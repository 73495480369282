import Dropdown from "../../components/dropdown/Dropdown.jsx";
import TableQuiz from "../../components/tableQuiz/TableQuiz.jsx";
import Quiz from "../../components/Quiz.jsx";

## Zadanie 1 (0-1pkt.)
(maj 2015 część 1 zadanie 2.1)

### TREŚĆ
Po wymnożeniu dwóch liczb 10324 oraz 1314 zapisanych w systemie czwórkowym
otrzymamy

<TableQuiz
    questions={
      [
        {question: <div>78<sub>10</sub></div>, answer: 'F'},
        {question: <div>8D6<sub>16</sub></div>, answer: 'P'},
        {question: <div>4326<sub>8</sub></div>, answer: 'F'},
        {question: <div>10011010110<sub>2</sub></div>, answer: 'F'},
      ]
    }
/>

## Zadanie 2 (0-1pkt.)
(maj 2016 część 1 zadanie 3.3)

### TREŚĆ
Dla dwóch liczb 1111<sub>(2)</sub> i 101<sub>(2)</sub>, ich

<TableQuiz 
    questions={
      [
        {question: <div>suma jest równa 10110<sub>(2)</sub>.</div>, answer: 'F'},
        {question: <div>różnica jest równa 1010<sub>(2)</sub>.</div>, answer: 'P'},
        {question: <div>iloczyn jest mniejszy od 110000<sub>(2)</sub>.</div>, answer: 'F'},
        {question: <div>iloraz jest większy od 10<sub>(2)</sub>. </div>, answer: 'P'},
      ]
    }
/>

## Zadanie 3 (0-1pkt.)
(maj 2019 część 1 zadanie 3.2)

### TREŚĆ
Po pomnożeniu dwóch liczb 11111102 oraz 1012 zapisanych w systemie dwójkowym otrzymamy: 

<TableQuiz 
    questions={
      [
        {question: <div>21312<sub>4</sub></div>, answer: 'P'},
        {question: <div>1001010110<sub>2</sub></div>, answer: 'F'},
        {question: <div>1166<sub>8</sub></div>, answer: 'P'},
        {question: <div>276<sub>16</sub></div>, answer: 'P'},
      ]
    }
/>

## Zadanie 4 (0-1pkt.)
(maj 2020 część 1 zadanie 3.1)

### TREŚĆ
<img src={new URL('https://i.imgur.com/Q6WjdYe.png', import.meta.url)} alt="xyz" className={`medium_img left_img`} />

<TableQuiz 
    questions={
      [
        {question: "Zegar A wskazuje godzinę 22:30:48.", answer: 'F'},
        {question: "Zegar B wskazuje godzinę 07:58:35.", answer: 'P'},
        {question: "Zegar C wskazuje godzinę 10:44:24.", answer: 'P'},
        {question: "Zegar D wskazuje godzinę 17:48:54.", answer: 'F'},
      ]
    }
/>

## Zadanie 5 (0-1pkt.)
(maj 2021 część 1 zadanie 3.2)

### TREŚĆ

<TableQuiz 
    questions={
      [
        {question: <p>(10000000)<sub>2</sub> jest liczbą większą od liczby (A9)<sub>16</sub></p>, answer: 'F'},
        {question: <p>(1111)<sub>4</sub> jest liczbą większą od liczby (1111111)<sub>2</sub></p>, answer: 'F'},
        {question: <p>(3003)<sub>4</sub> jest liczbą większą od liczby (C2)<sub>16</sub></p>, answer: 'P'},
        {question: <p>(333)<sub>8</sub> jest liczbą większą od liczby (10100101)<sub>2</sub></p>, answer: 'P'},
      ]
    }
/>

## Zadanie 6 (0-1pkt.)
(maj 2022 część 1 zadanie 3.2)

### TREŚĆ
Po dodaniu liczb 1324 oraz 31114 zapisanych w systemie czwórkowym otrzymamy:

<TableQuiz 
    questions={
      [
        {question: <p>1111011<sub>2</sub></p>, answer: 'F'},
        {question: <p>362<sub>8</sub></p>, answer: 'F'},
        {question: <p>F3<sub>16</sub></p>, answer: 'P'},
        {question: <p>3303<sub>4</sub></p>, answer: 'P'},
      ]
    }
/>


## Zadanie 7 (0-1pkt.)
(maj 2016 stara matura, poziom podstawowy, część 1 zadanie 3.5)

### TREŚĆ
Ile jest równe Y, aby X+Y=60<sub>(10)</sub>, jeżeli X=10110<sub>(2)</sub>?

<Quiz 
    question={''}
    answers={[
        <p>100011<sub>(2)</sub></p>,
        <p>100110<sub>(2)</sub></p>,
        <p>100101<sub>(2)</sub></p>,
        <p>100111<sub>(2)</sub></p>]}
    correct={<p>100110<sub>(2)</sub></p>}
/>

## Zadanie 8 (0-1pkt.)
(maj 2015 stara matura, poziom podstawowy, część 1 zadanie 3.1)

### TREŚĆ
Jaką ostatnią cyfrę w zapisie dziesiętnym ma liczba 2<sup>2015</sup>?

<Quiz 
    question={''}
    answers={[
        "2",
        "4",
        "6",
        "8"]}
    correct={"8"}
/>

## Zadanie 9 (0-1pkt.)
(maj 2014 stara matura, poziom podstawowy, część 1 zadanie 3b)

### TREŚĆ
Liczba binarna 10101010 to w systemie dziesiętnym 

<Quiz 
    question={''}
    answers={[
        "160",
        "165",
        "170"]}
    correct={"170"}
/>

## Zadanie 10 (0-1pkt.)
(maj 2013 stara matura, poziom podstawowy, część 1 zadanie 3c)

### TREŚĆ
Liczba 10101 zapisana w systemie binarnym jest

<Quiz 
    question={''}
    answers={[
        "większa od liczby 10110 zapisanej w systemie binarnym.",
        "mniejsza od liczby 20 zapisanej w systemie dziesiętnym.",
        "równa liczbie 15 zapisanej w systemie szesnastkowym."]}
    correct={"równa liczbie 15 zapisanej w systemie szesnastkowym."}
/>

## Zadanie 11 (0-1pkt.)
(maj 2011 stara matura, poziom podstawowy, część 1 zadanie 3a)

### TREŚĆ
Dane są dwie liczby: A=11001<sub>2</sub> oraz B=1010<sub>2</sub>

<Quiz 
    question={''}
    answers={[
        <p>A*B=101<sub>10</sub></p>,
        <p>A+B = 35<sub>10</sub></p>,
        <p>A–B = 10100<sub>2</sub></p>]}
    correct={<p>A+B = 35<sub>10</sub></p>}
/>

## Zadanie 12 (0-1pkt.)
(maj 2014 stara matura, poziom rozszerzony, część 1 zadanie 3c)

### TREŚĆ
Liczba binarna 101011111100 zapisana w systemie szesnastkowym ma postać

<TableQuiz 
    questions={
      [
        {question: "AEC", answer: 'F'},
        {question: "CFC", answer: 'F'},
        {question: "AFC", answer: 'P'},
        {question: "DFC", answer: 'F'},
      ]
    }
/>

## Zadanie 13 (0-1pkt.)
(maj 2013 stara matura, poziom rozszerzony, część 1 zadanie 3c)

### TREŚĆ 
Liczba BA<sub>(16)</sub> jest równa liczbie

<TableQuiz 
    questions={
      [
        {question: <p>272<sub>8</sub></p>, answer: 'P'},
        {question: <p>186<sub>10</sub></p>, answer: 'P'},
        {question: <p>2232<sub>4</sub></p>, answer: 'F'},
        {question: <p>10101010<sub>2</sub></p>, answer: 'F'}
      ]
    }
/>

## Zadanie 14 (0-1pkt.)
(maj 2012 stara matura, poziom rozszerzony, część 1 zadanie 3c)

### TREŚĆ 
Liczba 1E<sub>(16)</sub> jest równa liczbie

<TableQuiz 
    questions={
      [
        {question: <p>101010<sub>(2)</sub></p>, answer: 'F'},
        {question: <p>36<sub>(8)</sub></p>, answer: 'P'},
        {question: <p>1110<sub>(3)</sub></p>, answer: 'F'},
        {question: <p>30<sub>(10)</sub></p>, answer: 'P'}
      ]
    }
/>

## Zadanie 15 (0-1pkt.)
(maj 2012 stara matura, poziom rozszerzony, część 1 zadanie 3d)

### TREŚĆ 
Dla dwóch liczb 1110<sub>(2)</sub> i 10<sub>(2)</sub>, ich

<TableQuiz 
    questions={
      [
        {question: <p>suma jest równa 10000<sub>(2)</sub></p>, answer: 'P'},
        {question: <p>różnica jest równa 1000<sub>(2)</sub></p>, answer: 'F'},
        {question: <p>iloczyn jest równy 11110<sub>(2)</sub></p>, answer: 'F'},
        {question: <p>iloraz jest równy 111<sub>(2)</sub></p>, answer: 'P'}
      ]
    }
/>

## Zadanie 16 (0-1pkt.)
(maj 2011 stara matura, poziom rozszerzony, część 1 zadanie 3a)

### TREŚĆ 
Liczba 21202<sub>3</sub> jest równa

<TableQuiz 
    questions={
      [
        {question: <p>D1<sub>16</sub></p>, answer: 'P'},
        {question: <p>321<sub>8</sub></p>, answer: 'P'},
        {question: <p>10110001<sub>2</sub></p>, answer: 'F'},
        {question: <p>211<sub>10</sub></p>, answer: 'F'}
      ]
    }
/>

## Zadanie 17 (0-1pkt.)
(maj 2010 stara matura, poziom rozszerzony, część 1 zadanie 3c)

### TREŚĆ 
Liczba 1000<sub>16</sub> to

<TableQuiz 
    questions={
      [
        {question: <p>34522<sub>5</sub></p>, answer: 'F'},
        {question: <p>4096<sub>10</sub></p>, answer: 'P'},
        {question: <p>10000<sub>8</sub></p>, answer: 'P'}
      ]
    }
/>

## Zadanie 18 (0-1pkt.)
(maj 2009 stara matura, poziom rozszerzony, część 1 zadanie 3c)

### TREŚĆ 
Liczba dziesiętna 83 jest reprezentowana przez

<TableQuiz 
    questions={
      [
        {question: <p>(63)<sub>16</sub></p>, answer: 'F'},
        {question: <p>(121)<sub>8</sub></p>, answer: 'P'},
        {question: <p>(1103)<sub>4</sub></p>, answer: 'P'},
        {question: <p>(10100011)<sub>2</sub></p>, answer: 'P'}
      ]
    }
/>

## Zadanie 19 (0-1pkt.)
(maj 2009 stara matura, poziom rozszerzony, część 1 zadanie 3d)

### TREŚĆ 
 8-bitowa reprezentacja pewnej liczby dziesiętnej zapisanej w kodzie U2 ma postać
**11111110**. Tą liczbą jest 


<TableQuiz 
    questions={
      [
        {question: <p>-2</p>, answer: 'P'},
        {question: <p>-126</p>, answer: 'F'},
        {question: <p>-1</p>, answer: 'F'},
        {question: <p>254</p>, answer: 'F'}
      ]
    }
/>

## Zadanie 20 (0-1pkt.)
(maj 2008 stara matura, poziom rozszerzony, część 1 zadanie 3d)

### TREŚĆ 
Zapis 1010<sub>(p)</sub> oznacza, że 1010 jest zapisem pewnej liczby w systemie pozycyjnym
o podstawie *p*. Zaznacz, która z poniższych równości jest prawdziwa: 

<TableQuiz 
    questions={
      [
        {question: <p>1010<sub>(2)</sub> = 10<sub>(10)</sub></p>, answer: 'P'},
        {question: <p>12<sub>(10)</sub> = 1110<sub>(2)</sub></p>, answer: 'F'},
        {question: <p>67<sub>(10)</sub> = 1000011<sub>(2)</sub></p>, answer: 'P'}
      ]
    }
/>

## Zadanie 21 (0-1pkt.)
(maj 2008 stara matura, poziom rozszerzony, część 1 zadanie 3e)

### TREŚĆ 
Kod ASCII znaku zero wynosi 48, a kodem małej litery „a” jest 97

<TableQuiz 
    questions={
      [
        {question: <p>Kodem znaku „3” jest liczba 00110100<sub>(2)</sub></p>, answer: 'F'},
        {question: <p>Kodem znaku „4” jest liczba 01100000<sub>(2)</sub></p>, answer: 'F'},
        {question: <p>Kodem małej litery „f” jest liczba 01100110<sub>(2)</sub></p>, answer: 'P'}
      ]
    }
/>

## Zadanie 22 (0-1pkt.)
(maj 2007 stara matura, poziom rozszerzony, część 1 zadanie 1.3.)

### TREŚĆ
Największa liczba naturalna (bez znaku) zapisana w dwóch bajtach to

<Quiz 
    question={''}
    answers={[
        <p>2<sup>8</sup> - 1</p>,
        <p>210</p>,
        <p>65535</p>,
        <p>32767</p>]}
    correct={<p>65535</p>}
/>

## Zadanie 23 (0-1pkt.)
(maj 2007 stara matura, poziom rozszerzony, część 1 zadanie 1.4.)

### TREŚĆ
Liczba (BA)<sub>16</sub> równa się

<Quiz 
    question={''}
    answers={[
        <p>(186)<sub>10</sub></p>,
        <p>(252)<sub>8</sub></p>,
        <p>(10101010)<sub>2</sub></p>,
        <p>(2232)<sub>4</sub></p>]}
    correct={<p>(186)<sub>10</sub></p>}
/>

## Zadanie 24 (0-1pkt.)
(maj 2007 stara matura, poziom rozszerzony, część 1 zadanie 1.5.)

### TREŚĆ
Ułamek (0,125)<sub>10</sub> równa się

<Quiz 
    question={''}
    answers={[
        <p>(0,011)<sub>2</sub></p>,
        <p>(0,005)<sub>8</sub></p>,
        <p>(0,101)<sub>2</sub></p>,
        <p>(0,100)<sub>8</sub></p>]}
    correct={<p>(0,100)<sub>8</sub></p>}
/>

## Zadanie 25 (0-1pkt.)
(maj 2007 stara matura, poziom rozszerzony, część 1 zadanie 1.6.)

### TREŚĆ
Liczba (–120) zapisana na 8-bitach w kodzie uzupełnieniowym do dwóch ma postać

<Quiz 
    question={''}
    answers={[
        "01110111",
        "11110111",
        "10001000",
        "01111000"]}
    correct={"10001000"}
/>

## Zadanie 26 (0-1pkt.)
(maj 2006 stara matura, poziom rozszerzony, część 1 zadanie 4b)

### TREŚĆ
Liczba 2101 oznacza

<Quiz 
    question={''}
    answers={[
        "13 zapisane w systemie binarnym",
        "64 zapisane w systemie trójkowym",
        "1099 zapisane w systemie ósemkowym"]}
    correct={"64 zapisane w systemie trójkowym"}
/>
