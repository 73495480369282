import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/Informator_EM2023_informatyka.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/Informator_EM2023_informatyka_pliki.zip" >PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ

Wyrażeniem nawiasowym nazywamy dowolny skończony ciąg nawiasów: „[” i „]”.<br/>

#### Przykładowo: 
[ [ ] [ ] ]

<br/>
<b>Wyrażenie nawiasowe jest poprawne</b>, jeśli:
– jest wyrażeniem pustym (nie zawiera żadnych nawiasów)
– jest postaci AB, gdzie A i B są poprawnymi wyrażeniami nawiasowymi
– jest postaci [ A ], gdzie A jest poprawnym wyrażeniem nawiasowym
<br/><br/>

#### Przykład: 
wyrażenia [ [ ] ] oraz [ [ ] [ ] ] są poprawne. Niepoprawne jest za to wyrażenie
[ ] ] [ ] ].

<br/>
Niech w<sub>1</sub>, w<sub>2</sub>, …, w<sub>n</sub> będą kolejnymi nawiasami w pewnym wyrażeniu nawiasowym W.
Przyjmijmy teraz, że z każdym nawiasem otwierającym „[” wiążemy liczbę +1, a z każdym
nawiasem zamykającym „]” – liczbę -1. Niech si będzie liczbą związaną z nawiasem wi.
Wówczas <b>głębokością nawiasu wk w wyrażeniu W</b> nazywamy sumę:
S<sub>k</sub> = s<sub>1</sub> + <sub>s2</sub> + … + <sub>k</sub>
Głębokością wyrażenia W nazwiemy największą głębokość jego nawiasów, czyli maksimum
z liczb S<sub>k</sub>.

<br/><br/>

### <u>Zadanie 3</u>   (0-3pkt.)

Dane w pliku <i>dane2_3.txt</i> zapisano w oddzielnych wierszach. W każdym wierszu znajduje
się poprawne wyrażenie nawiasowe złożone z nawiasów kwadratowych (nieoddzielonych
żadnym znakiem).
Napisz program, który dla zadanych wyrażeń nawiasowych w pliku <i>dane2_3.txt</i> obliczy
ich głębokości.<br/><br/>

Dla danych z pierwszych czterech wierszy w pliku <i>dane2_3.txt</i>:

```text
[][][]
[[[]]]
[[][][]]
[[[]]]
```
<br/>
<br/>

<Dropdown title="ODPOWIEDŹ">

```text
1
3
2
3
```

</Dropdown>

<Dropdown title="ROZWIĄZANIE PRZYKŁADOWE - C++">

```
ifstream dane("dane2_3.txt");
ofstream wynik("zadanie2_3.txt");

string wiersz;
while (dane >> wiersz) {
    int suma = 0;
    int max = 0;

    for (string::size_type i = 0; i < wiersz.size(); i++) {
        if (wiersz[i] == '[') {
            suma++;
            if (suma > max)
                max = suma;
        } else
            suma--;
    }
    wynik << max << endl;
}
```
<p className={`dropdown_text`}>
Rozwiązanie z <a className="link" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/Informator_EM2023_informatyka.pdf">https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/Informator_EM2023_informatyka.pdf</a><br/>
</p>

</Dropdown>








<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def get_lines() -> list[int]:

    with open(r"dane2_3.txt") as text_file:
        lines = text_file.read().strip().split("\n")

    return lines


def get_max_depth(string: str) -> int:
    current_depth = 0
    max_depth = 0

    values = {"[": 1, "]": -1}

    for character in string:
        if character not in values:
            print(character)
        current_depth += values.get(character)

        max_depth = max(max_depth, current_depth)

    return max_depth


def main():
    liens = get_lines()

    for line in liens:
        print(get_max_depth(line))


if __name__ == "__main__":
    main()

```
</Dropdown>
