## Instalacja pythona

### Windows
![](https://i.imgur.com/dHtvgoA.png)
Aby rozpocząć pracę w Pythonie, musimy najpierw zainstalować środowisko. W tym celu wchodzimy na stronę: 
<a className={`link`} target="_blank" href="https://www.python.org/downloads/">https://www.python.org/downloads/</a> i instalujemy najnowszą wersję. 
Zwróćmy uwagę, żeby wybrać wersję 3.x, bo wersja 2.x nie jest już rozwijana, więc jej używanie nie jest rekomendowane. 

Po wejściu na stronę wybieramy zakładkę `Downloads`, a następnie klikamy na kafelek zaznaczony na poniższym screenie. 
![](https://i.imgur.com/H61TxgC.png)

Po pobraniu instalatora uruchamiamy go, a następnie zaznaczamy opcję 'Add python.exe to PATH' oraz `Install Now`. 
![](https://i.imgur.com/02cWNKj.png)

### Linux
Python powinien być domyślnie zainstalowany na tym systemie. Aby sprawdzić jaką wersję posiadasz, użyj poniższego polecenia. 
```
python -version
```
Jeśli masz przestarzałą wersję, możesz ją zaktualizować.
```
sudo apt-get install python 3.11 
```
## Przygotowanie środowiska pracy
Na maturze z informatyki dla języka Python możemy wybrać jedno z dwóch środowisk programistycznych: IDLE lub PyCharm. 

Środowisko programistyczne służy do tworzenia, edycji i debugowania kodu. Często daje nam również możliwość bezpośredniego uruchamiania programów. Znacznie ułatwia nam pracę dzięki kolorowaniu składni, inteligentnym podpowiedziom czy też podkreślaniu błędów. 

### IDLE 
W systemie Windows IDLE jest dołączony podczas instalacji Pythona. 

### PyCharm
Z tej strony możesz pobrać bezpłatną wersję PyCharm Community.
<a className={`link`} target="_blank" href="https://www.jetbrains.com/pycharm/download">https://www.jetbrains.com/pycharm/download</a>

### Pierwszy program
Aby uruchomić pierwszy program, utwórz plik z rozszerzeniem `.py` — dla przykładu nazwijmy go **pierwszy_program.py**. <br/>
Otwórz go w swoim ulubionym edytorze, a następnie wklej do pliku następującą linijkę:
```python=
print('Hello world!')
```
Plik możesz uruchomić za pomocą konsoli poleceniem:
```
python3 pierwszy_program.py
```
lub naciskając przycisk **Run** w wybranym przez siebie środowisku.