import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2020-kwiecien-probna-rozszerzona-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2020-kwiecien-probna-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ

Luką w ciągu liczbowym nazywamy <b>bezwzględną wartość różnicy</b> między dwoma kolejnymi
elementami.<br/>
Przykładowo – w czteroelementowym ciągu: 5, 2, 7, 10<br/>
są trzy luki:<br/>
− luka pomiędzy pierwszym a drugim elementem wynosi 3;<br/>
− luka pomiędzy drugim a trzecim elementem wynosi 5;<br/>
− luka pomiędzy trzecim a czwartym elementem wynosi 3.<br/>
Największa luka w tym ciągu ma wartość 5.<br/><br/>
W pliku <i>dane4.txt</i> znajduje się ciąg złożony z 1 000 dodatnich liczb całkowitych nie
większych od 2⋅10<sup>9</sup>.<br/> <b>Napisz program(-y)</b>, który(-e) da(-dzą) odpowiedzi do poniższych zadań.
Odpowiedzi zapisz w pliku <i>zadanie4.txt</i>, a każdą poprzedź numerem odpowiedniego
zadania.

<br/><br/>

### <u>Zadanie 2</u> (4pkt)
Fragment ciągu nazywamy regularnym, jeśli wszystkie jego luki mają tę samą wartość.<br/>
Przykładowo – w ciągu: 4, 11, 4, 1, 4, 7, 11, 12, 13, 14, 7, 0, 3<br/><br/>

regularnymi są następujące fragmenty:<br/>
− 4, 11, 4 – luka między jego elementami wynosi 7;<br/>
− 4, 1, 4, 7 – luka między jego elementami wynosi 3;<br/>
− 7, 11 – luka między jego elementami wynosi 4;<br/>
− 11, 12, 13, 14 – luka między jego elementami wynosi 1;<br/>
− 14, 7, 0 – luka między jego elementami wynosi 7;<br/>
− 0, 3 – luka między jego elementami wynosi 3. <br/><br/>

Znajdź <u>najdłuższy fragment regularny</u> w ciągu z pliku <i>dane4.txt</i>. Podaj jego długość oraz
wartości (liczby) znajdujące się na początku i końcu tego fragmentu. W pliku z danymi jest
jeden taki fragment.
W powyższym przykładzie długość najdłuższego fragmentu regularnego jest równa 4. Takie
fragmenty w przykładzie są dwa. Jeden zaczyna się od liczby 4 i kończy liczbą 7, a drugi
zaczyna się od liczby 11 i kończy liczbą 14. 

<br/><br/>
<Dropdown title="ODPOWIEDŹ">

```text
Największa długość fragmentu regularnego: 17
Początek: 193134524
Koniec: 223545714
```
</Dropdown>




