.main {
    min-height: 100vh;
    text-align: left;
    font-family: 'Chakra Petch', sans-serif;
    width: 70%;
    max-width: 85em;
    margin: 0 auto;
}

.main .introduction {
    margin-top: 10px;
    padding: 5px;
    background-color: white;
    border: solid #322e2b 2px;
    border-radius: 10px;
}

.main .exercises_title{
        padding-top: 10px;
}

.main .exercises_title p{
    padding: 8px 0;
    font-size: 16px;
    line-height: initial;
}

.main .exercises_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 20px;
    justify-content: center;
    text-align: center;
    line-height: 2rem;
}

.main .exercises_list pre {
    font-family: Chakra Petch, sans-serif;
}

.main .exercises_list.python_list {
    flex-direction: column;
}

.main .list_element_wrapper {
    display: flex;
    margin-top: 1.25rem;
    flex-wrap: wrap;
}

.wrap{
    display: flex;
    flex: row;
    padding-left: 15px;
}

.subject {
    margin-bottom: -3px;
    text-align: left;
    align-items: center;
    text-transform: uppercase;
    width: 14rem;
    border-left: 5px var(--exercise-secondary2) solid;
    padding-left: 10px;
}

.main .list_element {
    margin: 0 2px;
    background-color: var(--exercise-secondary);
    border-radius: 15px;
    height: 2rem;
    min-width: 11rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: var(--font);
}

.main .exam_exercise{
    margin-bottom: 8px;
}

.main .list_element:hover {
    background-color: var(--exercise-secondary2);
}


.main .list_element.list_element.python_exercise {
    min-width: 3rem;
}

.main .category {
    padding: 7px;
    /* margin: 0 40px; */
    letter-spacing: 2px;
    font-weight: bold;
    border-top: solid var(--exercise-secondary) 3px;
    text-align: center;
}

@media(max-width:1200px){
    .main{
        width: 80%;
        max-width: 95em;
    }
}


@media(max-width:900px){
    .main{
        width: 85%;
        max-width: 95em;
    }   

    .main .exercises_title p{
        font-size: 15px;
    }
}

@media (max-width:810px) {
    .main {
        padding: 0;
    }

    .exercises_title{
        padding-top: 20px;
    }
}

@media(max-width:600px){
    .exercises_title h2{
        font-size: 18px;
    }

    .category{
        font-size: 15px;
    }

    .list_element{
        font-size: 14px;
        height: 1.5rem;
    }
    
    .subject{
        font-size: 15px;
    }

    .main .list_element.list_element.python_exercise{
        min-width: 2rem;
    }

    .main .exercises_title p{
        font-size: 14px;
    }
}

@media(max-width:400px){
    .main{
        width: 90%;
        max-width: 95em;
    }

    .subject{
        width: 100%;
    }

    .main .exam_exercise{
        width: 100%;
    }
}