import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2011/R/inf_pr_ii.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2011/Dane_PR.rar"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ (0-10pkt.)
W pliku <i>liczby.txt</i>, w oddzielnych wierszach, znajduje się 1000 liczb zapisanych
w systemie dwójkowym o długościach zapisów od 2 do 16 cyfr (0 lub 1).
<b>Napisz program</b>, którego wykonanie da odpowiedzi do poniższych podpunktów.
Odpowiedzi zapisz w pliku <i>zadanie6.txt</i>, a każdą odpowiedź poprzedź literą
oznaczającą ten podpunkt.


<br/><br/><br/>

### <u>Podpunkt A</u>
Ile jest liczb parzystych w całym pliku?

<br/>
<Dropdown title="ODPOWIEDŹ">

```text
    W całym pliku jest 497 liczb parzystych.
```
</Dropdown>

<br/><br/>

### <u>Podpunkt B</u>
Jaka jest największa liczba w tym pliku? Podaj jej wartość w dwóch systemach:
dwójkowym i dziesiętnym.
<br/>

<Dropdown title="ODPOWIEDŹ">

```text
1111101111110110 (zapisana w systemie dwójkowym),
64502 (zapisana w systemie dziesiętnym)
```
</Dropdown>

<br/><br/>

### <u>Podpunkt C</u>
Ile liczb w całym pliku ma dokładnie 9 cyfr? Podaj sumę tych liczb w systemie
dwójkowym. 
<br/>

<Dropdown title="ODPOWIEDŹ">

```text
W całym pliku dokładnie 9 cyfr ma 101 liczb.
Ich suma w systemie dwójkowym jest równa
1001100111110111.
```
</Dropdown>

<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```
def podpunkt_a(dane):
    return len([x for x in dane if x[-1] == '0'])

def podpunkt_b(dane):
    dane_w_10 = [int(x,2) for x in dane]
    najw = max(dane_w_10)
    return (najw, bin(najw)[2:])

# tu jest niejednoznaczna tresc zakladam ze chodzi 
# tu o liczby, ktore w systemie 2 maja 9 cyfr

def podpunkt_c(dane):
    liczby_o_9_cyfrach = [int(x,2) for x in dane if len(x) == 9]
    return (len(liczby_o_9_cyfrach), bin(sum(liczby_o_9_cyfrach))[2:])

with open('liczby.txt', 'r') as plik:
    dane = [ a.strip() for a in plik.readlines()]

print("a)", podpunkt_a(dane))
print("b)", podpunkt_b(dane))
print("c)", podpunkt_c(dane))
```

</Dropdown>



