import Dropdown from "../../components/dropdown/Dropdown.jsx";
import TableQuiz from "../../components/tableQuiz/TableQuiz.jsx";
import Quiz from "../../components/Quiz.jsx";
import FillGapsQuiz from "../../components/FillGapsQuiz.jsx";

## Zadanie 1 (0-1pkt.)
(maj 2015 część 1 zadanie 2.2)

### TREŚĆ
Kompresja stratna w grafice
<TableQuiz 
    questions={
      [
        {question: "ma związek z plikami graficznymi w formacie BMP. ", answer: 'F'},
        {question: "ma związek z plikami graficznymi w formacie JPG. ", answer: 'P'},
        {question: "jest metodą zmniejszania rozmiaru pliku graficznego bez utraty szczegółów w obrazie.", answer: 'F'},
        {question: "wykorzystuje algorytm szyfrowania RSA", answer: 'F'},
      ]
    }
/>


## Zadanie 2 (0-1pkt.)
(maj 2018 cześć 1 zadanie 3.2)

### TREŚĆ
<TableQuiz 
    questions={
      [
        {question: "Plakat do druku lepiej przygotować w modelu barw RGB niż CMYK. ", answer: 'F'},
        {question: "Kolor żółty jest kolorem podstawowym w modelu RGB.", answer: 'F'},
        {question: "W wyniku nałożenia się składowych Yellow i Magenta w modelu CMYK otrzymamy kolor czerwony.", answer: 'P'},
        {question: "W modelu barw CMYK litera C pochodzi od angielskiego słowa contrast.", answer: 'F'},
      ]
    }
/>

## Zadanie 3 (0-1pkt.)
(informator o egzaminie maturalnym z informatyki od roku szkolnego 2022/2023 zadanie 19)

<FillGapsQuiz
    task={
        <p>
          Wskaż, gdzie stosowany jest model barw CMYK oraz zapisz, jaki kolor oznacza każda z liter w tym modelu. 
          <br/>
          .................................................................         
          <br/>
          Kolory:
        </p>
    }
    questions={[
        "C",
        "M",
        "Y",
        "K"]}
    correct={[
        "yan",
        "agenta",
        "ellow",
        "lack"]}
/>
<br/>
<Dropdown title="Rozwiązanie">
  <p className={`dropdown_text`}>
    Model CMYK wykorzystujemy w druku wielobarwnym, poligrafii, a także 
    przy określaniu kolorów atramentów, tonerów itp. <br/><br/>
    C - cyan (cyjan) <br/>
    M - magenta <br/>
    Y - yellow (żółty) <br/>
    K - black (czarny) <br/>
  </p>
</Dropdown>


## Zadanie 4 (0-1pkt.)
(maj 2014 stara matura, poziom podstawowy, część 1 zadanie 3f)

### TREŚĆ
Formatami plików graficznych przechowujących **grafikę rastrową** są

<Quiz 
    question={''}
    answers={[
        "JPEG, GIF, BMP",
        "SVG, CDR, SWF",
        "PAS, CPP, COM"]}
    correct={"JPEG, GIF, BMP"}
/>

## Zadanie 5 (0-1pkt.)
(maj 2013 stara matura, poziom podstawowy, część 1 zadanie 3e)

### TREŚĆ
Grafika wektorowa to sposób tworzenia i przechowywania w komputerze obrazów, które
są reprezentowane w postaci

<Quiz 
    question={''}
    answers={[
        "zbiorów punktów jednokolorowych.",
        "opisów figur geometrycznych (odcinków, łuków, okręgów, elips ...).",
        "siatki niezależnie traktowanych pikseli."]}
    correct={"opisów figur geometrycznych (odcinków, łuków, okręgów, elips ...)."}
/>

## Zadanie 6 (0-1pkt.)
(maj 2011 stara matura, poziom rozszerzony, część 1 zadanie 3f)

### TREŚĆ
Grafika wektorowa jest wykorzystywana do reprezentowania

### TREŚĆ
<TableQuiz 
    questions={
      [
        {question: "schematów i kompozycji figur geometrycznych", answer: 'P'},
        {question: "czcionek", answer: 'P'},
        {question: "zdjęć wysokiej jakości", answer: 'F'},
        {question: "obrazów pochodzących bezpośrednio ze skanera", answer: 'F'},
      ]
    }
/>

## Zadanie 7 (0-1pkt.)
(maj 2010 stara matura, poziom rozszerzony, część 1 zadanie 3e)

### TREŚĆ
Format plików graficznych dla grafiki rastrowej to

### TREŚĆ
<TableQuiz 
    questions={
      [
        {question: "BMP", answer: 'P'},
        {question: "JPG", answer: 'P'},
        {question: "GIF", answer: 'P'}
      ]
    }
/>

## Zadanie 8 (0-1pkt.)
(maj 2008 stara matura, poziom rozszerzony, część 1 zadanie 3j)

### TREŚĆ
Plik graficzny zawiera obrazek o rozmiarach 1024 na 768 pikseli zapisany z użyciem
256 kolorów. Do zapisania tego pliku (bez użycia kompresji) potrzebne jest 

### TREŚĆ
<TableQuiz 
    questions={
      [
        {question: "786432 bitów", answer: 'F'},
        {question: "786432 bajtów", answer: 'P'},
        {question: "786432 kilobajtów", answer: 'F'}
      ]
    }
/>

## Zadanie 9 (0-1pkt.)
(maj 2006 stara matura, poziom rozszerzony, część 1 zadanie 4h)

### TREŚĆ
Grafika rastrowa to sposób tworzenia i przechowywania w komputerze obrazów, które są
reprezentowane w postaci 

<Quiz 
    question={''}
    answers={[
        "równań figur geometrycznych (odcinków, łuków, okręgów, elips)",
        "siatki niezależnie traktowanych pikseli",
        "zbiorów odcinków"
        ]}
    correct={"siatki niezależnie traktowanych pikseli"}
/>