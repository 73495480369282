import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2021/Informatyka/poziom_rozszerzony/EINP-R2-100-2105.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2021/Informatyka/poziom_rozszerzony/Dane_2105.zip">PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ
Pewna firma przygotowuje wyświetlanie napisów złożonych z wielkich liter alfabetu
angielskiego. Na początku napis jest pusty (nie zawiera liter). W pliku <i>instrukcje.txt</i>
podanych jest 2000 instrukcji, które wykonuje automat do generowania napisu. Każda
z instrukcji składa się z polecenia, spacji oraz pojedynczego znaku. Polecenia są czterech
rodzajów:
<br/>
DOPISZ litera – oznacza, że na końcu napisu trzeba dopisać pojedynczą literę;<br/>
ZMIEN litera – oznacza, że ostatnią literę aktualnego napisu należy zmienić na podaną
literę (możesz założyć, że napis jest niepusty);<br/>
USUN 1 – oznacza, że należy usunąć ostatnią literę aktualnego napisu (możesz
założyć, że napis jest niepusty);<br/>
PRZESUN litera – oznacza, że pierwsze od lewej wystąpienie podanej litery w napisie należy
zamienić na następną literę w alfabecie (jeśli litera to A, to należy zamienić
na B, jeśli B, to na C itd.) Literę Z należy zamienić na A. Jeśli litera nie
występuje w napisie, nie należy nic robić. <br/><br/>

#### Przykład:
Dany jest następujący ciąg instrukcji:<br/>
DOPISZ A<br/>
DOPISZ B<br/>
DOPISZ C<br/>
USUN 1<br/>
DOPISZ D<br/>
ZMIEN B<br/>
DOPISZ E<br/>
PRZESUN B<br/>
Po wykonaniu pierwszych trzech instrukcji napis będzie miał postać ABC, potem AB, ABD,
ABB, ABBE, wreszcie ostatnia instrukcja zamieni pierwsze B na C, więc ostatecznie powstały
napis to ACBE.<br/><br/>
<span className="bold">Napisz program</span> (lub kilka programów), który(-e) znajdzie(-dą) odpowiedzi na poniższe
pytania. Każdą odpowiedź zapisz w pliku <i>wyniki4.txt</i> i poprzedź ją numerem
oznaczającym zadanie.<br/>
Do dyspozycji masz również plik <i>przyklad.txt</i>, w którym znajduje się tylko 20 instrukcji –
odpowiedzi dla tego pliku podane są w treściach zadań, możesz więc sprawdzać na nim
działanie swojego programu. Pamiętaj, że Twój program musi ostatecznie działać dla 2000
instrukcji. 

<br/><br/>

### Zadanie 4   (0-4pkt.)
Podaj napis, który powstanie po wykonaniu wszystkich instrukcji z pliku instrukcje.txt.<br/>
Dla pliku przyklad.txt wynikiem jest napis ALANTURING. 
<br/><br/>

<Dropdown title="ODPOWIEDŹ">

```text
POZNIEJMOWIONOZECZLOWIEKTENNADSZEDLODPOLNOCYODBRAMYPOWROZNIC
ZEJSZEDLPIESZOAOBJUCZONEGOKONIAPROWADZILZAUZDEBYLOPOZNEPOPOLUD
NIEIKRAMYPOWROZNIKOWIRYMARZYBYLYJUZZAMKNIETEAULICZKAPUSTABYLOCIE
PLOACZLOWIEKTENMIALNASOBIECZARNYPLASZCZNARZUCONYNARAMIONAZWRA
CALUWAGEZATRZYMALSIEPRZEDGOSPODASTARYNARAKORTPOSTALCHWILEPOSL
UCHALGWARUGLOSOWGOSPODAJAKZWYKLEOTEJPORZEBYLAPELNALUDZINIEZNA
JOMYNIEWSZEDLDOSTAREGONARAKORTUPOCIAGNALKONIADALEJWDOLULICZKIT
AMBYLADRUGAKARCZMAMNIEJSZANAZYWALASIEPODLISEMTUBYLOPUSTOKARCZ
MANIEMIALANAJLEPSZEJSLAWY

[początek opowiadania “Wiedźmin” A.Sapkowskiego]
```
</Dropdown>

<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
import collections

# Pomocnicze funkcje

def wczytaj_dane():
  with open("instrukcje.txt") as plik:
    return [x.rstrip().split(" ") for x in plik.readlines()]

def nastepna_litera(litera):
  if litera == "Z": return "A"
  else: return chr(ord(litera)+1)

def zmien(napis, litera):
  return napis.replace(litera, nastepna_litera(litera), 1)

# Podpunt 4.1) i 4.4)

def wykonaj(dotychczasowy_wynik, polecenie):
  rodzaj, argument = polecenie
  if rodzaj == "DOPISZ":
    return dotychczasowy_wynik + argument
  elif rodzaj == "ZMIEN":
    return dotychczasowy_wynik[:-1] + argument
  elif rodzaj == "USUN":
    return dotychczasowy_wynik[:-1]
  else:
    return zmien(dotychczasowy_wynik, argument)

def wykonaj_wszystko(polecenia):
  wynik = ""
  for p in polecenia: 
    wynik = wykonaj(wynik, p)
  return wynik

# Podpinkt 4.2)

def najczestsze_operacje_po_sobie(polecenia):
  najcz, najcz_wyst, akt, akt_wyst = "", 0, "", 0 
  for (tresc, arg) in polecenia:
    if akt == tresc: akt_wyst += 1
    else: akt, akt_wyst = tresc, 1
    if akt_wyst > najcz_wyst:
      najcz, najcz_wyst = akt, akt_wyst
  return (najcz, najcz_wyst)

# Podpunkt 4.3)

def najczestsza_litera(polecenia):
  litery = [y for (x,y) in polecenia if x == "DOPISZ"]
  return collections.Counter(litery).most_common(1)[0]

polecenia = wczytaj_dane()
po_wykonaniu = wykonaj_wszystko(polecenia)
print("4.1)", len(po_wykonaniu))
print("4.2)", najczestsze_operacje_po_sobie(polecenia))
print("4.3)", najczestsza_litera(polecenia))
print("4.4)", po_wykonaniu)

```

</Dropdown>
