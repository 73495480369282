## Kryteria
Możemy używać:
1. operatorów: **>**, **>=**, **=**, **<**, **<=**
2. słowa kluczowego **between**
3. spójnika logicznego **AND**
4. drugiego pola — jako alternatywy logicznej (**OR**)
5. słowa kluczowego **Like**: <br/>
    5.1. **\*** - zastępuje jeden lub wiele znaków <br/>
    5.2. **?** - zastępuje dokładnie jeden znak <br/>
    5.3. **#** - zastępuje jedną cyfrę <br/>
    5.3. szczegóły można znaleźć w dokumentacji: 
    <a className={`link`} target="_blank" href="https://support.microsoft.com/pl-pl/office/operator-like-b2f7ef03-9085-4ffb-9829-eef18358e931">https://support.microsoft.com/pl-pl/office/operator-like-b2f7ef03-9085-4ffb-9829-eef18358e931</a>

## Ciekawe funkcje wbudowane:
1. **Left**/ **Right** — zwraca ciąg x znaków od lewej/ prawej
2. **Mid** — zwraca ciąg x znaków, począwszy od pozycji y
3. **Day**/ **Month**/ **Year**/ **Hour**/ **Minute** — zwraca dzień/ miesiąc/ rok/ godzinę/ minutę z daty
4. **Średnia** — oblicza średnią
5. **Weekday** — zwraca dzień tygodnia reprezentowany przez liczbę.