import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2019-czerwiec-matura-rozszerzona-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2019-czerwiec-matura-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ
Liczby pierwsze to liczby naturalne większe od 1, które mają dokładnie dwa dzielniki: jedynkę
i samą siebie.<br/>
Dane są dwa pliki: <i>liczby.txt</i> i <i>pierwsze.txt</i>. Plik <i>liczby.txt</i> zawiera 300
wierszy. W każdym wierszu tego pliku znajduje się jedna liczba całkowita dodatnia z zakresu
od 1 do 100 000.<br/>
Plik <i>pierwsze.txt</i> zawiera 200 wierszy. W każdym wierszu tego pliku znajduje się jedna
liczba pierwsza z zakresu od 10 do 1 300 000.<br/><br/>

<b>Uwaga:</b> pomocnicze pliki <i>liczby_przyklad.txt</i> i <i>pierwsze_przyklad.txt</i>,
zawierają dane, które możesz wykorzystać, aby sprawdzić poprawność działania swojego(-ich)
programu(-ów). Każdy z nich zawiera po 50 wierszy. W każdym wierszu znajduje się jedna
liczba. Odpowiedzi dla danych z tych plików są podane pod treściami zadań.<br/><br/>

<b>Napisz program(-y)</b>, w wyniku działania którego(-ych) otrzymasz odpowiedzi do poniższych
zadań. Pliki źródłowe z rozwiązaniem zapisz pod nazwą zgodną z numerem zadania,
z rozszerzeniem odpowiadającym użytemu językowi programowania. 
<br/><br/>

### <u>Zadanie 1</u> (4pkt)

Podaj, (zachowując ich kolejność) te liczby z pliku <i>liczby.txt</i>, które są liczbami
pierwszymi z przedziału 〈100; 5000〉. Odpowiedź zapisz w pliku <i>wyniki4_1.txt</i>.<br/><br/>
Dla pliku <i>liczby_przyklad.txt</i> odpowiedzią są liczby: 103, 163, 173, 701, 1033, 2137,
3529, 4933, 977, 2143. 

<br/><br/>

<Dropdown title="ODPOWIEDŹ">

```
563
2087
163
2423
3581
911
997
113
1049
1511
2467
283
3559
521
4201
877
1301
2749
4919
1213
2039
4111
3331
401
2221
```
</Dropdown>




<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def is_valid(number: int) -> bool:
    if number not in range(100, 5001):
        return False

    for divider in range(2, number):
        if number % divider:
            continue

        return False

    return True


def main():
    with open(r"liczby.txt") as text_file:
        numbers = [int(line) for line in text_file.read().strip().split()]

    print(*list(filter(is_valid, numbers)), sep="\n")


if __name__ == "__main__":
    main()

```
</Dropdown>
