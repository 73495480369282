import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2021-czerwiec-matura-rozszerzona-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2021-czerwiec-matura-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ
W pliku <i>napisy.txt</i> znajduje się 1000 wierszy po 50 znaków (dużych liter angielskiego
alfabetu oraz cyfr).<br/><br/>
Napisz <b>program(y)</b>, który(e) da(dzą) odpowiedzi do poniższych zadań. Odpowiedzi zapisz
w pliku <i>wyniki4.txt</i>, a każdą odpowiedź poprzedź numerem oznaczającym odpowiednie
zadanie.<br/><br/>
<b>Uwaga:<br/></b> Plik <i>przyklad.txt</i> zawiera dane przykładowe spełniające warunki zadania.
Odpowiedzi dla danych z pliku <i>przyklad.txt</i> są podane pod pytaniami. )

<br/><br/>

### Zadanie 4 (4pkt.)
Ostatnie z haseł zostało ukryte w cyfrach zapisanych w pliku <i>napisy.txt</i>. Aby je odczytać,
należy cyfry z każdego wiersza pogrupować po dwie, pomijając ostatnią, jeśli w wierszu jest
nieparzysta liczba cyfr. Jeżeli liczba utworzona przez parę cyfr jest mniejsza od 65 lub większa
od 90, to ją pomijamy, w przeciwnym przypadku taką liczbę zamieniamy na znak o kodzie
ASCII odpowiadającym tej liczbie. Poszukiwanie hasła kończy się po otrzymaniu trzech
kolejnych znaków „X”. Odczytaj z pliku tak ukryte hasło.<br/><br/>
Dla danych z pliku <i>przyklad.txt</i> wynikiem jest: 

```text
NAPISANIEMATURYXXX
```

<br/><br/>
<Dropdown title="ODPOWIEDŹ">

```text
LITWOOJCZYZNOMOJATYJESTESJAKZDROWIEILECIETRZEBACENICTENTYLKOSIED
OWIEKTOCIESTRACILNATENCZASWOJSKICHWYCILNATASMIEPRZYPIETYSWOJROG
BAWOLIDLUGICENTKOWANYKRETYJAKWAZBOAXXX
```
</Dropdown>





<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
import re
import typing


def pairs_of_digits_from_line(line: str) -> typing.Union[None, list[int]]:
    digits = re.findall(r"\d{1}", line)

    if len(digits) < 2:
        return None

    if len(digits) % 2:
        digits = digits[:-1]

    pairs: list[int] = []

    for index in range(0, len(digits), 2):
        pairs.append(int(digits[index] + digits[index + 1]))

    return pairs


def main():
    with open(r"napisy.txt") as text_file:
        lines = text_file.read().strip().split("\n")

    message = ""

    for line in lines:
        pairs = pairs_of_digits_from_line(line)

        if pairs is None:
            continue

        for number in pairs:
            if number not in range(65, 91):
                continue

            message += chr(number)

        if message.endswith("XXX"):
            break

    print(message)


if __name__ == "__main__":
    main()

```
</Dropdown>
