import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2017/formula_od_2015/informatyka/MIN-R2_1P-172.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2017/formula_od_2015/informatyka/Dane_PR2.zip">PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ
W pliku <i>dane.txt</i> znajduje się 200 wierszy. Każdy wiersz zawiera 320 liczb naturalnych
z przedziału od 0 do 255, oddzielonych znakami pojedynczego odstępu (spacjami).<br/>
Przedstawiają one jasności kolejnych pikseli czarno-białego obrazu o wymiarach 320 na 200
pikseli (od 0 – czarny do 255 – biały).<br/>
Napisz program(y), który(e) da(dzą) odpowiedzi do poniższych zadań. Odpowiedzi zapisz
w pliku <i>wyniki6.txt</i>, a każdą odpowiedź poprzedź numerem oznaczającym odpowiednie
zadanie.<br/><br/>
<span className="bold">Uwaga</span>: plik <i>przyklad.txt</i> zawiera dane przykładowe spełniające warunki zadania (obraz
ma takie same rozmiary). Odpowiedzi dla danych z pliku <i>przyklad.txt</i> są podane pod
poleceniami. 

<br/><br/>

### <u>Zadanie 3</u>   (0-3pkt.)
Sąsiednie piksele to takie, które leżą obok siebie w tym samym wierszu lub w tej samej
kolumnie. Dwa sąsiednie piksele nazywamy kontrastującymi, jeśli ich wartości różnią się
o więcej niż 128. Podaj liczbę wszystkich takich pikseli, dla których istnieje przynajmniej jeden
kontrastujący z nim sąsiedni piksel.<br/><br/>
Dla danych z pliku <i>przyklad.txt</i> wynikiem jest 5. 

<br/><br/>

<Dropdown title="ODPOWIEDŹ">

```text
753
```
</Dropdown>

<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
DIRECTIONS = [
    [0, 1],
    [0, -1],
    [-1, 0],
    [1, 0]
]


def get_image() -> list[list[int]]:
    with open("dane.txt") as image:
        lines = image.read().strip().split("\n")

    matrix = [[int(pixel) for pixel in line.split(" ")] for line in lines]

    return matrix


def has_contrasting_pixels(image: list[list[int]], row_index: int, column_index: int) -> bool:

    pixel_value = image[row_index][column_index]

    for direction in DIRECTIONS:

        new_point_row = row_index + direction[0]
        new_point_column = column_index + direction[1]

        if new_point_column not in range(len(image[0])):
            continue

        if new_point_row not in range(len(image)):
            continue

        if abs(image[new_point_row][new_point_column] - pixel_value) > 128:
            return True

    return False


def get_contrasting_pixels_count(image: list[list[int]]) -> int:
    count = 0
    for row_index in range(len(image)):
        for column_index in range(len(image[0])):
            if not has_contrasting_pixels(image, row_index, column_index):
                continue

            count += 1

    return count


def main():
    image = get_image()

    cont = get_contrasting_pixels_count(image)

    print(cont)


if __name__ == "__main__":
    main()

```
</Dropdown>