import Dropdown from "../../components/dropdown/Dropdown.jsx";
import TableQuiz from "../../components/tableQuiz/TableQuiz.jsx";
import Quiz from "../../components/Quiz.jsx";

## Zadanie 1 (0-1pkt.)
(maj 2016 część 1 zadanie 3.4)

### TREŚĆ

<TableQuiz 
    questions={
      [
        {question: "Jednym z zadań systemu operacyjnego jest przydział pamięci działającym programom.", answer: 'P'},
        {question: "Na jednym dysku twardym mogą być zainstalowane dwa systemy operacyjne.", answer: 'P'},
        {question: "System operacyjny musi być przechowywany w pamięci ROM.", answer: 'F'},
        {question: "System operacyjny musi być przechowywany na twardym dysku.", answer: 'F'},
      ]
    }
/>


## Zadanie 2 (0-1pkt.)
(maj 2017 stara matura, poziom podstawowy, część 1 zadanie 3.4)

### TREŚĆ
Zagrożeniem dla bezpieczeństwa danych zapisanych na dysku twardym komputera jest:  

<Quiz 
    question={''}
    answers={[
        "korzystanie z aktualizacji systemu operacyjnego dostępnych w internecie.",
        "czytanie i właściwie reagowanie na komunikaty systemu operacyjnego.",
        "korzystanie z komunikatorów internetowych.",
        "otwieranie wszystkich załączników do otrzymywanych e-mailów."]}
    correct={"otwieranie wszystkich załączników do otrzymywanych e-mailów."}
/>

## Zadanie 3 (0-1pkt.)
(maj 2009 stara matura, poziom podstawowy, część 1 zadanie 3c)

### TREŚĆ 
Program, który umożliwia tłumaczenie programu w języku programowania wysokiego
poziomu na kod gotowy do wykonania na komputerze, to 

<TableQuiz 
    questions={
      [
        {question: "kompilator", answer: 'P'},
        {question: "BIOS", answer: 'F'},
        {question: "konsolidator", answer: 'F'}
      ]
    }
/>

## Zadanie 4 (0-1pkt.)
(maj 2011 stara matura, poziom rozszerzony, część 1 zadanie 3g)

### TREŚĆ 
Nazwą nośnika pamięci zewnętrznej jest 

<TableQuiz 
    questions={
      [
        {question: "jest wykorzystywana wyłącznie przez aplikacje służące do administrowania systemem operacyjnym", answer: 'F'},
        {question: "służy głównie jako nośnik do archiwizacji dokumentów", answer: 'F'},
        {question: "jest wykorzystywana do przechowywania programu komputerowego podczas jego uruchamiania i wykonywania", answer: 'P'},
        {question: "jest niezbędna do poprawnej pracy komputera", answer: 'P'}
      ]
    }
/>

## Zadanie 5 (0-1pkt.)
(maj 2008 stara matura, poziom rozszerzony, część 1 zadanie 3k)

### TREŚĆ 
Pamięć operacyjna komputera  

<TableQuiz 
    questions={
      [
        {question: "płyta CD", answer: 'P'},
        {question: "pamięć flash", answer: 'P'},
        {question: "pamięć cache", answer: 'F'}
      ]
    }
/>

## Zadanie 6 (0-1pkt.)
(maj 2007 stara matura, poziom rozszerzony, część 1 zadanie 1.7.)

### TREŚĆ
Sieć oznaczona skrótem MAN

<Quiz 
    question={''}
    answers={[
        "łączy komputery w obrębie jednego budynku",
        "łączy komputery w obrębie jednej instytucji",
        "łączy komputery w obrębie aglomeracji miejskiej",
        "łączy komputery w różnych miastach"]}
    correct={"łączy komputery w obrębie aglomeracji miejskiej"}
/>

## Zadanie 7 (0-1pkt.)
(maj 2006 stara matura, poziom rozszerzony, część 1 zadanie 4d)

### TREŚĆ
System operacyjny to

<Quiz 
    question={''}
    answers={[
        "program umożliwiający szybką realizację operacji matematycznych",
        "zbiór programów zarządzających pracą komputera",
        "program służący wyłącznie do formatowania dysków i kopiowania plików"]}
    correct={"zbiór programów zarządzających pracą komputera"}
/>
