.footer {
    font-size: 15px;
    font-family: 'Chakra Petch', sans-serif;
    width: 90%;
    margin: 0 auto;
    color: var(--font);
    text-align: center;
    padding: 30px 0 0;
}

.footer h4 {
    text-transform: uppercase;
}

.footer .logo_footer svg {
    margin: 2vw;
    width: 130px;
    height: auto;
}

.footer a {
    text-decoration: none;
    color: var(--font-accent);
    transition: .7s;
}

.footer .coffee_link {
    padding-top: 10px;
    width: 130px;
    margin: 0 auto;
    transition: .5s;
}

.footer .coffee_link:hover {
    filter: brightness(02);
}

.footer a svg {
    vertical-align: text-bottom;
}

.footer a:hover {
    letter-spacing: 2px;
}

@media(max-width: 1000px) {
    .footer {
        font-size: 14px;
    }
}

@media(max-width: 800px) {
    .footer {
        font-size: 13px;
        padding-top: 20px;
    }

    .footer .logo_footer svg {
        width: 110px;
    }
}

@media(max-width: 600px) {
    .footer .coffee_link {
        width: 110px;
    }

    .footer .logo_footer svg {
        width: 100px;
    }
}