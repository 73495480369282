## Sieć komputerowa
To zbiór komputerów i innych urządzeń połączonych ze sobą oraz oprogramowanie wykorzystywane  wtej sieci w celu wymiany danych.

### Podział sieci ze względu na wielkość

* **Lokalne** - łączą ze soba komputery na małym obszarze np. w szkole lub biurze.
* **Miejskie** - łączą ze sobą komputery na terenie jednego miasta.
* **Rozgległe** - łączą ze sobą komputery na większym terenie np. kraju.
* **Internet** - łączy ze sobą większość komputerów na świecie.

### Podział sieci ze względu na model funkcjonowania

* **każdy z każdym** - każdy komputer w sieci ma takie same uprawnienia i może być równocześnie serwerem i klientem.
* **klient - serwer** - conajmniej jeden z komputerów musi pełnić rolę serwera (udostępniać swoje zasoby pozostałym).


### Podział sieci ze względu na topologię

<img src={new URL('../../img/teoria/topologie.png', import.meta.url)} alt="xyz" className={`big_img`} />


## Terminologia sieciowa

### Klient
komputer, który korzysta z zasobów

### Serwer
komputer, który udostępnia zasoby

### Switch
naczej zwany przełącznikiem sieciowym. Jest to urządzenie, które łączy i umożliwia przesył danych pomiędzy wszystkimi elementami pracującymi 
w jednej sieci komputerowej.

### Router
to urządzenie, które umożliwia przesyłanie pakietów danych pomiędzy różnymi sieciami komputerowymi.

### Brama domyślna (ang. gateway)
to urządzenie, które umożliwia przesyłanie pakietów danych pomiędzy różnymi sieciami komputerowymi opartymi o różne protokoły.

### Karta sieciowa
to urządzenie, które umożliwia połączenie z innymi komputerami.

### Protokół
zbiór reguł regulujących zasady, na podstawie których następuje przesył danych pomiędzy komputerami.

### Klient sieci
oprogramowanie występujące w roli klienta, któy pobiera zasoby od serwera.

### Usługa
to określenie funkcji, jakie wykonuje system 

### Adres IP 
unikatowy numer, przyporządkowywany komputerowi w sieci na podstawie protokołu IP. Składa się on z czterech liczb oddzielonych kropkami, 
z zakresu 0 - 255 każda.