.collapsible {
  background-color: var(--dropdown);
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 1rem;
}

.dropdown_icon {
  color: var(--font);
}

.dropdown_title {
  color: var(--font);
  font-weight: bold;
}

.dropdown_content {
  color: var(--font);
  background-color: var(--dropdown);
}

