import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2020-kwiecien-probna-rozszerzona-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2020-kwiecien-probna-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ

Luką w ciągu liczbowym nazywamy <b>bezwzględną wartość różnicy</b> między dwoma kolejnymi
elementami.<br/>
Przykładowo – w czteroelementowym ciągu: 5, 2, 7, 10<br/>
są trzy luki:<br/>
− luka pomiędzy pierwszym a drugim elementem wynosi 3;<br/>
− luka pomiędzy drugim a trzecim elementem wynosi 5;<br/>
− luka pomiędzy trzecim a czwartym elementem wynosi 3.<br/>
Największa luka w tym ciągu ma wartość 5.<br/><br/>
W pliku <i>dane4.txt</i> znajduje się ciąg złożony z 1 000 dodatnich liczb całkowitych nie
większych od 2⋅10<sup>9</sup>.<br/> <b>Napisz program(-y)</b>, który(-e) da(-dzą) odpowiedzi do poniższych zadań.
Odpowiedzi zapisz w pliku <i>zadanie4.txt</i>, a każdą poprzedź numerem odpowiedniego
zadania.

<br/><br/>

### <u>Zadanie 3</u> (4pkt)

Krotnością luki nazywamy liczbę jej wystąpień. Najczęstszą luką nazywamy lukę
o największej krotności.<br/>
Przykładowo – w ciągu: 5, 2, 7, 10<br/>
luka 5 ma krotność 1, a luka 3 ma krotność 2 i wobec tego jest najczęstszą luką.<br/><br/>
Podaj krotność najczęstszej luki oraz wartości wszystkich najczęstszych luk w ciągu z pliku
<i>dane4.txt</i>. 
<br/><br/>
W przykładzie z zadania 4.2 (ciąg 4, 11, 4, 1, 4, 7, 11, 12, 13, 14, 7, 0, 3) krotność najczęstszej
luki wynosi 4. Tę krotność mają luki 7 i 3.

<br/><br/>
<Dropdown title="ODPOWIEDŹ">

```text
Krotność najczęstszej luki: 31
Wartości najczęstszych luk:
149
11
```
</Dropdown>




<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
from collections import Counter


def get_gaps(numbers: list[int]) -> list[int]:
    return [abs(numbers[index] - numbers[index - 1]) for index in range(len(numbers))]


def main():
    with open(r"dane4.txt") as text_file:
        numbers = [int(line) for line in text_file.read().strip().split("\n")]

    gaps = get_gaps(numbers)

    count = Counter(gaps)

    most_common = count.most_common()

    most_common = list(filter(lambda x: x[1] == max(most_common, key=lambda x: x[1])[1], most_common))

    print(most_common[0][1])
    print(*[m[0] for m in most_common], sep="\n")


if __name__ == "__main__":
    main()

```
</Dropdown>
