## Prawo autorskie
<img src={new URL('https://i.imgur.com/vb0Im0G.png', import.meta.url)} alt="xyz" className={`oryginal_img`}/>

### Prawo autorskie osobiste
* niezbywalne - zawsze przyszługuje twórcy i nie można przenieść go na inną osobę 
* nieograniczone w czasie

### Prawo autorskie majątkowe
* można je sprzedać lub przenieść na inną osobę czy podmiot
* ograniczone w czasie - 70 lat od śmierci twórcy (lub ostatniego z współtwórców)


### Kto ma prawo do programu komputerowego? Pracownik czy pracodwaca?
Autorskie prawa majątkowe do utworu stworzonego przez pracownika w ramach
wykonywania przez niego stosunku pracy nabywa pracodawca. Prawa autorskie osobiste
nadal przysługują pracownikowi jako twórcy utworu. <br/>

Zobacz więcej: <a className={`link`} target="_blank" href="https://poradnikpracownika.pl/-prawa-autorskie-osobiste-i-majatkowe-pracownikow">https://poradnikpracownika.pl/-prawa-autorskie-osobiste-i-majatkowe-pracownikow</a>

## Ciekawostka (dodatkowe)
### Sprawa ORACLE vs UsedSoft
Wyrok TS z 3.7.2012 r. w sprawie UsedSoft GmbH przeciwko Oracle International Corp., C-
128/11, Legalis, w którym Trybunał uznał, że: „w sprawie ochrony prawnej programów
komputerowych prawo do rozpowszechniania kopii programu komputerowego zostaje
wyczerpane, jeśli podmiot praw autorskich, który zezwolił, choćby i nieodpłatnie, na
pobranie tej kopii z Internetu na nośnik danych, również przyznał, w zamian za zapłatę ceny
mającej umożliwić mu uzyskanie wynagrodzenia odpowiadającego wartości gospodarczej
kopii dzieła, którego jest on właścicielem, prawo do nieograniczonego w czasie korzystania z
tej kopii”


## Przepisy, które mogą przydać się w przyszłości (dodatkowe)

### Mniejsze podatki dla programistów artystów
Według przepisów podwyższone o 50% koszty uzyskania przychodów mają zastosowanie do 
wynagrodzenia za przeniesienie praw autorskich do utworu.
<br/>
Należy być ostrożnym, ponieważ w zalezności od charakterystyki pracy oraz od Urzędu Skarbowego to czy przepisy te 
znajdują zastosowanie przy rozliczaniu pracy programisty jest rozstrzygane różnie (czasami na naszą korzyść, ale nie zawsze).


### Praca dyplomowa
Art. 15a. 
1. Uczelni przysługuje pierwszeństwo w opublikowaniu pracy dyplomowej studenta. 
Jeżeli uczelnia nie opublikowała pracy dyplomowej w terminie 6 miesięcy od dnia jej obrony, 
autor może ją opublikować, chyba że praca jest częścią utworu zbiorowego
