import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2022/Informatyka/poziom_rozszerzony/EINP-R2-100-2205.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2022/Informatyka/poziom_rozszerzony/Dane_2205.zip">PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ 
W pliku <i>liczby.txt</i> danych jest 200 różnych liczb całkowitych z zakresu [10, 100000].
Każda z tych liczb zapisana jest w osobnym wierszu.
Napisz program (lub kilka programów), który(-e) znajdzie(-dą) odpowiedzi do poniższych
zadań. Odpowiedzi zapisz w pliku <i>wyniki4.txt</i>. Każdą odpowiedź poprzedź numerem
oznaczającym zadanie.
Do dyspozycji masz plik <i>przyklad.txt</i>, który także zawiera 200 liczb – odpowiedzi dla
tego pliku podano w treściach zadań. Możesz sprawdzać na nim działanie swojego
programu.
Uwaga: Pamiętaj, że Twój program musi ostatecznie działać dla pliku <i>liczby.txt</i>.

<br/><br/>

### <u>Zadanie 3</u>   (0-4pkt.)
Trójka (x, y, z) jest dobra, jeśli y jest wielokrotnością x, natomiast z jest wielokrotnością y
(czyli x dzieli y, a y dzieli z) oraz x, y, z są różne.
#### Przykład: 
Trójka (2, 6, 12) jest dobra, ponieważ 2 dzieli 6, a 6 dzieli 12. <br/>
Trójka (2, 10, 12) nie jest dobra, ponieważ 10 nie dzieli 12. <br/>
Analogicznie możemy zdefiniować dobrą piątkę liczb – piątka (u, w, x, y, z) jest dobra, jeśli
każda z liczb, poza pierwszą, jest podzielna przez poprzednią liczbę z piątki (u dzieli w,
w dzieli x, x dzieli y oraz y dzieli z) oraz wszystkie liczby z piątki są różne.<br/>
<span className="bold">a)</span> Podaj, ile jest dobrych trójek wśród liczb występujących w pliku <i>liczby.txt</i>. Zapisz
wszystkie dobre trójki do pliku <i>trojki.txt</i>, każdą w osobnym wierszu.
Uwaga: Liczby z trójki nie muszą występować w pliku <i>liczby.txt</i> w kolejnych
wierszach, a ich kolejność w tym pliku może być dowolna.<br/>
<span className="bold">b)</span> Podaj, ile jest dobrych piątek wśród liczb występujących w pliku <i>liczby.txt</i>.
Odpowiedzi dla danych z pliku <i>przyklad.txt</i>:<br/>
<span className="bold">a)</span> 10<br/>
<span className="bold">b)</span> 1<br/>
(10 dobrych trójek i jedna dobra piątka)

<br/><br/>

### Zawartość pliku trojki.txt dla danych z pliku przyklad.txt:

```text
13 104 208
13 52 104
13 52 208
13 26 104
13 26 52
13 26 208
52 104 208
26 104 208
26 52 104
26 52 208
```
<br/><br/>
<Dropdown title="ODPOWIEDŹ">

```text
27 (trójki)
2 (piątki)

Dobre trójki:
955 8595 42975
232 13688 27376
13594 27188 81564
971 13594 81564
971 13594 27188
971 27188 81564
971 6797 81564
971 6797 13594
971 6797 27188
797 7173 64557
1403 42090 84180
1403 2806 42090
1403 2806 84180
1403 2806 8418
1403 8418 42090
1403 8418 84180
871 15678 62712
497 22365 89460
2806 42090 84180
2806 8418 42090
2806 8418 84180
392 20384 61152
409 9816 58896
8418 42090 84180
6797 13594 81564
6797 13594 27188
6797 27188 81564
```

</Dropdown>


<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
PATH = r"liczby.txt"


def get_numbers() -> list[str]:

    with open(PATH) as text_file:
        numbers = text_file.read().strip().split("\n")

    return list(map(int, numbers))


def find_matching(numbers_so_far: int, desired_length: int, numbers_all: int, found: list) -> None:
    if len(numbers_so_far) == desired_length:
        found.append(numbers_so_far)
        return

    for proposed_number in numbers_all:

        if proposed_number % numbers_so_far[-1]:
            continue

        if proposed_number == numbers_so_far[-1]:
            continue

        find_matching(numbers_so_far + [proposed_number], desired_length, numbers_all, found)


def append_starting_from_number(number: int, numbers_all: list[int], searched_length: int, found: list[list[int]]) -> None:
    numbers_so_far = [number]

    find_matching(numbers_so_far, searched_length, numbers_all, found)


def _save_to_file(numbers: list[list[int]], filename: str) -> None:

    output = ""

    for group in numbers:
        output += "{}\n".format(" ".join([str(x) for x in group]))

    with open(filename, "w", encoding="utf-8") as text_file:
        text_file.write(output)


def main():
    numbers_all = get_numbers()
    found_for_3 = []

    for starting_number in numbers_all:
        append_starting_from_number(starting_number, numbers_all, 3, found_for_3)

    found_for_5 = []

    for starting_number in numbers_all:
        append_starting_from_number(starting_number, numbers_all, 5, found_for_5)

    print("ilosc trójek:", len(found_for_3))
    _save_to_file(found_for_3, "trojki.txt")
    print("ilosc piatek", len(found_for_5))


if __name__ == "__main__":
    main()

```
</Dropdown>
