import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2018/formula_od_2015/informatyka/MIN-R2_1P-182.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2018/formula_od_2015/informatyka/Dane_PR2.zip">PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ
W ramach projektu WEGA naukowcom udało się odczytać sygnały radiowe pochodzące
z przestrzeni kosmicznej. Po wstępnej obróbce zapisali je do pliku <i>sygnaly.txt</i>.<br/>
W pliku <i>sygnaly.txt</i> znajduje się 1000 wierszy. Każdy wiersz zawiera jedno niepuste
słowo złożone z wielkich liter alfabetu angielskiego. Długość jednego słowa nie przekracza 100
znaków.<br/><br/>
Napisz <span className="bold">program(y)</span>, który(e) da(dzą) odpowiedzi do poniższych zadań. Odpowiedzi zapisz
w pliku <i>wyniki4.txt</i>, a każdą odpowiedź poprzedź numerem oznaczającym odpowiednie
zadanie.<br/><br/>
<span className="bold">Uwaga</span>: Plik <i>przyklad.txt</i> zawiera dane przykładowe spełniające warunki zadania.
Odpowiedzi dla danych z pliku <i>przyklad.txt</i> są podane pod pytaniami. 

<br/><br/>

### <u>Zadanie 3</u>   (0-4pkt.)
W tym zadaniu rozważmy odległość liter w alfabecie – np. litery A i B są od siebie oddalone
o 1, A i E o 4, F i D o 2, a każda litera od siebie samej jest oddalona o 0. Wypisz wszystkie
słowa, w których każde dwie litery oddalone są od siebie w alfabecie co najwyżej o 10. Słowa
wypisz w kolejności występowania w pliku <i>sygnaly.txt</i>, po jednym w wierszu.<br/><br/>
Na przykład CGECF jest takim słowem, ale ABEZA nie jest (odległość A – Z wynosi 25). <br/><br/>
Dla danych z pliku <i>przyklad.txt</i> wynikiem jest :<br/>

```text
AAAAAAAAAI
AAAAAAAAAE
AAAAAAAAAC
AAAAAAAAAH
AAAAAAAAAC
AAAAAAAAAI
AAAAAAAAAA
BB
AAAAAAAAAA
AAAAAAAAAA
AAAAAAAAAB
AAAAAAAAAE
AAAAAAAAAD
AAAAAAAAAI
AAAAAAAAAE
```

<br/><br/>

<Dropdown title="ODPOWIEDŹ">

```text
Lista słów:

QQMLKKQNOHPKKPJOLHIPJKLKQIIHQHPNKNQPHNKLKQNIMLQPNLPMHNNIPNJJONQOHKKQO
IHOHHJMOJPMNIPIKION
PTPNTOLNOPOSKLQOQKNTQTPLPNLNRRSTNNSMRNNMLPNLKORTSMNKRTLLLLNRKKOLKTR
QOQOQONKTKRSPTTORMKQTRNOKKQ
JONLNPOJRPKPMMSQOLPLNNMKLJKOPRKRKNSJMSOJQPLOOLSOJQSOLLOQKSOJJMNRKMJMJP
QKJRSMJQ
TWUSWQTTOUXUTVTWWUTSXUWXWXRPSXOTQQUPPXUPRUOSURVQWSWSOUQSXOSQQXTW
SWTW
IICEGIFCCGBEBCKDBIGIH
FAAEEDBACDGEDCEBGEEFAAGBCFEFCCDFEGGGACFBBAGGEADGAAGEE
PNMTNQSOQOSTQLPTPTRNPMMQKTQNKPTROORQSTTSTKKTKLTTMNMQQSQKLKROSONOTNR
OONOPTQPPKMRTKMROPKTLTLRKQQKMOM
OO
FH
AE
VSQRYRRWQYQWXWXUUXPVWWUTSXYPXQVPSVXWYRXXRSPPYUVYWQUVSSPQTWXPTQPQ
TYSUSWTYRPXUX
DAACAEDDABDBBCADAEDDCABABDDDBCEBCDEAEBCEDAEADDABBEBBAAADBDDCCCECBE
ABBBBCBEBCABA
FDEEBCDBDBFFFCCFCADBCBEEFAFFCEAEDDFCFCDDACEFCEBFDFCCFBFCCCBDDEADCACBD
BBB
DCCDEBCHCHADBHFBFHBCGCAAFBHBHDEGCCFFGAHEGCCHHECDAFCECFEEBEEFEE
OPQPHIHQQIQLQPPMNIJPIPLON
EEL
SRMQOLLOLTSPUMRUMRTNPRRLSUSRTRT
OJQMKJOPQKKQOORKKOMNJQKKMNKQRNNQOLJKNNMSKLMSSMSRLNPRSNO
HBDHIFAGHAJHADDCECACFHDHCHJJFDIGEFGBBGEICDJGJAJFDGAHGCBCEAFDEJADGJAHIJGD
EDBIDECAJBEI
NMFEN
CCHHGEHEHBGAEDCGHCECHFFHECDEDCHECABFHDGDECBGFAH
XWYYYXWTTXTYWWUWYUXWVTVXTYYTYYTTXVVVYWXTUTXXWXYYUUTXTXT
EFCHGGIJKFDBFKFCEGHHFFGGHGFHKEEGKHIFBJBKDGFGCFKIIEHGICGFJH
RQSRQSRSRRLPNNLQTTQMOSRTQPQLPLPLPMRMNM
SSQRTSNOUPOSVQUOQVSPOQRSNORUUTSNSSWRRPPVOUQOUU
EABEGFBFAGDEECGEBFBBFFAECDGGFCBCFDCEABDGBGEBEEFFGCGDDAAGFDBG
JFKKFMJFMIILOLFOMOMGNFNLKHKHNGLOJJIOIOJLLKLFIJIHOJFLLKLGIGGIILNNJMOLIGGHLFI
NOOOJ
VVXUVSUUTWSWTXYTYUVTTWTYSWXUSWYTTXTYXVSWUSVSYUSYTUUTXUSWTSUYVTXY
UXTYXVYUWVSVWWVUUTSTXUWS
JHJKDFGHHFGFHEJGGGIDCEHFLFCFDJDLDJHGGCGILCLKJHLKDFGICL
OGHFKHFKHONHFFIHHFNKINIOFHKIONMONNOGLMFOGOJKNFHGILOHHOIHFIHNFGGHJGGHIF
INMNFNHFLMOIMLOMNMLJIHOGNJHN
OJMNHMJMJKPKPKLPPIHNMMMPNHKMQNIJJNOLQKHKIPQQPQMIKNIHHIOJONKOJJLOOOMKL
OQKKHNJJHPJJKMJHO
DJJBHGFGGDKIKCHKGKBFEKFEFJEGEHCHIFBDBGKJKCDGDCIDIBEBDGCGFKCGFFCHJJEJBJCIH
KKBHIEGKDG
YWUUSRXWSQTYVXVVUTRXWWVVUQQVTSXUWWVXUXTSTSSRWTTWXWUYURVYTWTXXV
YVUUWURQUYUU
MOQIPPOMHJMJJLNKKOIJLIJMMQPIPJMNMMQ
TXTUVUWWUUUWUWUXWYWXXTTXWWYWUVXXXVYWYWYVUYYUYTXVUXUTTUYTYUTX
YWXWTVVYXTYWUWWWUYUVTUXVTVWYXWVYWV
EEEEB
WUYTXVXUXVWXVTUVTUUTUTWVVVTUXXUVTXUVYVYXXUUYWVUUUVYYYUXTUUUTWU
VYXWVYTYUTYYTTWUUVXXUVYXTUXTWTYYVT
WXVUYUXYUUVYXTWVVTXVYYVXXWWUTUXXUVXWUYWTWTWWUWXVUYXYUYVTTXUU
W
WVSSQSSXRUUPVQXVTPYSTYURWTSURYVRVPPWXVXUVWQUXQSWWUWTXTSU
DEBDBDCDECCABAAEBBDEDBCCADA
QQ
GIDIHFGKLLKE
TQYQTWRPXTQSWSYVSYSWVYRWSRPTSXVXPT
VOSVQOSQQTUOOTXUTSVXUSWWRXWPXVVUSWURSWTWPSOWWXXPUTXVVXRTURSWTSXQ
WXWUTSOPTTPSPWQUPVXQXPVXSSOQOUXV
UVWUYYYYXWUWXYVVWXVXVYWYWYYVUXUYUXUVWWYXWXWUVWWVUWXWUXYUYU
WWYUWXVVWVWWVUYUVXXVYUWWUWXVXW
GGAGFAHGGDGEHDEGDHDGHHFDFGDGBCDACCCCDHAFDFEECEAGFDHA
EABDAADFECFAEEBBADBCBAEAADADEADBADAFEBDFEFDDBCCBBBCACBADEDEADFFEFEBF
EABBDFCFBBBFCCFEFCCD
EIFEACGHGGGBDBGCCBFDCGCICHDHDEEACIAHCBAHAHHIDIDGHIBIBABECGEHDCGFEFFEHB
EGHBIIFIEBBCBDAEDFEADHHFFEDGIA
UYYSRSXUWWXTXYSVVRWRTQTXXWSYVRTUW
BFFH
IKCHJKBJFFEFGGFFBGGGIHFIFFHBBGCHGBGFBGJFJIDGDCCECGJBBCKEDGBBGG
IJJBGAJBGDFJFHJIGJDDBFAIAGFCDAHEBEIGBCIIJBFIDEEFHDAIFJDEGAAIBHCDBCFDCGJIBD
TRRPOURNVTRWWOWOURNQTVTRTVWOWVTOSPWOTSORTNR
DEHDDEAHFBBCEBDFCGHDDFEHHDBCDDFECDFADAGFDGEBEFCBFADHEHAEBACGFCDDECA
G 
QNUSNNUWSOQQRVWQRTNSPNNQTQUUTUPSOWSUTSNQTUTWSRWQTNRQRVTUNOSNUQUVN
ORONSRSUWPPPNRRSWQNSSWNSNUSUOOVWQ
BEFEC
QRTWTPPURPUXWWRWOPOVXTVPXTWTOTUOOPROTOPXVUOWWWTOPOTWPTQOSWOVRPQT
UTVVTSVUSRWSS
PSMUQPPSSPTQNTNVTMNUVSUPNRNVNOOTVRSQPSNNVUPPPVTSMRUTUVMRNRMQTQQVMSS
MOSRTRT
UU
XVXXUUWUYTYVVWWSUWSUSSUSTYTVYWVTYTTSWSVYYYVTXYSYWSUYWXTUXVSWWW
VTWYWXUSUWVXWSYUVWUXYSWTTSYVYVVTYYYVXX
AAHEGCCGGBCECGBDBIADEDCIEICEFGBGAGA
GLICJLIIKKKDILKEDEJEFFLGECLFFIFGFHIGIJGKDFGEHGKLKDEDLCFFICGJHIJEHJFDFLDHGIDL
KEEJLEDEECIJGIIGJKCCJDJF
ABBDCAEEEBBCDAABBCDBACCCBECCBCDECEEBACEECAAEBBAEEBDEEBDABBCECBBABDA
AAADEDCEDBBBCDECCCBEBECAACABBCDBA
GDEKFJKKLLLEJGK
JJGHLMMMFMIFHONHGOINFIJKIJGJMJOIKHNOIGHKMMGNMJJMFMHNHFOLOMMOMFIGLLNHH
IKLGOIIMNFLLFFOKHNONLMNMMONMLJ
UVVUXVUXWWXVXUWXXYWYWUVYYXYVXXVYXWUUXWWWYWVVWUYVUWXXYXUXYY
WWWYUUXYUVWXYYUYXUUUVXXYXWUXXXUUWVUVWWYU 
```
</Dropdown>

<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def max_space_between_letters(word: str) -> int:

    letter_values = [ord(letter) for letter in word]

    return abs(max(letter_values) - min(letter_values))


def main():
    with open("sygnaly.txt") as text_file:
        lines = text_file.read().strip().split()

    output = ""

    for word in lines:
        distance = max_space_between_letters(word)

        if distance > 10:
            continue

        output += word + "\n"

    print(output)


if __name__ == "__main__":
    main()

```
</Dropdown>
