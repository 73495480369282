import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2015/formula_od_2015/MIN-R2_1P-152.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2015/formula_od_2015/Dane_PR2.zip" >PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ
W pliku liczby.txt znajduje się 1000 liczb naturalnych zapisanych binarnie. Każda
liczba zapisana jest w osobnym wierszu. Pierwsze pięć wierszy zawiera następujące liczby:<br/>

```
11010100111
11110111111011101
1010100111010100
1101111111111111111111010100101010101001
1010110011001101010011110101010101010111
```
<br/>
Każda liczba binarna zawiera co najwyżej <span className="bold">250 cyfr binarnych</span>, co oznacza, że w wielu
językach programowania wartości niektórych z tych liczb nie da się zapamiętać
w pojedynczej zmiennej typu całkowitoliczbowego, np. w języku C++ w zmiennej typu
int.<br/><br/>
<span className="bold">Napisz program</span>, który da odpowiedzi do poniższych zadań. Odpowiedzi zapisz w pliku
wynik4.txt, a każdą odpowiedź poprzedź numerem oznaczającym odpowiednie zadanie. 

<br/><br/>

### <u>Zadanie 3</u>   (0-6pkt.)
Znajdź najmniejszą i największą liczbę w pliku liczby.txt. Jako odpowiedź podaj
numery wierszy, w których się one znajdują.<br/><br/>

#### Przykład: 
Dla zestawu liczb:<br/>
101011010011001100111<br/>
10001001011101010<br/>
1001000<br/>
101010011100<br/>
1000110<br/>
najmniejsza liczba to: 1000110<br/>
największa liczba to: 101011010011001100111<br/>
Prawidłowa odpowiedź dla powyższego przykładu to: 5, 1.

<br/><br/>

<Dropdown title="ODPOWIEDŹ">

```text
859 – numer wiersza zawierającego minimalną liczbę
925 – numer wiersza zawierającego maksymalną liczbę 
```
</Dropdown>
<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
from functools import cmp_to_key
with open("liczby.txt", "r") as f:
    lines = f.readlines()

def comparer(num1, num2):   
    n = max(len(num1), len(num2))

    a = "0"*(n-len(num1))+num1
    b = "0"*(n-len(num2))+num2

    for i in range(n):
        if a[i] == "0" and b[i] == "1":
            return -1
        elif a[i] == "1" and b[i] == "0":
            return 1
        
    if len(num1) < len(num2):
        return -1
    elif len(num1) > len(num2):
        return 1
    else:
        return 0

lines = list(map(lambda x: x.strip(), lines))

sorted_lines = sorted(lines, key=cmp_to_key(comparer))
minimum, maximum = sorted_lines[0], sorted_lines[-1]

print(f"{lines.index(minimum)+1} – numer wiersza zawierającego minimalną liczbę")
print(f"{lines.index(maximum)+1} – numer wiersza zawierającego maksymalną liczbę")
```
</Dropdown>

<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - C++">

```
#include <iostream>
#include <fstream>
#include <algorithm>
#include <vector>
using namespace std;

vector<string> dane_z_pliku() {
    vector<string> dane; string s;
    ifstream plik; plik.open("liczby.txt");
    while(plik >> s) {
        dane.push_back(s);
    }
    plik.close();
    return dane;
}

bool wiecej_0_niz_1(string l) {
    int l0 = count(l.begin(), l.end(), '0');
    int l1 = count(l.begin(), l.end(), '1');
    return (l0 > l1);
}

bool podzielna_przez_2(string l) {
    return l.size() > 0 && l[l.size()-1] == '0';
}

bool podzielna_przez_8(string l) {
    int n = l.size();
    return ((n > 3) && (l[n-1] == '0') && (l[n-2] == '0') && (l[n-3] == '0'));
}

bool mniejsza(string a, string b) {
    int n = max(a.size(), b.size());

    if(a.size() != b.size()) {
        a = string(n-a.size(), '0') + a;
        b = string(n-b.size(), '0') + b;
    }

    if(a == b) return false;

    for(int i = 0; i < n; i++) {
        if(b[i] == '1' && a[i] == '0') return true;
        else if(a[i] == '1' && b[i] == '0') return false;
    }
    return false;
}

int main()
{
    vector<string> dane = dane_z_pliku();
    cout << "4.1) " << count_if(dane.begin(), dane.end(), wiecej_0_niz_1) << endl;
    cout << "4.2) podzielne przez 2 = " << count_if(dane.begin(), dane.end(), podzielna_przez_2) << endl;
    cout << "4.2) podzielne przez 8 = " << count_if(dane.begin(), dane.end(), podzielna_przez_8) << endl;
    cout << "4.3) najwieksza jest w indeksie " << 1+distance(dane.begin(), max_element(dane.begin(), dane.end(), mniejsza)) << endl;
    cout << "4.3) najmniejsza jest w indeksie " << 1+distance(dane.begin(), min_element(dane.begin(), dane.end(), mniejsza)) << endl;
    return 0;
}
```
</Dropdown>