import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/Informator_EM2023_informatyka.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/Informator_EM2023_informatyka_pliki.zip" >PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ

<b>Segmentem</b> nazwiemy spójny ciąg elementów tablicy składający się z <b>co najmniej</b> 1 elementu.

#### Przykład: 
dla tablicy A = [1, 8, 4, 2, 7, 9] segmentami są ciąg 1,8,4 oraz ciąg 8,4,2,7, natomiast
nie jest segmentem ciąg 8,2,7,9 (bo w tablicy A pomiędzy liczbą 8 a liczbą 2 jest liczba 4).

<br/><br/>

### <u>Zadanie 3</u>   (0-3pkt.)

Elementy pewnej 1000-elementowej tablicy A zapisano kolejno w pliku <i>dane1_3.txt</i>.
Każda z liczb w pliku <i>dane1_3.txt</i> należy do przedziału od [-100, 100] i jest zapisana
w oddzielnym wierszu. <br/>
Napisz program wyznaczający największą sumę segmentu tablicy A.

<br/>
<br/>

<Dropdown title="ODPOWIEDŹ">

```text
2265
```

</Dropdown>


<Dropdown title="ROZWIĄZANIE PRZYKŁADOWE - C++">

```
ifstream plik("dane1_3.txt");
ofstream wynik("zadanie1_3.txt");
const int n = 1000;
int t[n];
for (int i = 0; i < n; i++)
    plik >> t[i];
int max = -100;
for (int dl = n; dl >= 1; dl--) {
    for (int pocz = 0; pocz <= n - dl; pocz++) {
        int suma = 0;
        for (int k = 0; k < dl; k++)
            suma += t[k + pocz];
        if (suma > max)
            max = suma;
    }
}
wynik << max << endl;
```

<p className={`dropdown_text`}>
Rozwiązanie z <a className="link" target="_blank"  href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/Informator_EM2023_informatyka.pdf">https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/Informator_EM2023_informatyka.pdf</a><br/>

Powyższy program przykładowy ma złożoność sześcienną. Można napisać programy
o znacznie lepszej (kwadratowej, a nawet liniowej) złożoności, ale ten program jest bardzo
prosty i wynika wprost z definicji problemu, a dla danych z pliku <i>dane1_3.txt</i> jest
wystarczająco szybki.
    
</p>

</Dropdown>








<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def get_numbers() -> list[int]:

    with open(r"dane1_3.txt") as text_file:
        lines = text_file.read().strip().split("\n")

    return [int(number) for number in lines]


def main():
    numbers = get_numbers()

    max_sum = 0
    current_sum = numbers[0]

    for index in range(1, len(numbers)):
        if current_sum > 0:
            current_sum += numbers[index]

        else:
            current_sum = numbers[index]

        if max_sum < current_sum:
            max_sum = current_sum
    print(max_sum)


if __name__ == "__main__":
    main()

```
</Dropdown>
