<br/>
Funkcja to wydzielony fragment kodu, który może być wykonywany wielokrotnie w różnych miejscach programu. <br/><br/>Funkcje pomagają utrzymać kod w czystości i sprawiają, że jest on bardziej czytelny.<br/><br/>
Funkcję definiujemy za pomocą słowa **def**, następnie piszemy **nazwę funkcji**, a potem nawiasy okrągłe **()** i dwukropek. Kod, który ma wykonać się w funkcji, piszemy z wcięciem.

```python
def przywitanie():
    print("Hello, world!")
```
Funkcję wywołujemy w następujący sposób:
```python
przywitanie() # Hello, world!
```
Funkcja może przyjmować argumenty i zwracać wartości. Dowolną liczbę argumentów zapisujemy wewnątrz nawiasów, z kolei wartość zwracamy za pomocą **return** wewnątrz funkcji.
```python
def dodaj(a, b):
    return a+b

wynik = dodaj(2, 3)
print(wynik) # 5
print(dodaj(10, 20)) # 30
```

Możemy zwrócić więcej niż jedną wartość z funkcji, zapisując je po przecinku przy `return`. Odczytujemy zapisując oddzielone przecinkami nazwy zmiennych, do których je przypisujemy:
```python=
def wiele_wartosci():
    return 1, 2, 3

a, b, c = wiele_wartosci()
print(a) # 1
print(b) # 2
print(c) # 3
```

Wynik tej funkcji możemy również przypisać do jednej zmiennej. Otrzymamy wtedy krotkę:
```python=
wszystko = wiele_wartosci()
print(wszystko) # (1, 2, 3)
```
