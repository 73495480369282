import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2016/formula_od_2015/MIN-R2_1P-162.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2016/formula_od_2015/Dane_PR2.zip" >PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ
Podstawieniowy szyfr Cezara z przesunięciem (kluczem) k polega na zastąpieniu każdego
znaku jawnego znakiem leżącym w alfabecie o k pozycji w prawo od zastępowanego znaku.
Przykład: znak ‘B’ po zakodowaniu kluczem k=3 zastąpiony zostanie znakiem ‘E’. <br/>
![](https://i.imgur.com/ePB4F8g.png)<br/>
Przy szyfrowaniu znaku należy postępować w sposób cykliczny, to znaczy, jeżeli znak nie
posiada w alfabecie następnika przesuniętego o k pozycji, to alfabet „zawija się" i za literą Z
następuje znów litera A.<br/>
Przykład: jawny znak ‘X’ po zakodowaniu kluczem k=3 zastąpiony zostanie znakiem ‘A’,
znak ‘Y’ – znakiem ‘B’, natomiast ‘Z’ – znakiem ‘C’. <br/>
![](https://i.imgur.com/Ikakpev.png)<br/>
W tym zadaniu rozpatrujemy tylko słowa zbudowane z wielkich liter alfabetu angielskiego
(o kodach ASCII odpowiednio od 65 do 90), o długościach nie większych niż 30 znaków. 

<br/><br/>


### <u>Zadanie 3</u>   (0-5pkt.)
W pliku <i>dane_6_3.txt</i> zapisano 3 000 par słów, po jednej parze w wierszu, oddzielonych
pojedynczym znakiem odstępu. Drugie słowo w każdej parze jest szyfrogramem pierwszego
z nieznanym kluczem.<br/>
Niektóre szyfrogramy są błędne, co oznacza, że niektóre litery w słowie zakodowano
z różnymi przesunięciami. Słowo ma zawsze tę samą długość co odpowiadający
mu szyfrogram.<br/><br/>
Fragment pliku <i>dane_6_3.txt</i>:<br/>
```text
ZAWISLAK EFBNXQFP
KRASZEWSKI XENFMRJFXV
```
<br/>
<span className="bold">Napisz program</span>, który wyszuka i wypisze te słowa z pliku <i>dane_6_3.txt</i>, które błędnie
zaszyfrowano. Wynik zapisz w pliku <i>wyniki_6_3.txt</i>: każde słowo w osobnym wierszu,
w porządku odpowiadającym kolejności tych słów z pliku z danymi.<br/><br/>
<span className="bold">Uwaga</span>:<br/>
Pierwsze słowo w pliku wynikowym to SMIGIELSKI. 

<br/>
<br/>

<Dropdown title="ODPOWIEDŹ">

```text
SMIGIELSKI
JANEK
JANUSZEWSKI
WOLAK
GAJEK
MROCZKOWSKI
SZCZESNIAK
CIESLINSKI 
```
</Dropdown>
<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
with open("dane_6_3.txt", "r") as f:
    lines = f.readlines()

def decipher(letter, key):
    new_ord = ord(letter) - key
    if new_ord < 65:
        new_ord = 91 - (65 - new_ord)
    
    return chr(new_ord)

def get_key(text_letter, cipher_letter):
    if ord(cipher_letter) > ord(text_letter):
        return ord(cipher_letter) - ord(text_letter)
    else:
        return (ord(cipher_letter) - 65) + (90 - ord(text_letter)) + 1

for line in lines:
    line = line.strip()

    (word, cipher) = line.split()

    key = get_key(word[0], cipher[0])

    letters = [*cipher]
    deciphered = list(map(lambda x: decipher(x, key), letters))
    if word != "".join(deciphered):
        print(word)
```
</Dropdown>
