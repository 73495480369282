import Dropdown from "../../components/dropdown/Dropdown.jsx";
import TableQuiz from "../../components/tableQuiz/TableQuiz.jsx";
import Quiz from "../../components/Quiz.jsx";

## Zadanie 1 (0-1pkt.)
(maj 2015 część 1 zadanie 2.3)

### TREŚĆ
Filtrowanie tabeli w bazie danych

<TableQuiz 
  questions={
    [
      {question: "polega na wyborze wierszy spełniających określone kryterium.", answer: 'P'},
      {question: "polega na wyborze niektórych kolumn z tabeli.", answer: 'F'},
      {question: "zmienia jej zawartość.", answer: 'F'},
      {question: "wymaga podania warunku dla jednej lub kilku kolumn tabeli.", answer: 'P'}
    ]
  } 
/>

## Zadanie 2 (0-1pkt.)
(maj 2017 część 1 zadanie 3.1)

### TREŚĆ
Po wykonaniu podanego zapytania SQL do pewnej bazy danych wyniki będą zawsze
uporządkowane niemalejąco według pola nazwa. 

<TableQuiz 
    questions={
      [
        {question: "SELECT nazwa, wartosc FROM dane ORDER BY wartosc, nazwa", answer: 'F'},
        {question: "SELECT nazwa, wartosc FROM dane ORDER BY nazwa", answer: 'P'},
        {question: "SELECT nazwa, sum(wartosc) FROM dane GROUP BY nazwa", answer: 'F'},
        {question: "SELECT nazwa, sum(wartosc) FROM dane GROUP BY nazwa ORDER BY nazwa ", answer: 'P'}
      ]
    }
/>

## Zadanie 3 (0-1pkt.)
(maj 2017 część 1 zadanie 3.2)

### TREŚĆ
Rozważ następujące zapytanie SQL do pewnej bazy danych:
<pre>
    SELECT pesel, COUNT(*) <br/>
    FROM samochody <br/>
    WHERE pesel NOT IN (SELECT pesel FROM dokumenty_zastrzezone) <br/>
    GROUP BY pesel HAVING COUNT(*) > 1 <br/><br/>
</pre>

Po wykonaniu tego zapytania w odpowiedzi

<TableQuiz
    questions={
      [
        {question: "ten sam numer PESEL może pojawić więcej niż jeden raz. ", answer: 'F'},
        {question: "nie pojawi się żaden numer PESEL, który jest zapisany w tabeli dokumenty_zastrzezone.", answer: 'P'},
        {question: "otrzymasz tabelę o 2 kolumnach.", answer: 'P'},
        {question: "przy odpowiednich danych może pojawić się wiersz 82122302134, 1. ", answer: 'F'}
      ]
    }
/>

**Uwaga:** kolumna pesel zawiera numery PESEL

## Zadanie 4 (0-1pkt.)
(maj 2018 część 1 zadanie 3.3)

### TREŚĆ
Wskaż zdania prawdziwe dla języka SQL

<TableQuiz 
    questions={
      [
        {question: "W wynikach zapytania postaci SELECT (...) ORDER BY (...) zawsze dostajemy rekordy uporządkowane ściśle rosnąco według wskazanego pola. ", answer: 'F'},
        {question: "Zapytanie UPDATE może zmienić wartości pól w bazie danych.", answer: 'P'},
        {question: "Zapytanie postaci SELECT * FROM tabela1 WHERE pole LIKE (...) może w pewnych warunkach dać wszystkie rekordy z tabeli tabela1.", answer: 'P'},
        {question: "Wynik zapytania SELECT * FROM tabela1 JOIN tabela2 ON tabela1.pole = tabela2.pole może być pusty przy niepustych tabelach tabela1 oraz tabela2.", answer: 'P'}
      ]
    }
/>


## Zadanie 5 (0-1pkt.)
(maj 2019 część 1 zadanie 3.1)

### TREŚĆ
Dana jest tabela PRACOWNICY.		
<img src={new URL('https://i.imgur.com/NQ2kOAB.png', import.meta.url)} alt="xyz" className={`medium_img left_img`} />

<TableQuiz 
    questions={
      [
        {question: <img src={new URL('https://i.imgur.com/DPLaTa4.png', import.meta.url)} alt="xyz" className={`small_img`} />, answer: 'F'},
        {question: <img src={new URL('https://i.imgur.com/8XR746A.png', import.meta.url)} alt="xyz" className={`small_img`} />, answer: 'P'},
        {question: <img src={new URL('https://i.imgur.com/kjkxEsz.png', import.meta.url)} alt="xyz" className={`small_img`} />, answer: 'P'},
        {question: <img src={new URL('https://i.imgur.com/5CnnY4h.png', import.meta.url)} alt="xyz" className={`small_img`} />, answer: 'F'}
      ]
    }
/>

## Zadanie 6 (0-1pkt.)
(maj 2020 część 1 zadanie 3.4)

### TREŚĆ
Dana jest tabela pracownicy 	
<img src={new URL('https://i.imgur.com/QKo7ulX.png', import.meta.url)} alt="xyz" className={`medium_img left_img`} />
oraz zapytanie SQL:
<p>
    SELECT * <br/>
    FROM pracownicy <br/>
    WHERE pensja  (mniejsze niż) 2000 <br/>
    ORDER BY staz DESC; 
</p>

<TableQuiz
    questions={
      [
        {question: <p>Wynik powyższego zapytania to 3</p>, answer: 'F'},
        {question: <img src={new URL('https://i.imgur.com/TDosuSg.png', import.meta.url)} alt="xyz" className={`small_img`} />, answer: 'P'},
        {question: <p>Wynikiem zapytania będą wiersze z tabeli ``pracownicy``, zawierające wszystkie dane z tej tabeli dotyczące pracowników, dla których wartość z kolumny ``pensja`` jest mniejsza niż 2000, posortowane nierosnąco według parametru ``staz``. </p>, answer: 'P'},
        {question: <img src={new URL('https://i.imgur.com/Xf2xIf7.png', import.meta.url)} alt="xyz" className={`small_img`} />, answer: 'F'}
      ]
    }
/>

## Zadanie 7 (0-1pkt.)
(maj 2021 część 1 zadanie 3.3)

### TREŚĆ
W bazie danych istnieje tabela `produkty(id_produktu, produkt, sztuk, cena)`, zawierająca
następujące dane: 	
<img src={new URL('https://i.imgur.com/yaB2M0z.png', import.meta.url)} alt="xyz" className={`super_small_img left_img`} />

<TableQuiz 
    questions={
      [
        {question: <img src={new URL('https://i.imgur.com/168P4ma.png', import.meta.url)} alt="xyz" className={`small_img`} />, answer: 'F'},
        {question: <img src={new URL('https://i.imgur.com/JOC0v30.png', import.meta.url)} alt="xyz" className={`small_img`} />, answer: 'P'},
        {question: <img src={new URL('https://i.imgur.com/MqiA8kx.png', import.meta.url)} alt="xyz" className={`small_img`} />, answer: 'P'},
        {question: <img src={new URL('https://i.imgur.com/kvkfiSZ.png', import.meta.url)} alt="xyz" className={`small_img`} />, answer: 'F'},
      ]
    }
/>

## Zadanie 8 (0-1pkt.)
(maj 2022 część 1 zadanie 3.3)

### TREŚĆ
W bazie danych istnieje tabela `mandaty(numer, id_osoby, punkty)` zawierająca następujące
dane:
<img src={new URL('https://i.imgur.com/yHJifOp.png', import.meta.url)} alt="xyz" className={`super_small_img left_img`} />

<TableQuiz 
    questions={
      [
        {question: <img src={new URL('https://i.imgur.com/K220hxV.png', import.meta.url)} alt="xyz" className={`small_img`} />, answer: 'F'},
        {question: <img src={new URL('https://i.imgur.com/pvawnQq.png', import.meta.url)} alt="xyz" className={`small_img`} />, answer: 'P'},
        {question: <img src={new URL('https://i.imgur.com/9jqNByo.png', import.meta.url)} alt="xyz" className={`small_img`} />, answer: 'F'},
        {question: <img src={new URL('https://i.imgur.com/rrLMv6q.png', import.meta.url)} alt="xyz" className={`small_img`} />, answer: 'P'},
      ]
    }
/>


## Zadanie 9 (0-1pkt.)
(maj 2017 stara matura, poziom podstawowy, część 1 zadanie 3.5)

<Quiz 
    question={
      <div>
        <p>
          Dane zapisane w tabeli 1 rozdzielono pomiędzy trzy tabele – tabela 2, tabela 3, tabela 4  
        </p>
        <img src={new URL('https://i.imgur.com/22wxtF3.png', import.meta.url)} alt="xyz" className={`medium_img`} />
        <p>Taka zamiana</p>
      </div>
    }
    answers={[
        "pozwala usunąć nadmiarowe dane.",
        "powoduje utratę części danych.",
        "nie wymaga połączenia tabel relacjami.",
        "wywołuje redundancję bazy danych."]}
    correct={"pozwala usunąć nadmiarowe dane."}
/>

## Zadanie 10 (0-1pkt.)
(maj 2015 stara matura, poziom podstawowy, część 1 zadanie 3.3)

### TREŚĆ
Klucz podstawowy w tabeli bazy danych 

<Quiz 
    question={''}
    answers={[
        "zawiera wartości wyłącznie numeryczne.",
        "umożliwia jednoznaczną identyfikację wiersza.",
        "umożliwia jednoznaczną identyfikację kolumny.",
        "nie może służyć do łączenia z inną tabelą."]}
    correct={"umożliwia jednoznaczną identyfikację wiersza."}
/>

## Zadanie 11 (0-1pkt.)
(maj 2013 stara matura, poziom podstawowy, część 1 zadanie 3a)

### TREŚĆ
Kolumna w tabeli bazy danych

<Quiz 
    question={''}
    answers={[
        "zawiera dane różnych typów.",
        "zawiera dane tego samego typu.",
        "może mieć taką samą nazwę, jak inna kolumna w tej samej tabeli."]}
    correct={"zawiera dane tego samego typu."}
/>

## Zadanie 12 (0-1pkt.)
(maj 2011 stara matura, poziom podstawowy, część 1 zadanie 3f)

### TREŚĆ
W relacyjnym modelu bazy danych 

<Quiz 
    question={''}
    answers={[
      "wyróżniamy trzy rodzaje relacji.",
      "podstawową formą organizacji danych są kwerendy.",
      "kluczem podstawowym nie może być kolumna zawierająca tylko i wyłącznie teksty."]}
    correct={"wyróżniamy trzy rodzaje relacji."}
/>


## Zadanie 13 (0-1pkt.)
(maj 2009 stara matura, poziom podstawowy, część 1 zadanie 3a)

### TREŚĆ
Tabela bazy danych spełnia warunki:

<TableQuiz 
    questions={
      [
        {question: "jest podstawową jednostką organizacji danych w relacyjnych bazach danych", answer: 'P'},
        {question: "każdy jej wiersz składa się tylko z danych tego samego typu", answer: 'F'},
        {question: "wszystkie dane w kolumnie są tego samego typu", answer: 'P'}
      ]
    }
/>

## Zadanie 14 (0-1pkt.)
(maj 2009 stara matura, poziom podstawowy, część 1 zadanie 3h)

### TREŚĆ
W bazie danych

<TableQuiz 
    questions={
      [
        {question: "sortowanie tabeli oznacza uporządkowanie wierszy według zawartości wybranych kolumn", answer: 'P'},
        {question: "sortowanie tabeli oznacza uporządkowanie kolumn według zawartości wybranych wierszy.", answer: 'F'},
        {question: "filtrowanie tabeli to wybór wierszy spełniających określone kryteria", answer: 'P'}
      ]
    }
/>

## Zadanie 14 (0-1pkt.)
(maj 2012 stara matura, poziom rozszerzony, część 1 zadanie 3a)

### TREŚĆ
Poniżej przedstawiono fragment bazy danych zawierającej informacje o książkach,
czytelnikach i wypożyczeniach. Pole *id_Cz* w tabeli *Czytelnicy* jest połączone relacją
„jeden do wielu” z polem *id_Cz* w tabeli *Wypożyczenia*, podobnie pole *id_Ks* w tabeli
*Książki* z polem *id_Ks* w tabeli *Wypożyczenia*. 
<img src={new URL('https://i.imgur.com/on36BTj.png', import.meta.url)} alt="xyz" className={`medium_img`} />

<TableQuiz 
    questions={
      [
        {question: "Jowita Kolska wypożyczyła „Misję Ambasadora”.", answer: 'P'},
        {question: "Bajki Robotów” były wypożyczane dwa razy.", answer: 'P'},
        {question: "Z podanych klas (I, II, III) najwięcej książek wypożyczyli uczniowie klasy II.", answer: 'F'},
        {question: "Jacek Doniec nie wypożyczył jeszcze żadnej książki", answer: 'P'}
      ]
    }
/>




