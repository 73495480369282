<br/>

Symbole wieloznacze są niezwykle przydatne w funkcjach wyszukiwania i zmiany tekstu. 

## Rodzaje i znaczenie symboli wieloznacznych

* **?** (znak zapytania) - reprezentuje dowolny pojedynczy znak.

Na przykład es?a umożliwia znalezienie wyrazów "eska" i "essa"

* **\*** (gwiazdka) - może zastępować dowolna liczba znaków.

Na przykład *ka umożliwia znalezienie wyrazów "odklejka" i "alternatywka"

* **~** (tylda), a po niej znak ?, * lub ~ - stosujemy, aby znaleźć te znaki specjalne.

Na przykład serio~? Znajduje "serio?"

## Przykłady użycia

Symbole wieloznaczne są bardzo często wykorzystywane podczas używania funkcji takich jak "Znajdź" lub "Zamień"

![](https://i.imgur.com/QsDYOTs.png)

![](https://i.imgur.com/mjhCTg8.png)

<img src={new URL('https://i.imgur.com/yYh9PAu.png', import.meta.url)} alt="xyz" className={`medium_img`} />


