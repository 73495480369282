import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2022-czerwiec-matura-rozszerzona-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2022-czerwiec-matura-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ
W pliku <i>liczby.txt</i> zapisano 100 nieparzystych liczb całkowitych z przedziału [10, 9999].
Liczby w pliku mogą się powtarzać.<br/>
<b>Odbiciem</b> dodatniej nieparzystej liczby całkowitej n nazywamy taką liczbę N, w której zapisie
dziesiętnym nastąpiło odwrócenie kolejności cyfr.<br/><br/>
<b>Przykład:<br/></b>
Odbiciem liczby 2019 jest 9102, natomiast odbiciem liczby 12345 jest 54321.<br/>
<b>Napisz program</b> (lub kilka programów), który(-e) znajdzie(-dą) odpowiedzi na poniższe
pytania. Każdą odpowiedź zapisz w pliku <i>wyniki4.txt</i> i poprzedź ją numerem
oznaczającym zadanie.<br/><br/>
Do dyspozycji masz również plik <i>przyklad.txt</i> zawierający tylko 11 nieparzystych liczb
całkowitych z przedziału [10, 9999] – odpowiedzi dla tego pliku podane są w treściach
zadań, możesz sprawdzać na nim działanie swojego programu.<br/><br/>
<b>Uwaga:<br/></b> Pamiętaj, że Twój program (lub kilka programów) musi(-szą) ostatecznie działać dla
100 liczb zapisanych w pliku <i>liczby.txt</i>.

<br/><br/>

### <u>Zadanie 3</u> (3pkt)
Wypisz wszystkie liczby pierwsze z pliku <i>liczby.txt</i>, których odbicia również są liczbami
pierwszymi, każdą w oddzielnym wierszu.<br/><br/>
Dla pliku <i>przyklad.txt</i> odpowiedź to:

```text
13
131
(odbiciem liczby 13 jest 31 – obie są liczbami pierwszymi, odbiciem 131 jest 131)
```


<br/><br/>
<Dropdown title="ODPOWIEDŹ">

```text
157
31
347
929
941
761
```
</Dropdown>




<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def is_prime(number: str) -> bool:
    number = int(number)

    for divider in range(2, number):
        if number % divider:
            continue

        return False

    return True


def is_valid(number: str) -> bool:
    return is_prime(number) and is_prime(number[::-1])


def main():
    with open(r"liczby.txt") as text_file:
        numbers = text_file.read().strip().split()

    numbers = list(filter(is_valid, numbers))

    print(*numbers, sep="\n")


if __name__ == "__main__":
    main()

```
</Dropdown>
