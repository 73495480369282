import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/Informator_EM2023_informatyka.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/Informator_EM2023_informatyka_pliki.zip" >PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ

Wyrażeniem nawiasowym nazywamy dowolny skończony ciąg nawiasów: „[” i „]”.<br/>

#### Przykładowo: 
[ [ ] [ ] ]

<br/>
<b>Wyrażenie nawiasowe jest poprawne</b>, jeśli:
– jest wyrażeniem pustym (nie zawiera żadnych nawiasów)
– jest postaci AB, gdzie A i B są poprawnymi wyrażeniami nawiasowymi
– jest postaci [ A ], gdzie A jest poprawnym wyrażeniem nawiasowym
<br/><br/>

#### Przykład: 
wyrażenia [ [ ] ] oraz [ [ ] [ ] ] są poprawne. Niepoprawne jest za to wyrażenie
[ ] ] [ ] ].

<br/>
Niech w<sub>1</sub>, w<sub>2</sub>, …, w<sub>n</sub> będą kolejnymi nawiasami w pewnym wyrażeniu nawiasowym W.
Przyjmijmy teraz, że z każdym nawiasem otwierającym „[” wiążemy liczbę +1, a z każdym
nawiasem zamykającym „]” – liczbę -1. Niech si będzie liczbą związaną z nawiasem wi.
Wówczas <b>głębokością nawiasu wk w wyrażeniu W</b> nazywamy sumę:
S<sub>k</sub> = s<sub>1</sub> + <sub>s2</sub> + … + <sub>k</sub>
Głębokością wyrażenia W nazwiemy największą głębokość jego nawiasów, czyli maksimum
z liczb S<sub>k</sub>.

<br/><br/>

### Zadanie 4   (0-3pkt.)

Napisz program, który dla wyrażeń nawiasowych zapisanych w pliku <i>dane2_4.txt</i>
sprawdzi, czy są one poprawne. Dane w pliku zapisano po jednym wyrażeniu w wierszu,
podobnie jak w pliku o nazwie <i>dane2_3.txt.</i><br/><br/>

Dla danych z pierwszych czterech wierszy w pliku <i>dane2_4.txt</i>:

```text
[][][]
[[[]]]
[[][][]]
[[[]]]]
```

<br/>
<br/>


<Dropdown title="ODPOWIEDŹ">

```text
tak
tak
tak
nie
```

</Dropdown>


<Dropdown title="ROZWIĄZANIE PRZYKŁADOWE - C++">

```
ifstream dane("dane2_4.txt");
ofstream wynik("zadanie2_4.txt");

string wiersz;
while (dane >> wiersz) {
    int balans = 0;
    bool poprawne = true;
    for (string::size_type i = 0; i < wiersz.size(); i++) {
        if (wiersz[i] == '[')
            balans++;
        else { // wiersz[i] == ']'
            balans--;
            if (balans < 0) {
                poprawne = false;
                break;
            }
        }
    }
    if (poprawne && balans == 0)
        wynik << "tak\n";
    else
        wynik << "nie\n";
} 
```
<p className={`dropdown_text`}>
Rozwiązanie z <a className="link" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/Informator_EM2023_informatyka.pdf">https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/Informator_EM2023_informatyka.pdf</a><br/>
</p>

</Dropdown>








<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def get_lines() -> list[int]:

    with open(r"dane2_4.txt") as text_file:
        lines = text_file.read().strip().split("\n")

    return lines


def is_valid(string: str) -> int:
    current_depth = 0
    values = {"[": 1, "]": -1}

    for character in string:
        current_depth += values.get(character)

    return current_depth == 0


def main():
    liens = get_lines()

    for line in liens:
        print("tak" if is_valid(line) else "nie")


if __name__ == "__main__":
    main()

```
</Dropdown>
