import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2015/formula_od_2015/MIN-R2_1P-152.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2015/formula_od_2015/Dane_PR2.zip">PLIKI DO ZADANIA</a>
</div>

### TREŚĆ
W pliku liczby.txt znajduje się 1000 liczb naturalnych zapisanych binarnie. Każda
liczba zapisana jest w osobnym wierszu. Pierwsze pięć wierszy zawiera następujące liczby:<br/>

```
11010100111
11110111111011101
1010100111010100
1101111111111111111111010100101010101001
1010110011001101010011110101010101010111
```
<br/>
Każda liczba binarna zawiera co najwyżej <span className="bold">250 cyfr binarnych</span>, co oznacza, że w wielu
językach programowania wartości niektórych z tych liczb nie da się zapamiętać
w pojedynczej zmiennej typu całkowitoliczbowego, np. w języku C++ w zmiennej typu
int.<br/><br/>
<span className="bold">Napisz program</span>, który da odpowiedzi do poniższych zadań. Odpowiedzi zapisz w pliku
wynik4.txt, a każdą odpowiedź poprzedź numerem oznaczającym odpowiednie zadanie. 


<br/><br/>

### <u>Zadanie 2</u>   (0-4pkt.)
Podaj, ile liczb w pliku liczby.txt jest podzielnych przez 2 oraz ile liczb jest podzielnych
przez 8.<br/><br/>

#### Przykład:
Dla zestawu liczb:<br/>
101011010011001100000 (\*), (\*\*)<br/>
10001001<br/>
100100 (\*)<br/>
101010010101011011000 (\*), (\*\*)<br/>
100011<br/>
trzy liczby są podzielne przez 2 (*) i dwie liczby są podzielne przez 8 (**). 

<br/><br/>

<Dropdown title="ODPOWIEDŹ">

``` text
500 – liczba liczb podzielnych przez 2
123 – liczba liczb podzielnych przez 8
```
</Dropdown>
<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
with open("liczby.txt", "r") as f:
    lines = f.readlines()
    
by_8 = 0
by_2 = 0
for line in lines:
    line = line.strip()
    if line[-3:] == "000":
        by_8 += 1
    
    if line[-1] == "0":
        by_2 += 1
print(f"{by_2} – liczba liczb podzielnych przez 2")
print(f"{by_8} – liczba liczb podzielnych przez 8")
```
</Dropdown>

<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - C++">

```
#include <iostream>
#include <fstream>
#include <algorithm>
#include <vector>
using namespace std;

vector<string> dane_z_pliku() {
    vector<string> dane; string s;
    ifstream plik; plik.open("liczby.txt");
    while(plik >> s) {
        dane.push_back(s);
    }
    plik.close();
    return dane;
}

bool wiecej_0_niz_1(string l) {
    int l0 = count(l.begin(), l.end(), '0');
    int l1 = count(l.begin(), l.end(), '1');
    return (l0 > l1);
}

bool podzielna_przez_2(string l) {
    return l.size() > 0 && l[l.size()-1] == '0';
}

bool podzielna_przez_8(string l) {
    int n = l.size();
    return ((n > 3) && (l[n-1] == '0') && (l[n-2] == '0') && (l[n-3] == '0'));
}

bool mniejsza(string a, string b) {
    int n = max(a.size(), b.size());

    if(a.size() != b.size()) {
        a = string(n-a.size(), '0') + a;
        b = string(n-b.size(), '0') + b;
    }

    if(a == b) return false;

    for(int i = 0; i < n; i++) {
        if(b[i] == '1' && a[i] == '0') return true;
        else if(a[i] == '1' && b[i] == '0') return false;
    }
    return false;
}

int main()
{
    vector<string> dane = dane_z_pliku();
    cout << "4.1) " << count_if(dane.begin(), dane.end(), wiecej_0_niz_1) << endl;
    cout << "4.2) podzielne przez 2 = " << count_if(dane.begin(), dane.end(), podzielna_przez_2) << endl;
    cout << "4.2) podzielne przez 8 = " << count_if(dane.begin(), dane.end(), podzielna_przez_8) << endl;
    cout << "4.3) najwieksza jest w indeksie " << 1+distance(dane.begin(), max_element(dane.begin(), dane.end(), mniejsza)) << endl;
    cout << "4.3) najmniejsza jest w indeksie " << 1+distance(dane.begin(), min_element(dane.begin(), dane.end(), mniejsza)) << endl;
    return 0;
}
```
</Dropdown>
