import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/Informator_EM2023_informatyka.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/Informator_EM2023_informatyka_pliki.zip" >PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ

<b>Segmentem</b> nazwiemy spójny ciąg elementów tablicy składający się z <b>co najmniej</b> 1 elementu.

#### Przykład: 
dla tablicy A = [1, 8, 4, 2, 7, 9] segmentami są ciąg 1,8,4 oraz ciąg 8,4,2,7, natomiast
nie jest segmentem ciąg 8,2,7,9 (bo w tablicy A pomiędzy liczbą 8 a liczbą 2 jest liczba 4).

<br/><br/>

### <u>Zadanie 3</u>   (0-4pkt.)

Elementy pewnej tablicy A o 100 000 elementów zapisano kolejno w pliku <i>dane1_4.txt</i>.
Każda z liczb w pliku <i>dane1_4.txt</i> należy do przedziału od [-100, 100] i jest zapisana
w oddzielnym wierszu. <br/>
Przyjmujemy, że pierwszy element tablicy ma indeks równy 1. Napisz program wypisujący
indeks pierwszego i indeks ostatniego elementu segmentu o największej sumie. W tablicy A
jest tylko jeden taki segment, <b>a suma jego elementów jest dodatnia</b>.

<br/>
<br/>

<Dropdown title="ODPOWIEDŹ">

```text
63669 70769
```

</Dropdown>


<Dropdown title="ROZWIĄZANIE PRZYKŁADOWE - C++">

```
ifstream plik ("dane1_4.txt");
ofstream wynik ("zadanie1_4.txt");
const long n=100000;
int t[n];
for (long i=0; i<n; i++)
    plik>>t[i];
long maks; // największa suma niepustego segmentu
long maks_pocz; // początek najlepszego niepustego segmentu
long maks_kon; // koniec najlepszego niepustego segmentu
long ost_suma; // najlepsza niepusta suma segmentu na końcu przejrzanej części tablicy
long ost_pocz; // początek najlepszego niepustego segmentu na końcu przejrzanej części
tablicy
// pierwszy rozważany niepusty segment to t[0]:
maks = ost_suma = t[0];
maks_pocz = maks_kon = ost_pocz = 0;
for (long i = 1; i < n; i++) {
    if (ost_suma >= 0) // Czy ost_suma + t[i] >= t[i]?
        ost_suma += t[i];
    else {
        ost_suma = t[i];
        ost_pocz = i;
    }
    if (maks < ost_suma) {
        maks = ost_suma;
        maks_pocz = ost_pocz;
        maks_kon = i;
    }
}

wynik << maks_pocz + 1 << " " << maks_kon + 1 << endl;
// Tablica, której wartości były w pliku wejściowym miała indeksy przesunięte o 1
// musimy zatem uwzględnić poprawkę na przesuniecie indeksów względem tablicy t,
// do której wczytano dane z pliku
```
<p className={`dropdown_text`}>
Rozwiązanie z <a className="link" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/Informator_EM2023_informatyka.pdf">https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/Informatory/Informator_EM2023_informatyka.pdf</a><br/>
</p>

</Dropdown>






<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def get_numbers() -> list[int]:

    with open(r"dane1_4.txt") as text_file:
        lines = text_file.read().strip().split("\n")

    return [int(number) for number in lines]


def main():
    numbers = get_numbers()

    max_sum = 0
    current_sum = numbers[0]
    starting_index = 0
    max_startin_index = 0
    max_ending_index = 0

    for index in range(1, len(numbers)):
        if current_sum > 0:
            current_sum += numbers[index]

        else:
            current_sum = numbers[index]
            starting_index = index

        if max_sum < current_sum:
            max_sum = current_sum
            max_startin_index = starting_index
            max_ending_index = index

    print(max_startin_index + 1, max_ending_index + 1)


if __name__ == "__main__":
    main()

```
</Dropdown>
