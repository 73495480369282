import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2020/formula_od_2015/informatyka/MIN-R2_1P-202.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2020/formula_od_2015/informatyka/Dane_PR2.zip">PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ
W pliku <i>pary.txt</i> znajduje się 100 wierszy. Każdy wiersz zawiera parę danych składającą
się z liczby całkowitej z przedziału od 3 do 100 i słowa (ciągu znaków) złożonego z małych
liter alfabetu angielskiego o długości od 1 do 50 znaków. Liczba i słowo są oddzielone znakiem
spacji.<br/>
Napisz <span className="bold">program(-my)</span>, dający(-e) odpowiedzi do poniższych zadań. Uzyskane odpowiedzi
zapisz w pliku <i>wyniki4.txt</i>, poprzedzając każdą z nich numerem odpowiedniego zadania.<br/><br/>
<span className="bold">Uwaga</span>: plik <i>przyklad.txt</i> zawiera przykładowe dane spełniające warunki zadania.
Odpowiedzi dla danych z pliku <i>przyklad.txt</i> są podane pod treściami zadań oraz w pliku
<i>odp_przyklad.txt</i>. 

<br/><br/>

### <u>Zadanie 2</u>   (0-4pkt.)
Dla każdego słowa z pliku <i>pary.txt</i> znajdź długość najdłuższego spójnego fragmentu tego
słowa złożonego z identycznych liter. Wypisz znalezione fragmenty słów i ich długości
oddzielone spacją, po jednej parze w każdym wierszu. Jeżeli istnieją dwa fragmenty o takiej
samej największej długości, podaj pierwszy z nich. Wyniki podaj w kolejności zgodnej
z kolejnością danych w pliku <i>pary.txt</i>.<br/><br/>

#### Przykład:
dla słowa zxyzzzz wynikiem jest:<br/>
zzzz 4<br/>
natomiast dla słowa kkkabbb wynikiem jest:<br/>
kkk 3<br/>
Dla danych z pliku <i>przyklad.txt</i> odpowiedzi podano w pliku <i>odp_przyklad.txt</i>. 

<br/><br/>

<Dropdown title="ODPOWIEDŹ">

```text
d 1
tt 2
a 1
lllllll 7
ooooo 5
wwwwww 6
aaaaaaaaaaa 11
jj 2
aaaaaaaaaaaa 12
ttt 3
qqq 3
uu 2
s 1
pppp 4
sssssssssssss 13
ww 2
mm 2
ddddd 5
ss 2
ll 2
xxx 3
ttttt 5
hhhhhhhhh 9
ggggggg 7
pppp 4
w 1
kkkkkkkkk 9
v 1
x 1
z 1
ttttttt 7
c 1
tttttttttttt 12
yyyyyyyyyyyy 12
ccc 3
j 1
aaaaa 5
a 1
r 1
gg 2
zzzzzzzz 8
jj 2
oooooooooooooooo 16
ppppp 5
dddddd 6
pppp 4
x 1
pppp 4
d 1
sssss 5
aaaaaaa 7
pppp 4
kkkkkkkk 8
wwwwww 6
xxxx 4
s 1
gggg 4
bb 2
z 1
o 1
mmmmm 5
aaaaaaa 7
l 1
llll 4
ggggg 5
aaaaa 5
jjjjjjjjjj 10
a 1
jj 2
kkkkk 5
d 1
kkkkk 5
eee 3
tttttttttt 10
bbb 3
uuuuuuuuuu 10
dddd 4
ggggggggg 9
lll 3
ttttttttttttttttttttttttttttttttt 33
i 1
fffff 5
dd 2
bb 2
a 1
h 1
hh 2
aaaa 4
dddddddd 8
ee 2
d 1
rrrrrrrrrrrrrr 14
jjjjjjj 7
n 1
aaaaaaa 7
s 1
a 1
uuuu 4
jj 2
d 1
```

</Dropdown>


<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def get_words() -> list[int]:

    with open(r"pary.txt") as text_file:
        lines = text_file.read().strip().split("\n")

    numbers = []

    for line in lines:
        numbers.append(line.split()[1])

    return numbers


def get_longest_repeating_substring(word: str) -> str:

    if len(word) == 1:
        return word

    max_length = 0
    character = ""

    length = 1

    for index in range(1, len(word)):

        if word[index] != word[index - 1]:
            if length <= max_length:
                length = 1
                continue

            max_length = length
            character = word[index - 1]
            length = 1
            continue

        length += 1

    if length > max_length:
        max_length = length
        character = word[index - 1]

    return character * max_length


def main():
    words = get_words()

    for word in words:

        substring = get_longest_repeating_substring(word)

        print(substring, len(substring))


if __name__ == "__main__":
    main()

```
</Dropdown>
