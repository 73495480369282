
## Uwagi ogólne

### Praktyka czyni mistrza (oklepane, ale prawdziwe)
Aby być w stanie zrealizować wszystkie zadania maturalne w wyznaczonym czasie, trzeba być bardzo dobrze obeznanym z dostępnymi
funkcjami oraz możliwościami, jakie oferuje Excel.

### Dziel i zwycięzaj ;)
Warto wyrobić sobie nawyk dzielenia problemów na mniejsze
podproblemy i rozwiązywania zadań etapami, w ten sposób łatwiej jest zweryfikować wyniki i poprawić ewentualne błędy.
Ponadto często wynik częściowy z jednego podpunktu będzie stanowił bazę do rozwiązania kolejnego. <br/>
Realizację każdego podpunktu warto umieścić w osobnym arkuszu (zakłądce) Excela. Dzięki temu unikniesz

### Zapisuj pracę na bieżąco

### Pamiętaj o pliku z odpowiedziami

<br/><br/>
Zadania maturalne z Excela można podzielić na dwie główne kategorie: polegające na opracowaniu zaimportowanych danych oraz te wymagające stworzenia symulacji.

## Analiza i opracowanie danych

W tego typu zadaniach bardzo ważnym elementem jest **poprawne zaimportowanie danych**, błąd na tym etapie może skutkować
niepoprawnymi wynikami w dalszych częściach. Szczególną uwagę musimy zwracać na **format daty** oraz **typy danych**
w poszczególnych kolumnach. Ponieważ samo przygotowanie bazy zadania jest tu na ogół prostsze, niż stworzenie symulacji, to w zadaniach często wymagane będą bardziej zaawansowane **funkcje**.

## Symulacja

Zadania symulacyjne mają zazwyczaj długą treść i opowiadają pewną historię. Najważniejszym etapem jest więc
wyłuskanie danych z polecenia i rozbicie symulacji na mniejsze etapy. Pomocna może być tu strategia **osi czasu**,
na której rozrysowujemy wszystkie kroki, które musimy odzwierciedlić w naszym arkuszu. Zazwyczaj za jeden krok
wygodnie jest uważać każdy moment, w którym zmienia się jakaś wartość.