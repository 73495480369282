import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2018/formula_od_2015/informatyka/MIN-R2_1P-182.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2018/formula_od_2015/informatyka/Dane_PR2.zip">PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ
W ramach projektu WEGA naukowcom udało się odczytać sygnały radiowe pochodzące
z przestrzeni kosmicznej. Po wstępnej obróbce zapisali je do pliku <i>sygnaly.txt</i>.<br/>
W pliku <i>sygnaly.txt</i> znajduje się 1000 wierszy. Każdy wiersz zawiera jedno niepuste
słowo złożone z wielkich liter alfabetu angielskiego. Długość jednego słowa nie przekracza 100
znaków.<br/><br/>
Napisz <span className="bold">program(y)</span>, który(e) da(dzą) odpowiedzi do poniższych zadań. Odpowiedzi zapisz
w pliku <i>wyniki4.txt</i>, a każdą odpowiedź poprzedź numerem oznaczającym odpowiednie
zadanie.<br/><br/>
<span className="bold">Uwaga</span>: Plik <i>przyklad.txt</i> zawiera dane przykładowe spełniające warunki zadania.
Odpowiedzi dla danych z pliku <i>przyklad.txt</i> są podane pod pytaniami. 

<br/><br/>

### <u>Zadanie 1</u>   (0-3pkt.)
Naukowcy zauważyli, że po złączeniu dziesiątych liter co czterdziestego słowa (zaczynając od
słowa czterdziestego) otrzymamy pewne przesłanie. Wypisz to przesłanie.<br/>
<span className="bold">Uwaga</span>: Każde co czterdzieste słowo ma co najmniej 10 znaków.<br/><br/>
Dla danych z pliku <i>przyklad.txt</i> wynikiem jest:

```text
NIECHCIMATURAPROSTABEDZIE 
```

<br/><br/>

<Dropdown title="ODPOWIEDŹ">

```text
ZAPISZODPOWIEDZIWPLIKUTXT 
```
</Dropdown>

<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def main():
    with open("sygnaly.txt") as text_file:
        lines = text_file.read().strip().split()

    word = "".join([w[9] for w in lines[39::40]])

    print(word)


if __name__ == "__main__":
    main()

```
</Dropdown>
