import Quiz from "../../../components/Quiz.jsx";
import Code from "../../../components/code/Code.jsx";

<Quiz 
    question={
        <>
            <p>Co wypisze wypisze w terminalu poniższy fragment kodu</p>
            <Code>A or not B and C</Code>
            <p>jeśli A, B, C = False, True, True</p>
        </>
    }
    answers={["True", "False", "Program zakończy się błędem"]}
    correct={"False"}
/>