import Dropdown from "../../components/dropdown/Dropdown.jsx";
import TableQuiz from "../../components/tableQuiz/TableQuiz.jsx";
import Quiz from "../../components/Quiz.jsx";
import FillGapsQuiz from "../../components/FillGapsQuiz.jsx";

## Zadanie 1 (0-1pkt.)
(maj 2016 część 1 zadanie 3.1)

### TREŚĆ
Po wpisaniu w pasku adresu przeglądarki http://81.219.47.83 otwiera się strona Centralnej
Komisji Egzaminacyjnej, ale po wpisaniu http://cke.edu.pl pojawia się błąd „Nie można
odnaleźć podanej strony”. Możliwe przyczyny tego stanu rzeczy to: 

<TableQuiz
    questions={
      [
        {question: "awaria serwera SMTP Centralnej Komisji Egzaminacyjnej, ", answer: 'F'},
        {question: "awaria serwera poczty użytkownika, ", answer: 'F'},
        {question: "awaria serwera DNS,", answer: 'P'},
        {question: "brak prawidłowego klucza szyfrującego w przeglądarce.", answer: 'F'},
      ]
    }
/>


## Zadanie 2 (0-1pkt.)
(maj 2018 część 1 zadanie 3.1)

### TREŚĆ
Na pewnym serwerze WWW znajduje się strona napisana w języku PHP, a jej kod zawiera
fragmenty w języku JavaScript. Pewien komputer-klient pobrał i wyświetlił tę stronę.
Wiadomo, że:

<TableQuiz 
    questions={
      [
        {question: "kod PHP jest wykonywany przez komputer – serwer. ", answer: 'P'},
        {question: "kod JavaScript jest wykonywany przez komputer – klient. ", answer: 'P'},
        {question: "podczas wykonywania kodu PHP zawsze pobierane są dane od klienta.", answer: 'F'},
        {question: "podczas wykonywania kodu JavaScript mogą być pobierane dodatkowe dane zarówno od klienta, jak i od serwera.", answer: 'P'},
      ]
    }
/>

## Zadanie 3 (0-1pkt.)
(maj 2019 część 1 zadanie 3.3)

### TREŚĆ
<TableQuiz 
    questions={
      [
        {question: "DNS to skrót od Domain Name System. ", answer: 'P'},
        {question: "Do danego adresu IP może być przypisanych wiele różnych nazw. ", answer: 'P'},
        {question: "Przy zmianie adresu IP komputera pełniącego funkcję serwera WWW jest konieczna zmiana nazwy domeny internetowej.", answer: 'F'},
        {question: "System DNS ma jedną centralną bazę danych adresów IP i nazw.", answer: 'F'},
      ]
    }
/>

## Zadanie 4 (0-1pkt.)
(maj 2020 część 1 zadanie 3.3)

### TREŚĆ
Dana jest konfiguracja interfejsu sieciowego komputerów A i B. <br/><br/>

Dla komputera A:
Adres IPv4: 192.168.10.65
Maska sieci: 255.255.255.0 <br/><br/>

Dla komputera B:
Adres IPv4: 192.168.10.128
Maska sieci: 255.255.255.0 <br/>

<TableQuiz 
    questions={
      [
        {question: "Komputer A i komputer B są w tej samej sieci. ", answer: 'P'},
        {question: "Adresem sieci dla komputera A jest adres 192.168.10.0. ", answer: 'P'},
        {question: "Dla maski 255.255.255.0 są dostępne 254 adresy hostów.", answer: 'P'},
        {question: "Adres rozgłoszeniowy sieci, do której należy komputer B, to 192.168.255.255. ", answer: 'F'},
      ]
    }
/>


## Zadanie 5 (0-1pkt.)
(maj 2017 stara matura, poziom podstawowy, część 1 zadanie 3.1)

### TREŚĆ
Adres IP 196.168.1.5 w systemie dwójkowym ma postać:

<Quiz 
    question={''}
    answers={[
        "11000100.10101000.00000001.00000101",
        "11000100.10101000.00000011.00000101",
        "11000100.10101000.10000000.10100000",
        "11000100.10101000.00000001.10100000"]}
    correct={"11000100.10101000.00000001.00000101"}
/>

## Zadanie 6 (0-1pkt.)
(maj 2017 stara matura, poziom podstawowy, część 1 zadanie 3.6)

<FillGapsQuiz
    task={
        <p>
          Uzupełnij tabelę. Spośród podanych protokołów (POP3, HTTP, SSH, FTP) wybierz
          zapewniające poprawne działanie wymienionych usług i odpowiednio je przyporządkuj. 
        </p>
    }
    questions={[
        "przeglądanie stron www",
        "odbiór poczty elektronicznej",
        "transfer plików",
        "szyfrowane połączenie zdalne"]}
    correct={[
        "HTTP",
        "POP3",
        "FTP",
        "SSH"]}
/>


## Zadanie 7 (0-1pkt.)
(maj 2016 stara matura, poziom podstawowy, część 1 zadanie 3.1)

### TREŚĆ
Protokół DHCP 

<Quiz 
    question={''}
    answers={[
        "odpowiedzialny jest za przydzielanie adresów IP.",
        "jest protokołem przesyłania dokumentów hipertekstowych.",
        "jest protokołem terminalu sieciowego zapewniający szyfrowanie połączenia.",
        "odpowiedzialny jest za tłumaczenie adresów domenowych na adresy IP i odwrotnie."]}
    correct={"odpowiedzialny jest za przydzielanie adresów IP."}
/>


## Zadanie 8 (0-1pkt.)
(maj 2015 stara matura, poziom podstawowy, część 1 zadanie 3.2)

### TREŚĆ
Jaki serwer tłumaczy adres IP na adres domenowy i odwrotnie? 

<Quiz 
    question={''}
    answers={[
        "serwer pocztowy",
        "serwer WWW",
        "serwer FTP",
        "serwer DNS"]}
    correct={"serwer DNS"}
/>


## Zadanie 9 (0-1pkt.)
(maj 2014 stara matura, poziom podstawowy, część 1 zadanie 3c)

### TREŚĆ
Ujednolicony format adresowania zasobów (informacji, danych, usług) stosowany w internecie i w sieciach lokalnych to

<Quiz 
    question={''}
    answers={[
        "FTP",
        "URL",
        "URN"]}
    correct={"URL"}
/>

## Zadanie 10 (0-1pkt.)
(maj 2014 stara matura, poziom podstawowy, część 1 zadanie 3d)

### TREŚĆ
Urządzenie, które pobiera dane cyfrowe z komputera i zamienia je na sygnały analogowe przesyłane w sieci telefonicznej, to 

<Quiz 
    question={''}
    answers={[
        "karta sieciowa",
        "router",
        "modem"]}
    correct={"modem"}
/>

## Zadanie 11 (0-1pkt.)
(maj 2013 stara matura, poziom podstawowy, część 1 zadanie 3f)

### TREŚĆ
Protokół sieciowy to  

<Quiz 
    question={''}
    answers={[
        "zbiór reguł, zgodnie z którymi następuje wymiana informacji między komputerami w sieci.", 
        "polecenie, które wysyła pakiet informacji do dowolnego komputera w sieci z żądaniem potwierdzenia otrzymania informacji.",
        "sterownik, który musi być zainstalowany, aby była możliwa wymiana informacji z innymi komputerami w sieci."]}
    correct={"zbiór reguł, zgodnie z którymi następuje wymiana informacji między komputerami w sieci."}
/>

## Zadanie 12 (0-2pkt.)
(maj 2012 stara matura, poziom podstawowy, część 1 zadanie 3a i 3b)

### TREŚĆ
Adres IP to 32-bitowa liczba zapisywana jako cztery binarne liczby ośmiobitowe oddzielone
odstępami, bądź jako cztery liczby dziesiętne oddzielone kropkami. Na przykład: <br/>
`10000000 00000001 00000010 11111110` <br/>
`128.1.2.254` <br/>
to dwa różne zapisy tego samego adresu. <br/>
Poniżej podajemy dwie niepełne wersje tego samego adresu IP: <br/>
`???????? 10101000 0000001 00000010` <br/>
`192.???.1.2` <br/>
gdzie znaki zapytania oznaczają brakujące cyfry

<Quiz 
    question={'Która z poniższych liczb jest równa brakującej części powyższego adresu IP w postaci binarnej?'}
    answers={[
        "11000000", 
        "10100000",
        "10111110"]}
    correct={"11000000"}
/>

<Quiz 
    question={'Która z poniższych liczb jest równa brakującej części powyższego adresu IP w postaci dziesiętnej? '}
    answers={[
        "178", 
        "168",
        "148"]}
    correct={"168"}
/>

## Zadanie 13 (0-1pkt.)
(maj 2011 stara matura, poziom podstawowy, część 1 zadanie 3c)

### TREŚĆ
Do odbierania wiadomości za pomocą poczty elektronicznej służy protokół

<Quiz 
    question={''}
    answers={[
      "SMTP",
      "POP3",
      "FTP"]}
    correct={"POP3"}
/>

## Zadanie 14 (0-1pkt.)
(maj 2011 stara matura, poziom podstawowy, część 1 zadanie 3d)

### TREŚĆ
W sieciach komputerowych 

<Quiz 
    question={''}
    answers={[
      "komputery pracujące w tej samej sieci mają identyczne adresy IP",
      "LAN to komputer podłączony do sieci, posiadający adres IP",
      "serwer DNS tłumaczy adresy domenowe na adresy IP"]}
    correct={"serwer DNS tłumaczy adresy domenowe na adresy IP"}
/>

## Zadanie 15 (0-1pkt.)
(maj 2009 stara matura, poziom podstawowy, część 1 zadanie 3e)

### TREŚĆ 
Jednoznaczny standard wskazywania położenia i sposobu dostępu do zasobów
w Internecie to

<TableQuiz 
    questions={
      [
        {question: "URL", answer: 'P'},
        {question: "XML", answer: 'F'},
        {question: "OLE", answer: 'F'}
      ]
    }
/>

## Zadanie 16 (0-1pkt.)
(maj 2013 stara matura, poziom rozszerzony, część 1 zadanie 3a)

### TREŚĆ 
Usługa zamieniająca adres domenowy (np. cke.edu.pl) na adres IP to 

<TableQuiz 
    questions={
      [
        {question: "DNS", answer: 'P'},
        {question: "HTTP", answer: 'F'},
        {question: "TCP", answer: 'F'},
        {question: "DHCP", answer: 'F'}
      ]
    }
/>


## Zadanie 17 (0-1pkt.)
(maj 2013 stara matura, poziom rozszerzony, część 1 zadanie 3e)

### TREŚĆ 
Routery w sieciach komputerowych

<TableQuiz 
    questions={
      [
        {question: "realizują połączenia międzysieciowe", answer: 'P'},
        {question: "usytuowane są na styku sieci LAN z internetem lub pomiędzy sieciami LAN. ", answer: 'P'},
        {question: "wymagają stosowania tej samej klasy adresów IP przed i za routerem", answer: 'F'},
        {question: "kierują pakiety danych do odpowiednich podsieci", answer: 'P'}
      ]
    }
/>

## Zadanie 18 (0-1pkt.)
(maj 2012 stara matura, poziom rozszerzony, część 1 zadanie 3b)

### TREŚĆ 
Które z podanych pojęć i skrótów dotyczą technologii i standardów wykorzystywanych
w budowie lokalnych sieci komputerowych? 

<TableQuiz 
    questions={
      [
        {question: "XML", answer: 'F'},
        {question: "WiFi", answer: 'P'},
        {question: "Ethernet", answer: 'P'},
        {question: "Telnet", answer: 'F'}
      ]
    }
/>

## Zadanie 19 (0-1pkt.)
(maj 2011 stara matura, poziom rozszerzony, część 1 zadanie 3h)

### TREŚĆ 
Protokół sieciowy 

<TableQuiz 
    questions={
      [
        {question: "SSL umożliwia bezpieczne przesyłanie danych w sieci", answer: 'P'},
        {question: "IP używany jest tylko w sieci lokalnej", answer: 'F'},
        {question: "POP3 to protokół odbioru poczty elektronicznej", answer: 'P'},
        {question: "HTTP dotyczy przesyłania dokumentów zapisanych w języku HTML", answer: 'P'}
      ]
    }
/>

## Zadanie 20 (0-1pkt.)
(maj 2010 stara matura, poziom rozszerzony, część 1 zadanie 3d)

### TREŚĆ 
Program zapobiegający włamaniom do systemu i kontrolujący pakiety sieciowe to 

<TableQuiz 
    questions={
      [
        {question: "firewall", answer: 'P'},
        {question: "keylogger", answer: 'F'},
        {question: "filtr antyspamowy", answer: 'F'}
      ]
    }
/>

## Zadanie 21 (0-1pkt.)
(maj 2008 stara matura, poziom rozszerzony, część 1 zadanie 3c)

### TREŚĆ 
Urządzenie, które pobiera dane cyfrowe z komputera i zamienia je na sygnały analogowe
przesyłane w sieci telefonicznej to 

<TableQuiz 
    questions={
      [
        {question: "karta sieciowa", answer: 'F'},
        {question: "router", answer: 'F'},
        {question: "modem", answer: 'P'}
      ]
    }
/>

## Zadanie 22 (0-1pkt.)
(maj 2008 stara matura, poziom rozszerzony, część 1 zadanie 3h)

### TREŚĆ 
Adresy IP składają się z czterech liczb z zakresu od 0 do 255, które zapisuje się
oddzielone kropkami, np. 130.11.121.94. Pierwsza z liczb zapisana binarnie na ośmiu
bitach pozwala określić, do jakiej klasy należy adres. Adresy klasy B mają na dwóch
pierwszych bitach (licząc od lewej strony) wartości odpowiednio 1 i 0. Adresy klasy C
mają na pierwszych trzech pozycjach wartości 1, 1 i 0. 

<TableQuiz 
    questions={
      [
        {question: "Adres 128.12.67.90 należy do klasy B", answer: 'P'},
        {question: "Adres 191.12.56.1 należy do klasy C", answer: 'F'},
        {question: "Adres 192.14.56.10 należy do klasy B", answer: 'F'}
      ]
    }
/>

## Zadanie 23 (0-1pkt.)
(maj 2008 stara matura, poziom rozszerzony, część 1 zadanie 3i)

### TREŚĆ 
 Skrótem nazwy protokołu sieciowego jest

<TableQuiz 
    questions={
      [
        {question: "FTP", answer: 'P'},
        {question: "SSH", answer: 'P'},
        {question: "OSI", answer: 'F'}
      ]
    }
/>

## Zadanie 24 (0-1pkt.)
(maj 2007 stara matura, poziom rozszerzony, część 1 zadanie 1.9.)

### TREŚĆ
Z ilu bitów składa się adres IPv4? 

<Quiz 
    question={''}
    answers={[
        "8",
        "16",
        "32",
        "64"]}
    correct={"32"}
/>

## Zadanie 25 (0-1pkt.)
(maj 2006 stara matura, poziom rozszerzony, część 1 zadanie 4a)

### TREŚĆ
Adresy IP składają się z czterech liczb z zakresu od 0 do 255, które zapisuje się
oddzielone kropkami, np. 130.11.121.94. Każda z tych liczb reprezentowana jest
w komputerze na ośmiu bitach. Wśród adresów IP wyróżniamy m.in. adresy klasy B,
w których pierwsza z liczb zapisana binarnie na ośmiu bitach, ma na dwóch pierwszych
pozycjach (licząc od lewej strony) wartości odpowiednio 1 i 0. Który z poniższych
adresów jest adresem IP typu B?

<Quiz 
    question={''}
    answers={[
        "131.125.94.11",
        "141.125.294.111",
        "201.93.93.93 "]}
    correct={"131.125.94.11"}
/>




