import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2016/formula_od_2015/MIN-R2_1P-162.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2016/formula_od_2015/Dane_PR2.zip" >PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ
Podstawieniowy szyfr Cezara z przesunięciem (kluczem) k polega na zastąpieniu każdego
znaku jawnego znakiem leżącym w alfabecie o k pozycji w prawo od zastępowanego znaku.
Przykład: znak ‘B’ po zakodowaniu kluczem k=3 zastąpiony zostanie znakiem ‘E’. <br/>
![](https://i.imgur.com/ePB4F8g.png)<br/>
Przy szyfrowaniu znaku należy postępować w sposób cykliczny, to znaczy, jeżeli znak nie
posiada w alfabecie następnika przesuniętego o k pozycji, to alfabet „zawija się" i za literą Z
następuje znów litera A.<br/>
Przykład: jawny znak ‘X’ po zakodowaniu kluczem k=3 zastąpiony zostanie znakiem ‘A’,
znak ‘Y’ – znakiem ‘B’, natomiast ‘Z’ – znakiem ‘C’. <br/>
![](https://i.imgur.com/Ikakpev.png)<br/>
W tym zadaniu rozpatrujemy tylko słowa zbudowane z wielkich liter alfabetu angielskiego
(o kodach ASCII odpowiednio od 65 do 90), o długościach nie większych niż 30 znaków. 

<br/><br/>

### <u>Zadanie 1</u>   (0-3pkt.)
W pliku <i>dane_6_1.txt</i> znajduje się 100 słów. Słowa umieszczono w osobnych wierszach.<br/>
Fragment pliku <i>dane_6_1.txt</i>:<br/>

```text
INTERPRETOWANIE
ROZWESELANIE
KONSERWOWANIE
```
<br/>
<span className="bold">Napisz program</span>, który <span className="bold">zaszyfruje</span> słowa z pliku <i>dane_6_1.txt</i> z użyciem klucza
k = 107. Wynik zapisz do pliku <i>wyniki_6_1.txt</i>, każde słowo w osobnym wierszu,
w porządku odpowiadającym kolejności słów z pliku z danymi.<br/><br/>
<br/>
<span className="bold">Uwaga:</span> <br/> Dla pierwszego słowa z pliku <i>dane_6_1.txt</i> (INTERPRETOWANIE) wynikiem jest
słowo LQWHUSUHWRZDQLH. 

<br/><br/>

<Dropdown title="ODPOWIEDŹ">

![](https://i.imgur.com/lolHrMC.png)

</Dropdown>
<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
with open("dane_6_1.txt", "r") as f:
    lines = f.readlines()

k = 107
k %= 26 # 26 liter w alfabecie, wszystko co ponad to będzie cyklicznym przejściem

def cipher(letter, k):
    new_ord = ord(letter)+k
    if new_ord > 90:
        new_ord = new_ord - 90 + 65 -1
    return chr(new_ord)

for line in lines:
    line = line.strip()
    letters = [*line]
    ciphered = list(map(lambda x: cipher(x, k), letters))
    print(line, "".join(ciphered))
```
</Dropdown>
