import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2016/formula_od_2015/MIN-R2_1P-162.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2016/formula_od_2015/Dane_PR2.zip" >PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ
Podstawieniowy szyfr Cezara z przesunięciem (kluczem) k polega na zastąpieniu każdego
znaku jawnego znakiem leżącym w alfabecie o k pozycji w prawo od zastępowanego znaku.
Przykład: znak ‘B’ po zakodowaniu kluczem k=3 zastąpiony zostanie znakiem ‘E’. <br/>
![](https://i.imgur.com/ePB4F8g.png)<br/>
Przy szyfrowaniu znaku należy postępować w sposób cykliczny, to znaczy, jeżeli znak nie
posiada w alfabecie następnika przesuniętego o k pozycji, to alfabet „zawija się" i za literą Z
następuje znów litera A.<br/>
Przykład: jawny znak ‘X’ po zakodowaniu kluczem k=3 zastąpiony zostanie znakiem ‘A’,
znak ‘Y’ – znakiem ‘B’, natomiast ‘Z’ – znakiem ‘C’. <br/>
![](https://i.imgur.com/Ikakpev.png)<br/>
W tym zadaniu rozpatrujemy tylko słowa zbudowane z wielkich liter alfabetu angielskiego
(o kodach ASCII odpowiednio od 65 do 90), o długościach nie większych niż 30 znaków. 

<br/><br/>


### <u>Zadanie 2</u>   (0-4pkt.)
W pliku <i>dane_6_2.txt</i> zapisano 3 000 szyfrogramów i odpowiadające im klucze
szyfrujące. W każdym wierszu znajduje się jeden szyfrogram (zaszyfrowane słowo)
i po pojedynczym znaku odstępu odpowiadający mu klucz (maksymalnie czterocyfrowa
liczba).<br/><br/>
Fragment pliku <i>dane_6_2.txt</i>:<br/>

```text
BCYKUNCM 1718
YFOGNSKGYW 7580
WARDA 9334
``` 
<br/>
<span className="bold">Napisz program</span>, który <span className="bold">odszyfruje</span> słowa zaszyfrowane podanymi kluczami. Wynik zapisz
w pliku <i>wyniki_6_2.txt</i>: każde odszyfrowane słowo w osobnym wierszu, w porządku
odpowiadającym kolejności szyfrogramów z pliku z danymi.<br/>
<br/>
<span className="bold">Uwaga</span>:<br/>
Dla pierwszego szyfrogramu z pliku <i>dane_6_2.txt</i> (BCYKUNCM) wynikiem jest słowo
ZAWISLAK. 

<br/><br/>

<Dropdown title="ODPOWIEDŹ">

![](https://i.imgur.com/AbRV55V.png)

</Dropdown>
<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
with open("dane_6_2.txt", "r") as f:
    lines = f.readlines()


def decipher(letter, key):
    new_ord = ord(letter) - key
    if new_ord < 65:
        new_ord = 91 - (65 - new_ord)
    
    return chr(new_ord)

for line in lines:
    line = line.strip()

    # ten try execpt potrzebny jako że dane do tego zadania zawieraja błąd - jedna linia ma słowo bez klucza
    try:
        (word, key) = line.split()
    except ValueError as e:
        print("Błąd danych")
        continue
    key = int(key)%26

    letters = [*word]
    deciphered = list(map(lambda x: decipher(x, key), letters))

    print("".join(deciphered))
```
</Dropdown>

