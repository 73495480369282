import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2013-maj-matura-podstawowa-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2013/Dane_PP.rar"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ (0-10pkt.)
W pliku <i>napisy.txt</i> znajduje się 1000 napisów o długościach od 2 do 16 znaków, każdy
napis w osobnym wierszu. W każdym napisie mogą wystąpić jedynie dwa znaki: „0” lub „1”.
W wybranym przez siebie języku programowania <b>napisz program</b>, za pomocą którego
uzyskasz odpowiedzi na poniższe polecenia. Odpowiedzi zapisz w pliku <i>zadanie4.txt</i>,
a odpowiedź do każdego podpunktu poprzedź literą oznaczającą ten podpunkt.


<br/><br/>

### <u>Podpunkt A</u>   
Podaj, ile jest napisów o parzystej długości.
<br/>
<Dropdown title="ODPOWIEDŹ">

```text
504
```
</Dropdown>
<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
with open("napisy.txt", "r") as f:
    lines = f.readlines()

lines = list(map(lambda x: x.strip(), lines))

even_length = list(filter(lambda x: len(x) % 2 == 0, lines))
print(len(even_length))
```
</Dropdown>

<br/><br/>

### <u>Podpunkt B</u>
Podaj, ile jest napisów, które zawierają taką samą liczbę zer i jedynek.
<br/>
<Dropdown title="ODPOWIEDŹ">

```text
110
```
</Dropdown>
<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
with open("napisy.txt", "r") as f:
    lines = f.readlines()

lines = list(map(lambda x: x.strip(), lines))

equal_01 = list(filter(lambda x: x.count("0") == x.count("1"), lines))
print(len(equal_01))
```
</Dropdown>
<br/><br/>

### <u>Podpunkt C</u>
Podaj, ile jest napisów składających się z samych zer, oraz podaj, ile jest napisów
składających się z samych jedynek.
<br/>
<Dropdown title="ODPOWIEDŹ">

```text
liczba napisów zawierających same zera – 32
liczba napisów zawierających same jedynki – 50 
```
</Dropdown>
<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
with open("napisy.txt", "r") as f:
    lines = f.readlines()

lines = list(map(lambda x: x.strip(), lines))

only_0 = list(filter(lambda x: len(x) == x.count("0"), lines))
print(f"liczba napisów zawierających same zera – {len(only_0)}")

only_1 = list(filter(lambda x: len(x) == x.count("1"), lines))
print(f"liczba napisów zawierających same jedynki – {len(only_1)}")
```
</Dropdown>

<br/><br/>

### Podpunkt D
Dla każdej liczby k = 2, 3, ...,16 podaj liczbę napisów o długości k znajdujących się
w pliku <i>napisy.txt</i>, tzn. podaj, ile jest napisów 2-znakowych, ile jest napisów
3-znakowych itd. 
<br/>
<Dropdown title="ODPOWIEDŹ">

```text
2-znaki: 43
3-znaki: 38
4-znaki: 37
5-znaków: 57
6-znaków: 53
7-znaków: 68
8-znaków: 78
9-znaków: 103
10-znaków: 83
11-znaków: 90
12-znaków: 86
13-znaków: 81
14-znaków: 68
15-znaków: 59
16-znaków: 56 
```
</Dropdown>
<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
with open("napisy.txt", "r") as f:
    lines = f.readlines()

lines = list(map(lambda x: x.strip(), lines))

lengths = list(map(lambda x: len(x), lines))

for i in range(2, 17):
    if i < 5:
        print(f"{i}-znaki: {lengths.count(i)}")
    else:
        print(f"{i}-znaków: {lengths.count(i)}")
```
</Dropdown>



