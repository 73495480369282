import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2019-czerwiec-matura-rozszerzona-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2019-czerwiec-matura-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ
Liczby pierwsze to liczby naturalne większe od 1, które mają dokładnie dwa dzielniki: jedynkę
i samą siebie.<br/>
Dane są dwa pliki: <i>liczby.txt</i> i <i>pierwsze.txt</i>. Plik <i>liczby.txt</i> zawiera 300
wierszy. W każdym wierszu tego pliku znajduje się jedna liczba całkowita dodatnia z zakresu
od 1 do 100 000.<br/>
Plik <i>pierwsze.txt</i> zawiera 200 wierszy. W każdym wierszu tego pliku znajduje się jedna
liczba pierwsza z zakresu od 10 do 1 300 000.<br/><br/>

<b>Uwaga:</b> pomocnicze pliki <i>liczby_przyklad.txt</i> i <i>pierwsze_przyklad.txt</i>,
zawierają dane, które możesz wykorzystać, aby sprawdzić poprawność działania swojego(-ich)
programu(-ów). Każdy z nich zawiera po 50 wierszy. W każdym wierszu znajduje się jedna
liczba. Odpowiedzi dla danych z tych plików są podane pod treściami zadań.<br/><br/>

<b>Napisz program(-y)</b>, w wyniku działania którego(-ych) otrzymasz odpowiedzi do poniższych
zadań. Pliki źródłowe z rozwiązaniem zapisz pod nazwą zgodną z numerem zadania,
z rozszerzeniem odpowiadającym użytemu językowi programowania. 
<br/><br/>

### <u>Zadanie 3</u> (4pkt)

![](https://i.imgur.com/oS7G5jt.png)

Ciąg kończy się, gdy jego wyraz jest liczbą jednocyfrową. Tę liczbę nazywamy wagą liczby
N.

![](https://i.imgur.com/XmDTBWI.png)

Podaj, ile jest liczb w pliku <i>pierwsze.txt</i>, których waga jest równa 1. Odpowiedź zapisz
w pliku <i>wyniki4_3.txt</i>.<br/>
Prawidłowa odpowiedź dla pliku <i>pierwsze_przyklad.txt</i>: 6 liczb. 


<br/><br/>

<Dropdown title="ODPOWIEDŹ">

```text
Liczby z wagą 1:
37
1009
109
31699
31663
6733
4663
7039
66601
80191
140527
151201
440821
440893
440911
100207
100279
171253
181981
75997
80173
160183
160201
1621
2017
15787
20809 
```
</Dropdown>






<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def is_valid(number: str) -> bool:
    if len(number) == 1:
        return int(number) == 1

    return is_valid(str(sum([int(dgit) for dgit in number])))


def main():
    with open(r"pierwsze.txt") as text_file:
        lines = [line for line in text_file.read().strip().split()]

    lines = list(filter(is_valid, lines))

    print(*lines, sep="\n")


if __name__ == "__main__":
    main()

```
</Dropdown>
