import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

## Zadanie 1
Napisz program, który pobierze od użytkownika jego wiek i wypisze czy jest pełnoletni

<Dropdown title="Rozwiązanie">

```python
wiek = int(input("Podaj swój wiek: "))
if wiek > 18:
    print("Jesteś pełnoletni")
else:
    print("Nie jesteś pełnoletni")
```

</Dropdown> 
