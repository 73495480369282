import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2020-lipiec-matura-rozszerzona-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2020-lipiec-matura-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ
Plik <i>identyfikator.txt</i> zawiera 200 wierszy. W każdym wierszu jest zapisany
identyfikator pewnego dokumentu, który składa się z serii (trzy wielkie litery alfabetu
łacińskiego) oraz sześciu cyfr.<br/><br/>
Napisz program(-y), w wyniku działania którego(-ych) otrzymasz odpowiedzi do poniższych
zadań.<br/><br/>
<b>Uwaga:<br/></b> 
Plik <i>identyfikator_przyklad.txt</i> zawiera przykładowe dane (20 wierszy).
Odpowiedzi dla danych z tego pliku są podane pod treściami zadań.

<br/><br/>

### <u>Zadanie 1</u> (3pkt)
Podaj identyfikatory (seria+numer) tych dokumentów z pliku <i>identyfikator.txt</i>,
których suma cyfr z numerycznej części jest największa. Odpowiedź zapisz w pliku
<i>wyniki4_1.txt</i>, po jednym identyfikatorze w wierszu, w kolejności zgodnej z kolejnością
w pliku <i>identyfikator.txt</i>.<br/><br/>
W pliku <i>identyfikator_przyklad.txt</i> jest jeden taki identyfikator. Prawidłowa
odpowiedź dla pliku <i>identyfikator_przyklad.txt</i>:
CHY728985

<br/><br/>
<Dropdown title="ODPOWIEDŹ">

```text
GYJ959787
JOK786969
```
</Dropdown>






<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def get_codes_digit_sum(code: str) -> int:
    return sum([int(x) for x in code[3:]])


def main():
    with open(r"identyfikator.txt") as text_file:
        lines = text_file.read().strip().split("\n")

    max_numbers = max(lines, key=get_codes_digit_sum)

    max_digit_sum = get_codes_digit_sum(max_numbers)

    print(*list(filter(lambda x: get_codes_digit_sum(x) == max_digit_sum, lines)))


if __name__ == "__main__":
    main()

```
</Dropdown>
