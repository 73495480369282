## Rodzaje licencji 

#### Wyłączna
Z licencją wyłączną mamy do czynienia, gdy prawo do wykorzystywania cudzego utworu
będzie przysługiwało tylko konkretnie wskazanej osobie, bez względu na to, czy jest ona
osobą fizyczną, czy też prawną. Bardzo często taki rodzaj licencji jest także ograniczony
terytorialnie lub względem określonego pola eksploatacji (np. w zakresie kopiowania
utworu, ale już nie jego rozpowszechniania)

#### Mocna
Szczególnym rodzajem licencji wyłącznej jest licencja mocna – uniemożliwia ona twórcy
swobodne korzystanie z utworu. Na mocy takiej licencji jedynym uprawnionym do
nieograniczonego wykorzystywania dzieła jest licencjobiorca, nawet wtedy, gdy nie jest on
jego autorem. Twórca nie może wówczas wykorzystywać swojego dzieła na własny użytek, w
przeciwnym wypadku naraża się bowiem na odpowiedzialność odszkodowawczą.

#### Słaba
Licencja słaba również jest odmianą licencji wyłącznej, aczkolwiek w jej przypadku twórca
zachowuje swoje prawa do utworu, tzn. może z niego korzystać chociażby na własny użytek
bez narażania się na obowiązek zapłaty odszkodowania.

#### Niewyłączna
Licencja niewyłączna stanowi przeciwieństwo licencji wyłącznej – jest udostępniania
odbiorcom masowym, takim jak np. użytkownicy komputerowi czy właściciele smartfonów i
tabletów. Cechą charakterystyczną takiej licencji jest wykorzystywanie danego utworu przez
wiele osób w jednym momencie. Licencja niewyłączna ma niejako pierwszeństwo przed
licencją wyłączną – jeśli w zawartej umowie licencyjnej nie wskazano, z jakiego rodzaju
licencją mamy do czynienia, to musimy przyjąć, że jest to licencja niewyłączna. Dodatkowo
licencja niewyłączna może być udzielana na podstawie umowy w dowolnej formie, także
ustnej. Przy licencji wyłącznej konieczne jest zachowanie co najmniej zwykłej formy
pisemnej.

#### Otwarta
Licencja otwarta jest określana mianem licencji darmowej – dany utwór jest na jej podstawie
udostępniany każdemu odbiorcy bez konieczności ponoszenia jakichkolwiek opłat. Dzieło
może być wykorzystywane w nieograniczony sposób przez każdą zainteresowaną osobę.
Licencja wzajemna wiąże się z kolei z przynajmniej dwoma różnymi utworami należącymi do
dwóch różnych twórców. Każda ze stron stosownej umowy występuje w podwójnej roli –
jako licencjobiorca i licencjodawca. Ta forma licencji stanowi pozwolenie uprawnionego z
patentu wcześniejszego na korzystanie z wynalazku późniejszego wchodzącego w zakres
ochrony patentu wcześniejszego (patentu zależnego), któremu towarzyszy podobne
zezwolenie uprawnionego z patentu późniejszego (właściciela patentu zależnego) na rzecz
uprawnionego z patentu wcześniejszego.

#### Dorozumiana
Licencja dorozumiana pojawia się tylko w kontekście prac badawczych lub podobnych, które
są wykonywane na wyraźne zlecenie. Zgodnie z treścią art. 81 Ustawy z dnia 30 czerwca
2000 roku – Prawo własności przemysłowej, jeżeli umowa o wykonanie prac badawczych lub
inna podobna umowa nie stanowi inaczej, domniemywa się, że wykonawca prac udzielił
zamawiającemu licencji na korzystanie z wynalazków zawartych w przekazanych wynikach
prac (licencja dorozumiana).

#### Przymusowa i wzajemna
Licencja przymusowa jest stosowana także na podstawie regulacji Prawa własności
przemysłowej. Urząd Patentowy może udzielić zezwolenia na korzystanie z opatentowanego
wynalazku innej osoby, gdy:

* jest to konieczne do zapobieżenia lub usunięcia stanu zagrożenia bezpieczeństwa
Państwa, w szczególności w dziedzinie obronności, porządku publicznego, ochrony życia i
zdrowia ludzkiego oraz ochrony środowiska naturalnego;
* zostanie stwierdzone, że patent jest nadużywany, lub
* zostanie stwierdzone, że uprawniony z patentu udzielonego z wcześniejszym
pierwszeństwem (patentu wcześniejszego) uniemożliwia, nie godząc się na zawarcie umowy
licencyjnej, zaspokojenie potrzeb rynku krajowego przez stosowanie opatentowanego
wynalazku (patent zależny), z którego korzystanie wkraczałoby w zakres patentu
wcześniejszego; w tym przypadku uprawniony z patentu wcześniejszego może żądać
udzielenia mu zezwolenia na korzystanie z wynalazku będącego przedmiotem patentu
zależnego (licencja wzajemna).

#### Podlicenja
Z kolei z podlicencją mamy do czynienia wtedy, gdy licencjobiorca udziela dalszej licencji
innej osobie (zazwyczaj wymagane jest wówczas uzyskanie zgody na taką czynność od
licencjodawcy). Podlicencje są kolejnymi udzielanymi licencjami tego samego utworu,
określamy je także jako sublicencje.

## Licencje Creative Commons

<img src={new URL('https://i.imgur.com/RvlipuI.jpg', import.meta.url)} alt="xyz" className={`big_img`} />

### Domena publiczna 
Nie jest stricte zdefiniowana w przepisach prawa.<Br/>
Domyślnie rozumiana jest jako przestrzeń do której trafiają wszystkie utwory, dla których upłyną termin obowiązywania praw autorskich.<br/>
<br/>

* Dowolne wykorzystanie – również w celu komercyjnym
* Możliwość edycji i przeróbek dzieła
* Brak potrzeby żadnych zgód i pozwoleń

<img src={new URL('https://i.imgur.com/5gbtGNT.png', import.meta.url)} alt="xyz" className={`super_small_img`} />

## Źródła
Grafik oraz informacje dotyczące licencji CC: <a className={`link`} target="_blank" href="https://creativecommons.pl/poznaj-licencje-creative-commons/">https://creativecommons.pl/poznaj-licencje-creative-commons/</a> <br/>
Definicje i opisy rodzajów licencji: <a className={`link`} target="_blank" href="https://poradnikprzedsiebiorcy.pl/-rodzaje-licencji">https://poradnikprzedsiebiorcy.pl/-rodzaje-licencji</a>



