import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2020-kwiecien-probna-rozszerzona-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2020-kwiecien-probna-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ

Luką w ciągu liczbowym nazywamy <b>bezwzględną wartość różnicy</b> między dwoma kolejnymi
elementami.<br/>
Przykładowo – w czteroelementowym ciągu: 5, 2, 7, 10<br/>
są trzy luki:<br/>
− luka pomiędzy pierwszym a drugim elementem wynosi 3;<br/>
− luka pomiędzy drugim a trzecim elementem wynosi 5;<br/>
− luka pomiędzy trzecim a czwartym elementem wynosi 3.<br/>
Największa luka w tym ciągu ma wartość 5.<br/><br/>
W pliku <i>dane4.txt</i> znajduje się ciąg złożony z 1 000 dodatnich liczb całkowitych nie
większych od 2⋅10<sup>9</sup>.<br/> <b>Napisz program(-y)</b>, który(-e) da(-dzą) odpowiedzi do poniższych zadań.
Odpowiedzi zapisz w pliku <i>zadanie4.txt</i>, a każdą poprzedź numerem odpowiedniego
zadania.

<br/><br/>

### <u>Zadanie 1</u> (3pkt)
Podaj wartość największej luki oraz wartość najmniejszej luki pomiędzy elementami ciągu
z pliku <i>dane4.txt</i>. 


<br/><br/>
<Dropdown title="ODPOWIEDŹ">

```text
Najmniejsza 1
Największa 1056392131
```
</Dropdown>






<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def main():
    with open(r"dane4.txt") as text_file:
        numbers = [int(line) for line in text_file.read().strip().split("\n")]

    gaps = [abs(numbers[index] - numbers[index - 1]) for index in range(len(numbers))]

    print(min(gaps), max(gaps))


if __name__ == "__main__":
    main()

```
</Dropdown>
