import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2022-czerwiec-matura-rozszerzona-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2022-czerwiec-matura-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ
W pliku <i>liczby.txt</i> zapisano 100 nieparzystych liczb całkowitych z przedziału [10, 9999].
Liczby w pliku mogą się powtarzać.<br/>
<b>Odbiciem</b> dodatniej nieparzystej liczby całkowitej n nazywamy taką liczbę N, w której zapisie
dziesiętnym nastąpiło odwrócenie kolejności cyfr.<br/><br/>
<b>Przykład:<br/></b>
Odbiciem liczby 2019 jest 9102, natomiast odbiciem liczby 12345 jest 54321.<br/>
<b>Napisz program</b> (lub kilka programów), który(-e) znajdzie(-dą) odpowiedzi na poniższe
pytania. Każdą odpowiedź zapisz w pliku <i>wyniki4.txt</i> i poprzedź ją numerem
oznaczającym zadanie.<br/><br/>
Do dyspozycji masz również plik <i>przyklad.txt</i> zawierający tylko 11 nieparzystych liczb
całkowitych z przedziału [10, 9999] – odpowiedzi dla tego pliku podane są w treściach
zadań, możesz sprawdzać na nim działanie swojego programu.<br/><br/>
<b>Uwaga:<br/></b> Pamiętaj, że Twój program (lub kilka programów) musi(-szą) ostatecznie działać dla
100 liczb zapisanych w pliku <i>liczby.txt</i>.

<br/><br/>

### <u>Zadanie 2</u> (3pkt)

Dla każdej liczby z pliku <i>liczby.txt</i> oblicz wartość bezwzględną różnicy tej liczby i jej
odbicia.<br/><br/>
Wyznacz taką liczbę n, dla której wartość bezwzględna różnicy tej liczby i jej odbicia jest
największa. Podaj tę liczbę oraz wartość bezwzględną różnicy tej liczby i jej odbicia.
W pliku <i>liczby.txt</i> jest tylko jedna taka liczba.<br/>
Dla pliku <i>przyklad.txt</i> odpowiedzią jest 741 594.


<br/><br/>
<Dropdown title="ODPOWIEDŹ">

```text
1129 8082
```
</Dropdown>






<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def get_value(number: str) -> int:
    return abs(int(number) - int(number[::-1]))


def main():
    with open(r"liczby.txt") as text_file:
        numbers = text_file.read().strip().split()

    maximal_number = max(numbers, key=get_value)

    print(maximal_number, get_value(maximal_number))


if __name__ == "__main__":
    main()

```
</Dropdown>
