import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2020-lipiec-matura-rozszerzona-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2020-lipiec-matura-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ
Plik <i>identyfikator.txt</i> zawiera 200 wierszy. W każdym wierszu jest zapisany
identyfikator pewnego dokumentu, który składa się z serii (trzy wielkie litery alfabetu
łacińskiego) oraz sześciu cyfr.<br/><br/>
Napisz program(-y), w wyniku działania którego(-ych) otrzymasz odpowiedzi do poniższych
zadań.<br/><br/>
<b>Uwaga:<br/></b> 
Plik <i>identyfikator_przyklad.txt</i> zawiera przykładowe dane (20 wierszy).
Odpowiedzi dla danych z tego pliku są podane pod treściami zadań.

<br/><br/>

### <u>Zadanie 3</u> (4pkt)
Poprawność identyfikatora dokumentu potwierdza pierwsza cyfra z jego numerycznej części,
która jest cyfrą kontrolną. <br/>
Podczas sprawdzania poprawności identyfikatora dokumentu litery jego serii są zamieniane na
liczby według następującego przypisania:<br/>

![](https://i.imgur.com/D3rzRq4.png)
<br/>

Aby sprawdzić poprawność identyfikatora danego dokumentu, należy wartość każdego
elementu identyfikatora (poza cyfrą kontrolną) pomnożyć przez odpowiednią wagę. Wagi
poszczególnych składowych identyfikatora to kolejno: 7, 3, 1, 7, 3, 1, 7, 3. Otrzymane iloczyny
należy zsumować i policzyć resztę z dzielenia tej sumy przez 10 (mod 10). Jeśli uzyskana w ten
sposób liczba jest równa wartości pierwszej cyfry z identyfikatora dokumentu, to identyfikator
jest poprawny. 
<br/><br/>
<b>Przykład:<br/></b>
Dla dokumentu o identyfikatorze: CIS459437 <br/>

![](https://i.imgur.com/NkC4H1V.png)

<br/>

Suma to 84+54+28+35+27+4+21+21=274<br/>
274 mod 10 = 4<br/><br/>
Pierwsza cyfra z numerycznej części identyfikatora jest równa 4, zatem identyfikator
dokumentu jest poprawny.<br/><br/>
W pliku <i>identyfikator.txt</i> znajdują się identyfikatory, z których część jest poprawna,
a część – niepoprawna. Podaj wszystkie identyfikatory dokumentów z tego pliku, które są
niepoprawne. Odpowiedź zapisz w pliku <i>wyniki4_3.txt</i>, po jednym identyfikatorze
w wierszu, w kolejności zgodnej z kolejnością w pliku <i>identyfikator.txt</i>.<br/>
Prawidłowa odpowiedź dla pliku <i>identyfikator_przyklad.txt</i>:

```text
NHO307984
SEH422297
MOS302203
```

<br/><br/>
<Dropdown title="ODPOWIEDŹ">

```text
LIK235378
MBF340043
HFG378931
KIJ511146
NUK840936
OKH427482
RTS278686
```
</Dropdown>





<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
from string import ascii_uppercase


def is_valid_code(code: str) -> bool:

    multipliers = [7, 3, 1, 7, 3, 1, 7, 3]

    values = [ascii_uppercase.index(character) + 10 for character in code[:3]]

    values += [int(code[index]) for index in range(4, 9)]

    great_sum = 0

    for multiplier, number in zip(multipliers, values):
        great_sum += number * multiplier

    return (great_sum % 10) == int(code[3])


def main():
    with open(r"identyfikator.txt") as text_file:
        identifiers = text_file.read().strip().split()

    invalid = list(filter(lambda x: not is_valid_code(x), identifiers))

    print(*invalid, sep="\n")


if __name__ == "__main__":
    main()

```
</Dropdown>
