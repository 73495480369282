import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2022-grudzien-probna-rozszerzona.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2022-grudzien-probna-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ
Dawno temu, w odległej galaktyce, rozegrano mecz w grę, która przypominała siatkówkę.
W meczu wystąpiły dwie drużyny: drużyna A i drużyna B. Mecz składał się z 10 000 krótkich
rozgrywek. Każda rozgrywka kończyła się wygraną jednej z dwóch drużyn, za którą
zwycięska drużyna otrzymywała jeden punkt.
Plik <i>mecz.txt</i> zawiera zapis wyników kolejnych rozgrywek – jeden wiersz z napisem
złożonym z 10 000 znaków A i B. Znak A oznacza, że rozgrywkę wygrała drużyna A,
natomiast znak B – że rozgrywkę wygrała drużyna B.
Napisz <b>program(-y)</b> który(-e) znajdzie(-dą) odpowiedzi do poniższych zadań. Odpowiedzi
zapisz w pliku <i>wyniki1.txt</i>, a każdą z nich poprzedź numerem odpowiedniego zadania.
Do dyspozycji masz plik <i>mecz_przyklad.txt</i>, spełniający warunki zadania – odpowiedzi
dla tego pliku podano w treściach zadań. Możesz sprawdzać na nim działanie swojego
programu.

<br/><br/>

### <u>Zadanie 3</u> (3pkt)
Powiemy, że drużyna ma dobrą passę, jeśli wygrywa rozgrywki co najmniej 10 razy z rzędu.
Każda <i>dobra passa</i> rozpoczyna się albo na początku meczu, albo bezpośrednio po
przegranej rozgrywce. Każda dobra passa kończy się albo z końcem meczu, albo
bezpośrednio przed przegraną rozgrywką.<br/>
Podaj łączną liczbę dobrych pass, które miały obie drużyny w meczu. Wyznacz długość
najdłuższej dobrej passy i drużynę, która ją osiągnęła. Tylko jedna drużyna miała dobrą
passę o tej długości.<br/>
<b>Przykład:<br/></b> w meczu BBBBBBBBBBAABBAAAAAAAAAAABA mamy łącznie 2 dobre passy.<br/>
Najdłuższą dobrą passę, o długości 11, osiągnęła drużyna A

<br/>
<br/>
<Dropdown title="ODPOWIEDŹ">

```text
6 B 15
(Liczba dobrych pass: 6 Najdłuższa dobra passa: B, 15 rozgrywek)
```
</Dropdown>





<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
import re


def get_game() -> str:
    with open(r"mecz.txt") as text_file:
        return text_file.read().strip("\n")


def main():
    game = get_game()
    streaks_a = re.findall("A{10,}", game)
    streaks_b = re.findall("B{10,}", game)

    print("dobrych pass w sumie:", len(streaks_a) + len(streaks_b))

    best = max(streaks_b + streaks_a, key=len)

    print("najlepszą passe miała drużyn {}, o długości {}".format(best[0], len(best)))


if __name__ == '__main__':
    main()

```
</Dropdown>
