import Dropdown from "../../components/dropdown/Dropdown.jsx";
import TableQuiz from "../../components/tableQuiz/TableQuiz.jsx";
import Quiz from "../../components/Quiz.jsx";

## Zadanie 1 (0-1pkt.)
(maj 2017 część 1 zadanie 3.3)

### TREŚĆ
Pewien oszust chce rozesłać wiadomość, podszywając się pod Jana Kowalskiego, ale nie zdołał
wykraść żadnych należących do Jana haseł ani innych prywatnych informacji. Posiada jednak
klucz publiczny Jana Kowalskiego, który ten udostępnił w sieci, a także znaleziony w internecie
adres e-mail Jana. Może zatem  

<TableQuiz 
    questions={
      [
        {question: "założyć konto „Jan Kowalski” w serwisie społecznościowym i stamtąd rozsyłać wiadomości.", answer: 'P'},
        {question: "na podstawie klucza publicznego Jana Kowalskiego szybko wygenerować jego podpis cyfrowy.", answer: 'F'},
        {question: "na podstawie klucza publicznego Jana Kowalskiego szybko obliczyć jego klucz prywatny", answer: 'F'},
        {question: "rozsyłać listy elektroniczne, które w nagłówku „Od:” będą miały adres e-mail Jana Kowalskiego. ", answer: 'P'},
      ]
    }
/>

## Zadanie 2 (0-1pkt.)
(maj 2008 stara matura, poziom rozszerzony, część 1 zadanie 3g)

### TREŚĆ
Do szyfrowania informacji służy

<TableQuiz 
    questions={
      [
        {question: "algorytm RSA", answer: 'P'},
        {question: "algorytm Euklidesa", answer: 'F'},
        {question: "algorytm Hornera", answer: 'F'}
      ]
    }
/>

## Zadanie 3 (0-1pkt.)
(maj 2008 stara matura, poziom rozszerzony, część 1 zadanie 3l)

### TREŚĆ
Asymetryczne metody szyfrowania wymagają

<TableQuiz 
    questions={
      [
        {question: "używania takich samych kluczy do szyfrowania i deszyfrowania wiadomości", answer: 'F'},
        {question: "używania różnych kluczy do szyfrowania i deszyfrowania wiadomości", answer: 'P'},
        {question: "ujawniania klucza służącego do szyfrowania", answer: 'P'}
      ]
    }
/>

## Zadanie 4 (0-1pkt.)
(maj 2006 stara matura, poziom rozszerzony, część 1 zadanie 4g)

### TREŚĆ
Które z poniższych czynności są przykładami kodowania informacji?

<Quiz 
    question={''}
    answers={[
        "Zastąpienie znaków tworzących tekst innymi znakami w sposób pozwalający odtworzyć tekst oryginalny",
        "Usunięcie losowo wybranych liter z tekstu wiadomości",
        "Ukrywanie przekazywanych wiadomości poprzez dobór odpowiednich uprawnień i atrybutów"]}
    correct={"Zastąpienie znaków tworzących tekst innymi znakami w sposób pozwalający odtworzyć tekst oryginalny"}
/>