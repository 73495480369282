import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2010/Informatyka/pr_ii.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2010/Informatyka/Dane_PR.rar"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ (0-10pkt.)
Anagram to słowo powstałe z innego słowa przez przestawienie liter. Przez słowo rozumiemy
w tym zadaniu dowolny ciąg liter alfabetu łacińskiego.<br/><br/>
<b>Przykłady anagramów:<br/></b>
dla słowa: barok – korba, robak, arobk, rokab, orkab …<br/>
dla słowa: ranty – tyran, narty, ntyra, natyr, ytnar …<br/>
W pliku tekstowym <i>anagram.txt</i> znajduje się 200 wierszy zawierających po 5 słów
w każdym wierszu. Słowa oddzielone są znakiem odstępu. Długość każdego ze słów wynosi
od 1 do 20 znaków.<br/><br/>
<b>Przykład:<br/></b> 


```text
abcd cdba dbac cbad dcba
barbakan xle ala foto otof
smok ayszkm lampa ayszkm bakara
skok arabanta oko agnieba dyskietka
```

<br/>

Napisz program w wybranym przez siebie języku programowania, za pomocą którego
wykonasz poniższe polecenia: 

<br/><br/>

### <u>Podpunkt A</u>
Wyszukaj w pliku <i>anagram.txt</i> te wiersze, w których wszystkie słowa znajdujące się
w danym wierszu mają taką samą liczbę znaków. Zapisz te wiersze w pliku
<i>odp_4a.txt</i>.
<br/><br/>
<Dropdown title="ODPOWIEDŹ">

```text
abcd cdba dbac cbad dcba
wrona rossa slowo gwert rezas
grant hello zakon lloeh hello
kabaret kabanos kabaret gertyfu kabaret
ola ala aga oal ola
rezas rossa zaser sarez rezas
foto foto tofo tofo foto
romans romans normag masrom ansrom
ekran ranek lampa zakon ekran
korba orkan delpu pudel udelp
czek azer reza zare rzea
cebula romans romans mansro romans
kruk kruk buka zuka nuka
agent rossa serce cerse sdfrt
qwerty wertyq wertyu magnor normag
glob lobg bogl glbo gblo
triada dariat aadrit iatdar adatri
kotek tekok teokk kokte otekk
obrus bruso soubo seawo rusob
rower werro werro owerr erwor
ipfon ipfon fonip ipfon zakop
nerka drewn korba korba korba
patyk wrona wrona wrona wrona
foto tofo foot ftoo ootf
spiker kerspi erspik erspki kiersp
burza orkan lukde pudeh lerfy 
```

</Dropdown>

<br/><br/>

### <u>Podpunkt B</u>
Wyszukaj w pliku <i>anagram.txt</i> wszystkie wiersze tekstu, w których wszystkie słowa
są anagramami pierwszego słowa w danym wierszu. Zapisz te wiersze w pliku
<i>odp_4b.txt</i>.
<br/>
<br/>
<Dropdown title="ODPOWIEDŹ">

```text
abcd cdba dbac cbad dcba
foto foto tofo tofo foto
glob lobg bogl glbo gblo
triada dariat aadrit iatdar adatri
kotek tekok teokk kokte otekk
rower werro werro owerr erwor
foto tofo foot ftoo ootf
spiker kerspi erspik erspki kiersp 
```

</Dropdown>

<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```
# Podpunkt A

def czy_wszystkie_elementy_o_rownej_dlg(lista_slow):
    dlg = len(lista_slow[0])
    for slowo in lista_slow:
        if len(slowo) != dlg:
            return False
    return True

def linie_o_wszystkich_elementach_rownej_dlg(lista_linii):
    return list(filter(czy_wszystkie_elementy_o_rownej_dlg, lista_linii))

# Podpunkt B

def czy_anagram(s, w):
    return sorted(s) == sorted(w)

def czy_wszystkie_slowa_sa_anagramami_pierwszego(lista_slow):
    s = lista_slow[0]
    for slowo in lista_slow:
        if not czy_anagram(s, slowo):
            return False
    return True

def linie_ze_wszystkie_slowa_sa_swoimi_anagramami(lista_linii):
    return list(filter(czy_wszystkie_slowa_sa_anagramami_pierwszego, lista_linii))

# Main

with open('anagram.txt', 'r') as plik:
    dane = [ a.strip().split(" ") for a in plik.readlines()]

odp_A = linie_o_wszystkich_elementach_rownej_dlg(dane)
print("A)")
for s in odp_A: print(s)
print("\n")
#
print("B)")
odp_B = linie_ze_wszystkie_slowa_sa_swoimi_anagramami(dane)
for s in odp_B: print(s)
```

</Dropdown>



