.input {
    display: none;
}

.question{
    padding: 0 0.3rem;
    vertical-align: middle;
}

.answer{
    vertical-align: middle;
    min-width: 3rem;
    height: 100%;
    position: relative;
}

.label {
    display: block;
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
  
.label div {
    padding-bottom: 5%;
    position: relative;
    text-align: center;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.label div:hover{
    background-color: var(--secondary3);
    opacity: 0.3;
} 


.filled .label div:hover{
    background-color: transparent;
    opacity: 1;
} 


.label div:before,
.label div:after {
    content: '';
}
  
.label div:before {
    display: inline-block;
    vertical-align: top;
}
  
.label div:after {
    background: var(--secondary3);
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 300ms;
}

.label input:checked+div:after {
opacity: 0.3;
height: 100%;
}

.filled .correct:after {
background-color: green;
}

.filled .incorrect:after {
background-color: red;
}

.label:hover .correct {
background-color: none;
}

.label:hover .incorrect {
background-color: none;
}
