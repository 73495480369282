<br/>

**Słowo wstępu:** na maturze wszystkie pliki, jakie będzie trzeba zaimportować, będą w formacie tekstowym (rozszerzenie .txt). W tym punkcie omówimy import danych od strony technicznej.

<br/> 

Importowanie danych pokażemy na podstawie Zadania 5. "Zbiornik" z matury z maja 2018 roku.


<a className={`link`} target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2018/formula_od_2015/informatyka/MIN-R2_1P-182.pdf">LINK DO ARKUSZA</a>
<br/>
<a className={`link`} href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2018/formula_od_2015/informatyka/Dane_PR2.zip">LINK DO PLIKU Z DANYMI</a>
<br/><br/>

<img src={new URL('https://i.imgur.com/0KVXMrr.png', import.meta.url)} alt="xyz" className={`medium_img`} />

## 1. Wybieramy zakładkę "Dane" -> "Dane z pliku tekstowego/ CSV"
(CSV - ang. comma-separated values)
![](https://i.imgur.com/xnziDy2.png)

## 2. Wybieramy plik, który chcemy zaimportować.
(W naszym przykładzie będzie to zadanie "woda.txt".)
<img src={new URL('https://i.imgur.com/sgSe6cb.png', import.meta.url)} alt="xyz" className={`medium_img`} />


## 3. Wybieramy ogranicznik i klikamy "Załaduj".
Excel często sam "domyśla się" jaki ogranicznik powinien wybrać, jednak zawsze warto upewnić się, że dokonał on waściwego wyboru. 
W naszym przykładzie z treści zadania wiemy, że ograniczniekiem dancyh jest tabulator.
<img src={new URL('https://i.imgur.com/5fJWfTi.png', import.meta.url)} alt="xyz" className={`medium_img`} />

## 4. Dodajemy nagłówki kolumn
Wystarczy dwukrotnie kliknąć, usunąć automatyczna nazwę (np. "Column1") i spisać własną (np. "data").
![](https://i.imgur.com/Q0sE7QG.png)


## 5. Ustawiamy odpowiednie typy danych
Zaznaczamy całą kolumnę, klikamy prawym klawiszem myszy i wybieramy "Formatuj komórki", a następnie ustawiamy odpowiedni typ danych.
![](https://i.imgur.com/55o5Ujq.png)

(W naszym przykładzie kolumna "Data" będzie miała format "Liczby" -> "data" -> format typu "rrr-mm-dd", natomiast kolumnę "Dopływy [m3]" możemy zostawić bez zmian lub ustawić format liczbowy np. z dwoma miejscami po przecinku.)
![](https://i.imgur.com/r29ADTK.png)

## 6. Zapisujemy nasz arkusz!
Pamiętajmy, aby regularnie zapisywać arkusz z danymi. Ponadto upewnij się, że rozszerzenie pliku jest odpowiednie (realizację zadania chcemy zapisać jako skoroszyt programu Excel nie plik .txt)



