.main {
    height: 100%;
}

.editor {
    display: flex;
    flex-direction: column;
    margin: 0 3rem;
}

.editor_monaco {
    flex: 1;
}

.editor_wrapper {
    box-shadow: var(--card-shadow) 0px 2px 8px 0px;
    border-radius: 10px;
    background-color: #1e1e1e;
    padding: 1rem;
    margin-bottom: 1rem;
}

/* .editor_wrapper .react-monaco-editor-container .margin{
    width: 50px;
} */

.title_wrapper {
    display: flex;
    justify-content: space-between;
    background-color: #1e1e1e;
    padding: 0.5rem;
}

.editor_button {
    border: 0;
    font-family: 'Chakra Petch', sans-serif;
    font-size: 1.17em;
    color: #d4d4d4;
    background: rgb(0, 111, 0);
    padding: 0 2rem;
    margin-right: 0.5rem;
    cursor: pointer;
    transition: .7s;
}

.editor_button:hover{
    background: rgb(0, 135, 0);
    color: #ffffff;
    border: solid .5px white;
}

.editor_button:disabled {
    background: gray;
}

.editor_output {
    display: block;
    flex-direction: row;
    overflow-y: auto;
    background-color: #1e1e1e;
    color: #d4d4d4;
    min-height: 20vh;
}

.editor_stdout,
.editor_stderr {
    flex: 1;
    min-width: 0;
    flex-direction: row;
}

.editor_stdout p,
.editor_stderr p {
    text-align: center;
}

.editor_stdout pre,
.editor_stderr pre {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
}

.title {
    font-family: 'Chakra Petch', sans-serif;
    font-size: 1.17em;
    text-decoration: underline;
    color: #d4d4d4;
    padding-left: 1rem;
}

.editor_stdout {
    background: transparent;
}

.editor_stdout p {
    background: transparent;
}

.editor_stderr {
    color: red;
}

@media (max-width: 700px) {
    .main {
        margin: 10px 0;
    }

    .editor {
        margin: 0 1rem;
    }

    .editor_wrapper {
        padding: .2rem;
        margin-bottom: .4rem;
    }

    .title_wrapper {
        padding: 0.1rem;
    }

    .title {
        font-size: 1em;
    }

    .editor_button {
        font-size: 1em;
        padding: 0 1rem;
        border-radius: 15px;
        margin-right: 0.5rem;
    }

}

@media (max-width: 350px) {
    .main {
        margin: 5px 0;
    }
    
    .editor {
        margin: 0 .5rem;
    }
    
    .title {
        font-size: 0.9em;
    }
}