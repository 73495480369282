import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2020-lipiec-matura-rozszerzona-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2020-lipiec-matura-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ
Plik <i>identyfikator.txt</i> zawiera 200 wierszy. W każdym wierszu jest zapisany
identyfikator pewnego dokumentu, który składa się z serii (trzy wielkie litery alfabetu
łacińskiego) oraz sześciu cyfr.<br/><br/>
Napisz program(-y), w wyniku działania którego(-ych) otrzymasz odpowiedzi do poniższych
zadań.<br/><br/>
<b>Uwaga:<br/></b> 
Plik <i>identyfikator_przyklad.txt</i> zawiera przykładowe dane (20 wierszy).
Odpowiedzi dla danych z tego pliku są podane pod treściami zadań.

<br/><br/>

### <u>Zadanie 2</u> (4pkt)

Podaj wszystkie te identyfikatory dokumentów z pliku <i>identyfikator.txt</i>, których seria
lub numer są palindromami, czyli czytane od lewej do prawej i od prawej do lewej są takie
same. Odpowiedź zapisz w pliku <i>wyniki4_2.txt</i>, po jednym identyfikatorze w wierszu,
w kolejności zgodnej z kolejnością w pliku <i>identyfikator.txt</i>.<br/><br/>
Prawidłowa odpowiedź dla pliku <i>identyfikator_przyklad.txt</i>:<br/>

```text
KOK201272
PIP417543
MOS302203 
```

<br/><br/>

<Dropdown title="ODPOWIEDŹ">

```text
MBF340043
JKJ452719
GFG148345
AXA231829
KIK866983
LOL695279
```
</Dropdown>






<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def _is_palindrome(string: str) -> bool:
    return string[::-1] == string


def main():
    with open("identyfikator.txt") as text_file:
        codes = text_file.read().strip().split("\n")

    for code in codes:
        serie = code[:3]

        number = code[3:]

        if not (_is_palindrome(number) or _is_palindrome(serie)):
            continue

        print(code)


if __name__ == "__main__":
    main()

```
</Dropdown>
