## Definicja SQL
Zacznijmy od formalnego zdefiniowania, czym jest język SQL? Czy jest to język programowania?
**Język SQL (structured query language)** to język zapytań. Umożliwia on dokonywanie operacji na bazach danych - pozwala na pobieranie i zapisywanie informacji oraz zarządzanie nimi.

## Podział SQL (dodatkowe)
* DML (data manipulation language)
    * DELETE (usuń)
    * INSERT (wstaw)
    * UPDATE (aktualizuj/zmień)
* DDL (data definition language)
    * CREATE
    * DROP
    * ALTER

## Dlaczego warto nauczyć się SQL-a?
1. Na części teoretycznej matury z informatyki mogą pojawić się pytania wymagające jego znajomości i są to bardzo łatwe do zdobycia punkty (zob. "Zadania z baz danych").

2. Zrozumienie SQL pomoże Ci w udoskonaleniu twojej pracy z MS Access, a także umożliwi poprawienie zapytań, które nie działają zgodnie z twoimi oczekiwaniami.

3. Relacyjne bazy danych są niezwykle popularne i wykorzystywane w wielu miejscach. Wszystkie bazują na języku SQL (MS Access, MySQL, PostgreSQL, T-SQL).

4. Jeśli idziesz na studia informatyczne, to z pewnością czeka Cię przedmiot obejmujący tematykę baz danych i najzwyczajniej będzie Ci łatwiej, jeśli będziesz zaznajomiony, choć z podstawami tego języka.

## SQL a Access
Wszystkie **kwerendy**, **relacje** czy nowe **tabele** jakie tworzymy w Access, są tłumaczone na język SQL i wykonywane. W łatwy sposób możemy sprawdzić, jak nasze zapytanie będzie tłumaczone przez Access.


#### Widok projektu kwerendy w Access

![](https://i.imgur.com/76eSnOY.png)


#### Widok SQL

![](https://i.imgur.com/eZSxoEp.png)

## Składnia SQL 
Zaletą języka SQL jest łatwość jego zrozumienia. Zadania w SQL możemy czytać niemalże jak zwykłe pytania w naturalnym języku angielskim. W tej sekcji zdefiniujemy i pokażemy przykłady wykorzystania podstawowych funkcji i selektorów w SQL-u.

### Zadawanie zapytań (potocznie: kwerendy)   

#### Szablon składni
<br/>
(Kolorem fioletowym wyróżniamy funkcje SQL-a, natomiast te zapisane w nawiasach [] to funkcje opcjonalne, możemy, ale nie musimy ich dodawać.)
<br/><br/>


<span className="color_marked_text">SELECT</span> tutaj_nazwa_kolumny <span className="color_marked_text">[F.AGREGUJĄCE] <br/>
FROM </span> tutaj_nazwa_tabeli <br/>
<span className="color_marked_text"> WHERE </span> tutaj_warunki <br/>
<span className="color_marked_text"> [DODATKOWE KLAUZULE] </span> <br/>

