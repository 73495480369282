
## Słowo wstępu
Teoria informatyki na maturze, czyli budowa komputera, modele barw, sieci komputerowe itp. to bardzo szeroki zakres wiedzy do opanowania.
Warto zauważyć, że dostaje się za nią stosunkowo mało punktów. Jeśli masz więc dużo czasu na naukę to warto przeczytać odpowiednie tematy a 
przede wszystkim sprawdzić swoją wiedzę poprzez przerobienie quizów. 

### Jeśli jednak czas Cię goni, lepiej skup się na pozostałych działach Excelu, Accessie i Pythonie.

### Jeśli zdecydujesz się na naukę teorii to najlepiej zacznij od przerobienia działu "Konwersja liczb", ponieważ wiele innych działów wymaga zrozumienia tego działu. 

### Jeśli trudno Ci zrozumieć jakiś dział, to nie poświęcaj na niego za dużo czasu. Lepiej iść dalej i nie marnowac cennego czasu na trudny temat teoretyczny, który może w ogóle nie pojawić się na egzaminie.

