import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2022-grudzien-probna-rozszerzona.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2022-grudzien-probna-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ
Dawno temu, w odległej galaktyce, rozegrano mecz w grę, która przypominała siatkówkę.
W meczu wystąpiły dwie drużyny: drużyna A i drużyna B. Mecz składał się z 10 000 krótkich
rozgrywek. Każda rozgrywka kończyła się wygraną jednej z dwóch drużyn, za którą
zwycięska drużyna otrzymywała jeden punkt.
Plik <i>mecz.txt</i> zawiera zapis wyników kolejnych rozgrywek – jeden wiersz z napisem
złożonym z 10 000 znaków A i B. Znak A oznacza, że rozgrywkę wygrała drużyna A,
natomiast znak B – że rozgrywkę wygrała drużyna B.
Napisz <b>program(-y)</b> który(-e) znajdzie(-dą) odpowiedzi do poniższych zadań. Odpowiedzi
zapisz w pliku <i>wyniki1.txt</i>, a każdą z nich poprzedź numerem odpowiedniego zadania.
Do dyspozycji masz plik <i>mecz_przyklad.txt</i>, spełniający warunki zadania – odpowiedzi
dla tego pliku podano w treściach zadań. Możesz sprawdzać na nim działanie swojego
programu.

<br/><br/>

### <u>Zadanie 1</u> (2pkt)

Oblicz, ile razy nastąpiła sytuacja, w której rozgrywkę wygrała inna drużyna niż rozgrywkę
poprzednią (tzn. dwa kolejne znaki A lub B w opisie meczu się różnią).<br/>
<b>Przykład:<br/></b> Dla napisu ABBBABA odpowiedzią jest 4.<br/>
Natomiast dla pliku <i>mecz_przyklad.txt</i> odpowiedzią jest 1798

<br/>
<br/>
<Dropdown title="ODPOWIEDŹ">

```text
5030
```
</Dropdown>




<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def get_game() -> str:
    with open(r"mecz.txt") as text_file:
        return text_file.read().strip("\n")


def main():
    game = get_game()

    counter = 0

    for index in range(1, len(game)):
        if game[index] != game[index - 1]:
            counter += 1

    print(counter)


if __name__ == '__main__':
    main()

```
</Dropdown>
