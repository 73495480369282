.title {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: var(--primary2);
  text-align: center;
  font-size: 35px;
  letter-spacing: 10px;
  text-indent: 30px;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 900;
  color: var(--title);
  margin-bottom: 20px;
  text-transform: uppercase;
  height: 80px;
}

.cont_title {
  padding: 10px 0;
  text-align: center;
  /* letter-spacing: 1px; TU MOGŁAM COS ZEPSUC */
  line-height: 25px;
  text-indent: 5px;
  font-family: "Chakra Petch", sans-serif;
  font-weight: 900;
  font-size: 25px;
  color: var(--font-accent);
}

.main {
  min-height: 80vh;
  text-align: center;
  display: grid;
  grid-template-columns: 18em 1fr;
  gap: 2.5vw;
  width: 70%;
  max-width: 85em;
  margin: 0 auto;
}

/* SIDEBAR */

.main .contents {
  margin-top: 20px;
}

.main .contents .cont {
  padding: 0;
  font-size: 15px;
  font-family: "Chakra Petch", sans-serif;
  font-weight: lighter;
  text-transform: uppercase;

}

.main .contents .cont .topic {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondary3);
}

.main .contents .cont .topic.standalone {
  justify-content: left;
  min-height: 100%;
}

.topic a {
  text-transform: uppercase;
  text-decoration: none;
}

.main .contents .cont .subtopic {
  display: flex;
  margin: 0;
  text-align: left;
  align-items: center;
  background-color: var(--primary);
  color: var(--secondary3);
  border-bottom: 1px var(--secondary3) solid;
  padding-left: 30px;
}

.main .contents .cont .subtopic_current > div{
  color: var(--secondary4);
  background-color: var(--primary2);
  border-left: 5px var(--secondary3) solid;
  padding-left: 25px;
}

.main .contents .cont .subtopic:hover {
  color: var(--secondary4);
}

.main .contents .cont .subtopic_current > div:hover {
  color: var(--secondary4);
  background-color: var(--primary2);
}

.main .contents .cont .topic:hover {
  background-color: var(--secondary2);
}

.icon_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress_icon {
  transform: scale(0.6);
}

/* NAGŁÓWEK - CZEŚĆ */

.main article {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 300;
  padding: 20px 0;
  margin-bottom: 1vw;
  text-align: justify;
  border-radius: 1px;
  text-justify: inter-word;
}

.main article h1 {
  font-family: "Chakra Petch", sans-serif;
  text-transform: uppercase;
  color: var(--font-accent);
  font-size: 25px;
  border-bottom: 3px var(--font) solid;
}

.main article h2,
.main article h3,
.main article h4,
.main article h5,
.main article h6 {
  font-family: "Chakra Petch", sans-serif;
  margin-top: 20px;
}

.main article img {
  display: block;
  margin: 0 auto;
  padding: 10px 0;
  max-width: 90%;
  width: 100%;
}

.main article pre {
  margin: 10px 0;
  /* white-space: pre-line; */
}

.main_content .controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 30px;
  margin-bottom: 1vw;
}

.main_content article {
  border-bottom: solid 3px var(--font);
}

.controls button {
  width: 30px;
  height: 30px;
  border-radius: 90%;
  margin-left: 10px;
  border: solid 1px transparent;
  background-color: transparent;
  transition: transform;
  transition-duration: 0.3s;
  cursor: pointer;
  color: var(--font);
}

.controls button:hover {
  transform: scale(1.3);
}

.controls .checkbox_label {
  font-family: "Chakra Petch", sans-serif;
}

.ft {
  display: none;
}

img.section_logo {
  max-width: 3rem;
  max-height: 3rem;
  padding-left: 2%;
  filter: var(--screen_filter);
}

/* ??? */
.left_menu{
  margin-top: 3px;
}

@media (max-width: 1400px) {
  .main {
    grid-template-columns: 22vw 1fr;
    width: 80%;
  }
}

@media (max-width: 1200px) {
  .main {
    grid-template-columns: 23vw 1fr;
    width: 90%;
    max-width: 95em;
  }

  .cont_title {
    font-size: 23px;
  }

  .main .contents {
    margin-top: 0px;
  }

  .main .contents .cont div {
    font-size: 13px;
    letter-spacing: 0;
  }

  .main article {
    font-size: 15px;
    padding: 15px 0px;
    margin-bottom: 1vw;
  }
}

@media (max-width: 1000px) {
  .main {
    grid-template-columns: 26vw 1fr;
  }

  .cont_title {
    letter-spacing: 5px;
  }

  .main .contents .cont div {
    font-size: 12px;
  }
}

@media (max-width: 810px) {
  .main {
    grid-template-columns: 1fr;
    gap: 0;
    width: 92%;
  }

  .main article {
    padding: 15px 10px;
    font-size: 14px;
  }

  .main .contents {
    margin-top: 0px;
  }


  .main .contents .cont {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-flow: row;
    font-size: 13px;
  }

  .main .contents .cont svg {
    min-height: 40px;
  }

  .main .contents .cont .subtopic {
    margin: 0.5px 0;
  }

  .main .contents .cont .subtopic {
    margin: 1px;
    border-width: 2px;
  }

  .cont_title {
    padding: 0px;
  }

  .title {
    font-size: 22px;
    margin: 10px;
    height: 60px;
  }

  .main_content>.title {
    display: none;
  }

  img.section_logo {
    max-width: 8em;
  }

  .main article h1 {
    font-size: 20px;
  }

  .main article h2 {
    font-size: 17px;
  }

  .main article h3 {
    font-size: 15px;
  }

  .main article h2,
  .main article h3,
  .main article h4,
  .main article h5,
  .main article h6 {
    margin-top: 12px;
  }
}

@media (max-width: 600px) {
  .main .contents .cont {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .main article {
    padding: 10px 0px;
    font-size: 13px;
    border: 0px;
  }

  img.section_logo {
    max-width: 40px;
  }

}

@media (max-width: 400px) {
  .main .contents .cont {
    display: grid;
    grid-template-columns: 1fr;
  }

  .main .contents .cont svg {
    min-height: 20px;
  }

  .main article {
    font-size: 13px;
    text-align: left;
  }

}

/* nie da się zmniejszyć poniżej 336px przeszakadza kod (pre) */