import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/materialy_dodatkowe/pokazowe/Informatyka/MINP-R0-100-2203.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2023/materialy_dodatkowe/pokazowe/Informatyka/Dane_2203.zip" >PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ

<b>Uwaga:</b> do rozwiązania zadań 1.1.–1.3. nie jest potrzebna znajomość reguł gry w szachy.<br/>
W pliku <i>szachy.txt</i> znajduje się zapis partii szachów, jaką w 2020 roku rozegrali polski
arcymistrz Jan Krzysztof Duda oraz mistrz świata Magnus Carlssen. Zapis partii składa się
z opisów 125 plansz przedstawiających stany gry (położenie bierek na szachownicy) po
kolejnych posunięciach każdego z graczy. Opis każdej planszy składa się z:<br/>
• 8 wierszy tekstu po 8 znaków w każdym wierszu<br/>
• kolejne znaki w wierszach oznaczają:<br/>
<pre>   − znak '.' − puste pole</pre>  
<pre>   − wielkie litery − białe bierki (czyli białe figury i pionki)</pre>  
<pre>   − małe litery − czarne bierki</pre>  
<pre>   − oznaczenia bierek to:</pre> 
<pre>        − K/k − król, </pre> 
<pre>        − H/h − hetman,</pre> 
<pre>        − W/w − wieża,</pre> 
<pre>        − G/g − goniec,</pre> 
<pre>        − S/s − skoczek, </pre> 
<pre>        − P/p − pionek.</pre> 
<br/>
Dla zachowania czytelności, po każdym opisie następuje pojedynczy pusty wiersz. W dalszej
części, zamiast „opis planszy”, będziemy pisać krótko „plansza”.<br/><br/>

#### Przykład:<br/>

```
wsghkgsw
pppppppp
........
........
....P...
........
PPPP.PPP
WSGHKGSW
wsghkgsw
pp.ppppp
..p.....
........
....P...
........
PPPP.PPP
WSGHKGSW
wsghkgsw
pppppppp
........
........
....P...
........
PPPP.PPP
WSGHKGSW
wsghkgsw
pp.ppppp
..p.....
........
....P...
........
PPPP.PPP
WSGHKGSW
```
<br/>

Dwie podane wyżej przykładowe plansze odpowiadają następującym stanom gry:<br/>
![](https://i.imgur.com/QDpW7Wf.png)
<br/> 

![](https://i.imgur.com/R1uNeAN.png)

<br/>

Napisz <b>program(-y)</b>, który(-e) znajdzie(-dą) odpowiedzi do poniższych zadań.
Do Twojej dyspozycji jest plik <i>szachy_przyklad.txt</i>, który zawiera 9 plansz zapisanych
w podanym wyżej formacie. Odpowiedzi dla pliku <i>szachy_przyklad.txt</i> podano w treści
poszczególnych zadań. Pamiętaj, że Twój(-e) program(-y) musi(-szą) działać dla 125 plansz.

<br/><br/>

### <u>Zadanie 2</u>   (0-3pkt.)

Rozstrzygnij, ile razy w trakcie gry (inaczej: na ilu planszach zapisanych w pliku
<i>szachy.txt</i>) nastąpiła sytuacja, w której jest równowaga – jest <u>tyle samo</u> i <u>takich samych</u>
czarnych bierek, ile białych. Podaj liczbę takich plansz, a także najmniejszą liczbę bierek
(łącznie białych i czarnych) na planszy w stanie równowagi. <br/><br/>

#### Przykład:

```
A:                  B:
.k......           .p......
........           ........
........           ........
....s...           ....s...
....S...           ....s...
........           ........
........           ........
.......K           .......K
```

Plansza A jest w równowadze, a plansza B nie jest w równowadze (czarne i białe nie mają
takich samych bierek).
Odpowiedź dla pliku <i>szachy_przyklad.txt</i>:
6 4
(6 plansz w równowadze, 4 − najmniejsza liczba bierek na planszy w stanie równowagi)

<br/>
<br/>

<Dropdown title="ODPOWIEDŹ">

```
22 28
```

<p className={`dropdown_text`}>
    22 plansze w stanie równowagi, najmniejsza liczba bierek na planszy w stanie równowagi – 28
</p>

</Dropdown>





<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def get_boards() -> list[list[str]]:
    with open(r"szachy.txt") as text_file:
        boards_strs = text_file.read().strip("\n ").split("\n\n")

    return boards_strs


def is_balance(board: str) -> bool:
    uppercase_count = ""
    lowercase_count = ""

    for character in list(board):
        if character.lower() == character:
            lowercase_count += character

        else:
            uppercase_count += character

    return sorted(list(uppercase_count.lower())) == sorted(list(lowercase_count))


def main():
    boards = get_boards()

    boards = [board.replace("\n", "").replace(".", "") for board in boards]

    smallest_count = 8 * 8
    balanced_count = 0

    for board in boards:
        if not is_balance(board):
            continue

        balanced_count += 1
        smallest_count = min(smallest_count, len(board))

    print(balanced_count, smallest_count)


if __name__ == "__main__":
    main()

```
</Dropdown>
