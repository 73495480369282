## System operacyjny
to oprogramowanie, które odpowiada za zarządzanie programami komputerowymi. 

### Gdzie zapisany jest system operacyjny?
"System operacyjny jest przechowywany na dysku twardym, ale podczas rozruchu BIOS uruchomi system operacyjny, 
który jest ładowany do pamięci RAM i od tego momentu system operacyjny jest dostępny, gdy znajduje się w pamięci RAM." <br/>



(źródło:<a className={`link`} target="_blank" href="https://fikiri.net/gdzie-jest-zapisany-system-operacyjny/">https://fikiri.net/gdzie-jest-zapisany-system-operacyjny/</a> [dostęp: 07/02/2023])

<br/><br/>
System operacyjny zapisywany jest na twardym dysku komputera, ponieważ chcemy, aby był on trwale zapamiętany. W szczególności 
chcemy, żeby był on dostępny po wyłączeniu komoutera. Do tego celu wykorzystujemy więc pamięć nieulotną (dysk twardy).
Co za tym idzie nie możemy przechowywać systemu operacyjnego w pamięci RAM (ang. Random Access Memory), gdyż jest to tak zwana pamięć ulotna.
Dostęp do niej jest bardzo szybki i służy do przechowywania danych potrzebnych do pracy komputera w danym momencie, jednak jest ona często 
nadpisywana, zmieniania i nie należy wykorzystywać jej do zapamiętywania systemu operacyjnego.


### Zadania systemu operacyjnego
Do zadań systemu operacyjnego należy m.in.:

* zarządzanie pamięcią
* umożliwienie uruchamiania wielu procesów jednocześnie
* obsługa interakcji z użytkownikiem
* łączność z innymi urządzeniami i komputerami

### Rodzaje systemów operacyjnych 
Dawniej systemy operacyjne były wyłącznie tekstowe (np. Dos), obecnie wszystkie najpopularniejsze systemy operacyjne (Windows, MacOS)
oferują interfejs graficzny - GUI (ang. graphical user interface). Wciąż jednak część użytkowników wybiera systemy operacjne tekstowe, ze względu na 
optymalizację ilości pamięci komputera zajmowanej przez system oraz szybkośc działania. W szczególności sympatycy systemu Linux często decydują się na 
takie rozwiązanie.

<br/>
Na jednym komputerze możemy zainstalowac również kilka systemów operacyjnych. Bardzo popularnym rozwiązaniem jest dual-boot, zazwyczaj łączący 
Windows i Linux. W internecie możemy znaleźć wiele poradników i artykułów na ten temat.