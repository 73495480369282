import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2021-czerwiec-matura-rozszerzona-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2021-czerwiec-matura-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ
W pliku <i>napisy.txt</i> znajduje się 1000 wierszy po 50 znaków (dużych liter angielskiego
alfabetu oraz cyfr).<br/><br/>
Napisz <b>program(y)</b>, który(e) da(dzą) odpowiedzi do poniższych zadań. Odpowiedzi zapisz
w pliku <i>wyniki4.txt</i>, a każdą odpowiedź poprzedź numerem oznaczającym odpowiednie
zadanie.<br/><br/>
<b>Uwaga:<br/></b> Plik <i>przyklad.txt</i> zawiera dane przykładowe spełniające warunki zadania.
Odpowiedzi dla danych z pliku <i>przyklad.txt</i> są podane pod pytaniami. )

<br/><br/>

### <u>Zadanie 3</u> (4pkt.)
Palindromem nazywamy napis, który czytany od początku lub od końca jest taki sam (np.
KAJAK). Część napisów zapisanych w wierszach pliku (każdy ma 50 znaków) można w prosty
sposób – przez dodanie dokładnie jednego znaku na początku lub na końcu napisu – zamienić
na palindrom.<br/><br/>
Podaj hasło utworzone przez środkowe litery tak utworzonych palindromów.<br/>
Dla danych z pliku <i>przyklad.txt</i> wynikiem jest: 

```text
INFORMATYKA
```

<br/><br/>
<Dropdown title="ODPOWIEDŹ">

```text
ZADANIEMATURALNE
```
</Dropdown>





<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def can_be_palindromed(string: str):
    if string[1:] == string[1:][::-1]:
        return string + string[0]

    if string[:-1] == string[:-1][::-1]:
        return string[-1] + string


def main():
    with open(r"napisy.txt") as text_file:
        lines = text_file.read().strip().split("\n")

    lines = list(map(can_be_palindromed, lines))

    lines = list(filter(lambda x: x is not None, lines))

    print("".join([line[25] for line in lines]))


if __name__ == "__main__":
    main()

```
</Dropdown>
