import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2010/Informatyka/pp_ii.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2010/Informatyka/Dane_PP.rar"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ (0-10pkt.)
Palindromem nazywamy słowo, które czytane od lewej i od prawej strony jest takie samo.<br/>
Na przykład palindromami są słowa:<br/>
JABFDFBAJ<br/>
HAJAHAJAH<br/>
ABBA<br/>
Słowo JANA nie jest palindromem.<br/><br/>
W pliku <i>dane.txt</i> umieszczono w kolejnych wierszach 1000 słów o długościach od 2 do
25 znaków, składających się z wielkich liter A, B, C, D, E, F, G, H, I, J. <b>Napisz program</b>,
który przegląda słowa zapisane w pliku <i>dane.txt</i> i wypisuje te z nich, które są
palindromami, po jednym w wierszu. Kolejność wypisywania palindromów powinna być
taka sama jak w pliku z danymi. Wyniki zapisz w pliku <i>zadanie4.txt</i>. 

<br/><br/>

<Dropdown title="ODPOWIEDŹ">

```text
CBJBC
AABBHHIHHBBAA
CCJGJCC
FDDFFFDDF
BCACHCACB
DFDCCDFD
DFFFD
AABBAA
AABAABAABAA
AJAJAJAJAJA
FAFBDCEAAAECDBFAF
CEDDIGIDDEC
ABFACDDABADDCAFBA
EBE
EABAE
FDFBCFFCBFDF
FDDEDDF
FAAAF
AABBAA
EECEE
DDFFEEJGJEEFFDD
BCEABDECCEDBAECB
FEDFBBGGBBFDEF
BDDBCEADDBFCCFBDDAECBDDB
BAAJDJAAB
BBFFGJGFFBB
CBJGJBC
BDDDB
AEFDCEFEGEFECDFEA
ABBCCBBA
ABCDEFGHIJIHGFEDCBA
BADADAB
FGHAHGF
AAABEDEBAAA
CFCFC
HJHHJHHJH
DCFCD
BDCAEEIJIEEACDB
BDDFBAFAFABFDDB
ADAADA
ABCGCBA
GABAG

```
</Dropdown>

<Dropdown title="ROZWIĄZANIE PRZYKŁADOWE - PYTHON">

```python
import math
prime_numbers = []

def is_prime(number):
    for i in range(2, int(math.sqrt(number))+1):
        if number % i == 0:
            return False
    return True


with open("liczby.txt", "r") as f:
    lines = f.readlines()

numbers = list(map(lambda x: int(x.strip()), lines))
max_num = max(numbers)

# tworzymy tablice wszystkich liczb pierwszych od 2 do pierwiastka z najwiekszej liczby w pliku
for i in range(2, int(math.sqrt(max_num))):
    if is_prime(i):
        prime_numbers.append(i)

for number in numbers:
    sqrt = int(math.sqrt(number))
    
    # sprawdzamy czy pierwiastek z liczby w pliku jest w naszej tablicy liczb pierwszych
    # oraz czy liczba jest w ogole kwadratem liczby naturalnej
    if sqrt in prime_numbers and sqrt**2 == number:
        print(number)
```
</Dropdown>



