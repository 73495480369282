import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2020/formula_od_2015/informatyka/MIN-R2_1P-202.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2020/formula_od_2015/informatyka/Dane_PR2.zip">PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ
W pliku <i>pary.txt</i> znajduje się 100 wierszy. Każdy wiersz zawiera parę danych składającą
się z liczby całkowitej z przedziału od 3 do 100 i słowa (ciągu znaków) złożonego z małych
liter alfabetu angielskiego o długości od 1 do 50 znaków. Liczba i słowo są oddzielone znakiem
spacji.<br/>
Napisz <span className="bold">program(-my)</span>, dający(-e) odpowiedzi do poniższych zadań. Uzyskane odpowiedzi
zapisz w pliku <i>wyniki4.txt</i>, poprzedzając każdą z nich numerem odpowiedniego zadania.<br/><br/>
<span className="bold">Uwaga</span>: plik <i>przyklad.txt</i> zawiera przykładowe dane spełniające warunki zadania.
Odpowiedzi dla danych z pliku <i>przyklad.txt</i> są podane pod treściami zadań oraz w pliku
<i>odp_przyklad.txt</i>. 

<br/><br/>

### <u>Zadanie 1</u>   (0-3pkt.)
Mocna hipoteza Goldbacha mówi, że każda parzysta liczba całkowita większa od 4 jest sumą
<span className="bold">dwóch nieparzystych</span> liczb pierwszych, np. liczba 20 jest równa sumie 3 + 17 lub sumie
7 + 13.<br/>
Każdą <span className="bold">liczbę parzystą</span> z pliku <i>pary.txt</i> przedstaw w postaci sumy dwóch liczb pierwszych.
Wypisz tę liczbę oraz dwa składniki sumy w kolejności niemalejącej. Jeżeli istnieje więcej
rozwiązań (tak jak dla liczby 20) należy wypisać składniki sumy o największej różnicy.
Wyniki podaj w oddzielnych wierszach, w kolejności zgodnej z kolejnością danych w pliku
<i>pary.txt</i>. Liczby w każdym wierszu rozdziel znakiem spacji, np. dla liczby 20 należy
wypisać 20 3 17.<br/><br/>

### Dla danych z pliku przyklad.txt prawidłową odpowiedzią jest:

```text
24 5 19
6 3 3
6 3 3 
```
<br/><br/>

<Dropdown title="ODPOWIEDŹ">

```text
68 7 61
24 5 19
48 5 43
12 5 7
42 5 37
66 5 61
78 5 73
88 5 83
76 3 73
64 3 61
24 5 19
22 3 19
64 3 61
18 5 13
36 5 31
42 5 37
56 3 53
30 7 23
44 3 41
28 5 23
94 5 89
28 5 23
52 5 47
28 5 23
6 3 3
36 5 31
34 3 31
58 5 53
```

</Dropdown>


<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
from math import sqrt, ceil


def primes_smaller_than(number: int) -> list[int]:

    primes_small = []

    for maybe_prime in range(2, ceil(sqrt(number))):

        for divider in range(2, maybe_prime):

            if divider == maybe_prime:
                continue

            if maybe_prime % divider:
                continue

            break

        else:
            primes_small.append(maybe_prime)

    is_prime = [True for _ in range(number)]

    for prime_number in primes_small:

        for not_prime in range(prime_number * 2, number, prime_number):
            is_prime[not_prime] = False

    primes = []

    for index in range(2, number):
        if is_prime[index]:
            primes.append(index)

    return primes


def get_numbers() -> list[int]:

    with open(r"pary.txt") as text_file:
        lines = text_file.read().strip().split("\n")

    numbers = []

    for line in lines:
        numbers.append(int(line.split()[0]))

    return numbers


def get_prime_sum(number: int, primes: list[int]) -> None:

    prime_pairs = []
    primes_used = set()

    for prime_number in primes:
        if prime_number > number:
            break

        if prime_number in primes_used:
            continue

        rest_from_prime = number - prime_number

        if rest_from_prime in primes:
            prime_pairs.append(sorted([rest_from_prime, prime_number]))
            primes_used.add(rest_from_prime)

    return sorted(prime_pairs, key=lambda pair: abs(pair[0] - pair[1]), reverse=1)[0]


def main():
    numbers = get_numbers()

    primes = primes_smaller_than(100)

    for number in numbers:
        if number <= 4 or number % 2:
            continue

        print(number, *get_prime_sum(number, primes))


if __name__ == "__main__":
    main()

```
</Dropdown>
