import Dropdown from "../../components/dropdown/Dropdown.jsx";
import TableQuiz from "../../components/tableQuiz/TableQuiz.jsx";
import Quiz from "../../components/Quiz.jsx";
import FillGapsQuiz from "../../components/FillGapsQuiz.jsx";

## Zadanie 1 (0-1pkt.)
(maj 2016 część 1 zadanie 3.2)

### TREŚĆ
Dana jest funkcja f określona wzorem rekurencyjnym
<img src={new URL('https://i.imgur.com/aSqBx3L.png', import.meta.url)} alt="xyz" className={`super_small_img left_img`} />
<TableQuiz
    questions={
      [
        {question: "f(8) = 1/3", answer: 'F'},
        {question: "f(9) = 3/4", answer: 'P'},
        {question: "f(10) = 4", answer: 'P'},
        {question: "f(100) = -1/3", answer: 'F'},
      ]
    }
/>

## Zadanie 2 (0-1pkt.)
(maj 2020 część 1 zadanie 3.2)

### TREŚĆ
W poniższym algorytmie ``n`` jest nieujemną liczbą całkowitą, ``mod`` to operator reszty z dzielenia, ``div`` to operator dzielenia całkowitego. 
<pre>
    w ← 0 <br/>
    dopóki n ≠ 0 wykonuj <br/>
    &nbsp;&nbsp;&nbsp;&nbsp; w ← w + (n mod 10) <br/>
    &nbsp;&nbsp;&nbsp;&nbsp; n ← n div 10  <br/>
</pre>
<TableQuiz 
    questions={
      [
        {question: "Po wykonaniu algorytmu dla n = 45778 zmienna w przyjmuje wartość 30. ", answer: 'F'},
        {question: "Po wykonaniu algorytmu dla liczby n wartością zmiennej w jest suma cyfr liczby n w zapisie dziesiętnym. ", answer: 'P'},
        {question: "Podczas wykonywania algorytmu dla n = 1234 w kolejnych iteracjach pętli dopóki, zmienna w przyjmuje wartości 1, 3, 6, 10.", answer: 'F'},
        {question: "Po wykonaniu algorytmu dla n = 11111 zmienna w przyjmuje wartość 5. ", answer: 'P'},
      ]
    }
/>

## Zadanie 3 (0-1pkt.)
(maj 2021 część 1 zadanie 3.1)

### TREŚĆ
Dana jest następująca funkcja:
<pre>
  funkcja f(n): <br/>
  &nbsp;&nbsp;&nbsp;&nbsp; jeżeli n > 0 <br/>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; wypisz n <br/>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; f(n – 2) <br/>
  &nbsp;&nbsp;&nbsp;&nbsp; wypisz n
</pre>
<TableQuiz
    questions={
      [
        {question: "W wyniku wywołania f(5) otrzymamy ciąg 5 5 5 5 5 5. ", answer: 'F'},
        {question: "W wyniku wywołania f(6) otrzymamy ciąg 6 4 2 2 4 6.", answer: 'P'},
        {question: "W wyniku wywołania f(7) otrzymamy ciąg 7 5 3 1 1 3 5 7.", answer: 'P'},
        {question: "W wyniku wywołania f(8) otrzymamy ciąg 8 6 4 2 0 0 2 4 6 8.", answer: 'F'},
      ]
    }
/>

## Zadanie 4 (0-1pkt.)
(maj 2022 część 1 zadanie 3.1)

### TREŚĆ
Dana jest następująca funkcja:
<pre>
  s ← 0 <br/>
  dla i = 1, 2, …, n <br/>
  &nbsp;&nbsp;&nbsp;&nbsp; dla j = i, i + 1, …, n <br/>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; s ← s + 1 <br/>
</pre>
Złożoność obliczeniowa powyższego algorytmu oceniona liczbą wykonań instrukcji
``s ← s + 1``, w zależności od dodatniej liczby całkowitej ``n``, jest 

<TableQuiz 
    questions={
      [
        {question: "liniowa.", answer: 'F'},
        {question: "kwadratowa.", answer: 'P'},
        {question: "n log n.", answer: 'F'},
        {question: "nie większa niż sześcienna.", answer: 'P'},
      ]
    }
/>

## Zadanie 5 (0-1pkt.)
(maj 2016 stara matura, poziom podstawowy, część 1 zadanie 3.3)

### TREŚĆ
Programowanie polegające na określeniu i wykorzystaniu klas nazywamy programowaniem 

<Quiz 
    question={''}
    answers={[
        "liniowym",
        "obiektowym",
        "strukturalnym",
        "mikroprocesorów"]}
    correct={"obiektowym"}
/>

## Zadanie 6 (0-1pkt.)
(maj 2016 stara matura, poziom podstawowy, część 1 zadanie 3.4)

### TREŚĆ
Ciąg deklaracji i instrukcji zapisany w języku programowania wysokiego poziomu nazywamy kodem

<Quiz 
    question={''}
    answers={[
        "wynikowym",
        "pośrednim",
        "źródłowym",
        "maszynowym"]}
    correct={"źródłowym"}
/>

## Zadanie 7 (0-1pkt.)
(maj 2011 stara matura, poziom podstawowy, część 1 zadanie 3b)

### TREŚĆ
Dane są dwie liczby całkowite a, b takie, że b \> a  oraz schemat algorytmu: 
<img src={new URL('https://i.imgur.com/gRmZ1UR.png', import.meta.url)} alt="xyz" className={`medium_img left_img`} />
Wynikiem działania tego algorytmu jest wypisanie 

<Quiz 
    question={''}
    answers={[
        "wyłącznie liczb parzystych z przedziału domkniętego <a,b/>",
        "wyłącznie liczb nieparzystych z przedziału domkniętego <a,b/>",
        "wszystkich liczb z przedziału domkniętego <a,b/>"]}
    correct={"wszystkich liczb z przedziału domkniętego <a,b/>"}
/>

## Zadanie 8 (0-1pkt.)
(maj 2009 stara matura, poziom podstawowy, część 1 zadanie 3f)

### TREŚĆ 
Minimum ciągu n-elementowego (dla dowolnego całkowitego n ≥ 1)

<TableQuiz 
    questions={
      [
        {question: "można zawsze znaleźć, wykonując nie więcej niż n porównań pomiędzy elementami ciągu", answer: 'P'},
        {question: "można zawsze znaleźć, wykonując nie więcej niż n/2 porównań pomiędzy elementami ciągu", answer: 'F'},
        {question: "można znaleźć za pomocą strategii przeszukiwania liniowego", answer: 'P'}
      ]
    }
/>

## Zadanie 9 (0-1pkt.)
(maj 2009 stara matura, poziom podstawowy, część 1 zadanie 3g)

### TREŚĆ 
Rozważ poniższy algorytm. 
<img src={new URL('https://i.imgur.com/u9J7c61.png', import.meta.url)} alt="xyz" className={`medium_img left_img`} />
Wynikiem działania tego algorytmu dla liczby całkowitej n > 0 jest wypisanie wartości
wyrażenia 

<TableQuiz 
    questions={
      [
        {question: "1+ 2 + 3 + ... + n", answer: 'F'},
        {question: "1+1/ 2 +1/ 3 + ... +1/ n", answer: 'P'},
        {question: "1*1/ 2 *1/ 3*...*1/ n", answer: 'F'}
      ]
    }
/>

## Zadanie 10 (0-3pkt.)
(maj 2014 stara matura, poziom rozszerzony, część 1 zadanie 3a)

### TREŚĆ 
Przeanalizuj poniższy algorytm dla dodatniej liczby całkowitej n: 
<pre>
jeżeli n = 1, to suma ← 1 <br/>
w przeciwnym przypadku <br/>
&nbsp;&nbsp;&nbsp;&nbsp; suma ← 1 + n <br/>
&nbsp;&nbsp;&nbsp;&nbsp; i ← n – 1 <br/>
&nbsp;&nbsp;&nbsp;&nbsp; dopóki i > 1 wykonuj <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; suma ← 1 + i * suma <br/>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; i ← i – 1 
</pre>

#### <u>Podpunkt A</u> (0-2pkt.)
Podaj wartość zmiennej suma po zakończeniu działania algorytmu dla następujących
wartości argumentu n: 
<img src={new URL('https://i.imgur.com/GSWEK9m.png', import.meta.url)} alt="xyz" className={`medium_img`} />

<Dropdown title="Rozwiązanie">

<img src={new URL('https://i.imgur.com/9kHWp4H.png', import.meta.url)} alt="xyz" className={`medium_img`} />

<p className={`dropdown_text`}>
2 p. – za podanie poprawnych odpowiedzi <br/>
1 p. – za podanie jednej poprawnej odpowiedzi <br/>
0 p. – za podanie niepoprawnej odpowiedzi lub jej brak 
</p>
</Dropdown>

#### <u>Podpunkt B</u> (0-1pkt.)
Wynikiem działania algorytmu przedstawionego na początku zadania jest 

<TableQuiz 
    questions={
      [
        {question: "1 + 2 · (1 + 3 · (1 + … (n – 2) · (1 + (n – 1) · (1 + n)) … ))", answer: 'P'},
        {question: "1 + 2<sup>2</sup> + 3<sup>3</sup> + ... + n<sup>n</sup>", answer: 'F'},
        {question: "1! + 2! + 3! + … + n!", answer: 'P'},
        {question: "1 + 2 + 3 + … + n", answer: 'F'}
      ]
    }
/>


## Zadanie 11 (0-1pkt.)
(maj 2013 stara matura, poziom rozszerzony, część 1 zadanie 3b)

### TREŚĆ 
Dla x będących liczbami całkowitymi dodatnimi, funkcja określona wzorem 
<img src={new URL('https://i.imgur.com/qfU6iJv.png', import.meta.url)} alt="xyz" className={`medium_img left_img`} />


<TableQuiz 
    questions={
      [
        {question: "jest przykładem funkcji rekurencyjnej.", answer: 'P'},
        {question: "dla x = 3 przyjmuje wartość t(x) = 9 .", answer: 'F'},
        {question: "dla liczby całkowitej dodatniej x przyjmuje wartość x!.", answer: 'P'},
        {question: "w rozwinięciu zawiera x - 1 operatorów mnożenia „*”.", answer: 'P'}
      ]
    }
/>

## Zadanie 12 (0-1pkt.)
(maj 2013 stara matura, poziom rozszerzony, część 1 zadanie 3f)

### TREŚĆ 
Przeanalizuj działanie poniższego algorytmu dla n = 3. 
<img src={new URL('https://i.imgur.com/q1u9owE.png', import.meta.url)} alt="xyz" className={`medium_img left_img`} />


<TableQuiz 
    questions={
      [
        {question: "Podczas wykonywania algorytmu k dwukrotnie przyjmuje wartość 3.", answer: 'F'},
        {question: "Podczas wykonywania algorytmu i dwukrotnie przyjmuje wartość 2.", answer: 'P'},
        {question: "Po wykonaniu powyższego algorytmu s = 7 .", answer: 'P'},
        {question: "Po wykonaniu powyższego algorytmu p = 108 .", answer: 'P'}
      ]
    }
/>

## Zadanie 13 (0-1pkt.)
(maj 2011 stara matura, poziom rozszerzony, część 1 zadanie 3f)

### TREŚĆ 
Rozważalgorytm, który dla zadanego naturalnego n \> 0 oblicza następującą sumę: <br/>
suma = 1<sup>1</sup> + 2<sup>2</sup> + 3<sup>3</sup> + 4<sup>4</sup> + ... + n<sup>n</sup>
<img src={new URL('https://i.imgur.com/GoBoX13.png', import.meta.url)} alt="xyz" className={`small_img left_img`} />
Oceń prawdziwość stwierdzeń

<TableQuiz 
    questions={
      [
        {question: "Liczba instrukcji wykonana przez ten algorytm n e za i leży od wielkości n.", answer: 'F'},
        {question: "Liczba instrukcji wykonana przez ten algorytm jest funkcją kwadratową ze względu na n. ", answer: 'P'},
        {question: "Instrukcja w kroku 2. jest instrukcją iteracji.", answer: 'P'},
        {question: "Wartość zmiennej j w kroku 2.b. zmienia się kolejno od 1 do i, przy n \> 1.", answer: 'F'}
      ]
    }
/>

## Zadanie 14 (0-1pkt.)
(maj 2010 stara matura, poziom rozszerzony, część 1 zadanie 3b)

### TREŚĆ 
Algorytm
<img src={new URL('https://i.imgur.com/P89fnHX.png', import.meta.url)} alt="xyz" className={`medium_img left_img`} />
znajduje

<TableQuiz 
    questions={
      [
        {question: "NWW (a,b)", answer: 'F'},
        {question: "NWD (a,b)", answer: 'P'},
        {question: "liczbę pierwszą większą od a i mniejszą od b. ", answer: 'F'}
      ]
    }
/>

## Zadanie 15 (0-2pkt.)
(maj 2009 stara matura, poziom rozszerzony, część 1 zadanie 3a)
(2pkt. za w pełni poprawnąodpowiedź, 0pkt. w przeciwnym przypadku)

### TREŚĆ 
Przeanalizuj poniższy algorytm (:= oznacza instrukcję przypisania) 
<img src={new URL('https://i.imgur.com/mEq5IWl.png', import.meta.url)} alt="xyz" className={`small_img left_img`} />

<TableQuiz 
    questions={
      [
        {question: "Wykonywanie algorytmu zakończy się po wypisaniu liczb od 1 do 7", answer: 'P'},
        {question: "Po pierwszym sprawdzeniu warunku w kroku 3. nie zostaną wykonane kroki: 4., 5., 6. i wykonywanie algorytmu zakończy się. ", answer: 'F'},
        {question: "Wykonywanie algorytmu zakończy się po wypisaniu liczb od 0 do 6.", answer: 'F'},
        {question: "Sprawdzenie warunku m n > wykonane zostanie dokładnie 8 razy", answer: 'P'}
      ]
    }
/>

## Zadanie 16 (0-1pkt.)
(maj 2009 stara matura, poziom rozszerzony, część 1 zadanie 3a)

### TREŚĆ 
Schemat Hornera znajduje zastosowanie przy

<TableQuiz 
    questions={
      [
        {question: "obliczaniu pola powierzchni figur płaskich", answer: 'F'},
        {question: "obliczaniu wartości wielomianu przy minimalnej liczbie operacji mnożenia", answer: 'P'},
        {question: "szybkim sortowaniu dużych zbiorów danych", answer: 'F'},
        {question: "znajdowaniu najmniejszego elementu w zbiorze", answer: 'F'}
      ]
    }
/>

## Zadanie 17 (0-1pkt.)
(maj 2008 stara matura, poziom rozszerzony, część 1 zadanie 3a)

### TREŚĆ 
Dla poniższego algorytmu dane stanowi skończony ciąg liczbowy zawierający
co najmniej jedną liczbę:
<img src={new URL('https://i.imgur.com/9i6MljH.png', import.meta.url)} alt="xyz" className={`medium_img left_img`} />
Uwaga: „:=” oznacza instrukcję przypisania <br/>
Wynikiem działania tego algorytmu jest 

<TableQuiz 
    questions={
      [
        {question: "suma podanych liczb.", answer: 'F'},
        {question: "średnia arytmetyczna podanych liczb.", answer: 'P'},
        {question: "średnia geometryczna podanych liczb.", answer: 'F'}
      ]
    }
/>

## Zadanie 18 (0-1pkt.)
(maj 2008 stara matura, poziom rozszerzony, część 1 zadanie 3b)

### TREŚĆ 
Poszukując numeru telefonu w książce telefonicznej wiele osób korzysta z następującego
algorytmu: otwieramy książkę mniej więcej w połowie. Jeśli szukane nazwisko
w kolejności alfabetycznej jest wcześniej niż nazwisko, na które trafiliśmy, otwieramy
książkę w połowie, licząc od początku do miejsca, w którym się znajdujemy.
W przeciwnym przypadku bierzemy pod uwagę drugą połowę książki. Postępujemy
podobnie dla tej części książki, którą wybraliśmy, aż do momentu, kiedy jesteśmy blisko
szukanego nazwiska. Wtedy wystarczy już przejrzeć kilka stron. Ten sposób
postępowania jest zastosowaniem w praktyce strategii 

<TableQuiz 
    questions={
      [
        {question: "dziel i zwyciężaj", answer: 'P'},
        {question: "zachłannej", answer: 'F'},
        {question: "porządkowania ciągu elementów", answer: 'F'}
      ]
    }
/>

## Zadanie 19 (0-1pkt.)
(maj 2008 stara matura, poziom rozszerzony, część 1 zadanie 3f)

### TREŚĆ 
Poniższy schemat blokowy opisuje instrukcję powtarzania, w której 
<img src={new URL('https://i.imgur.com/EXX8CYr.png', import.meta.url)} alt="xyz" className={`medium_img left_img`} />

<TableQuiz 
    questions={
      [
        {question: <p>liczba powtórzeń instrukcji <b>I</b> nie zależy od warunku warunek</p>, answer: 'F'},
        {question: <p>instrukcja <b>I</b> jest wykonywana co najmniej raz</p>, answer: 'P'},
        {question: <p>jeśli warunek <b>nie jest spełniony</b>, to następuje zakończenie powtarzania</p>, answer: 'F'}
      ]
    }
/>

## Zadanie 20 (0-1pkt.)
(maj 2007 stara matura, poziom rozszerzony, część 1 zadanie 1.8.)

### TREŚĆ
Spośród czterech algorytmów, o podanych niżej złożonościach, najbardziej wydajny
jest algorytm o złożoności 

<Quiz 
    question={''}
    answers={[
        "liniowej",
        "wykładniczej",
        "kwadratowej",
        "logarytmicznej"]}
    correct={"logarytmicznej"}
/>

