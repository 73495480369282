import Table from "../../components/Table.jsx";

<br/>

Naszym celem w tej lekcji będzie stworzenie tabeli takiej jak poniższa w Access-ie. Inne tabele będziemy importować w analogiczny sposób. Dalsze kroki postępowania ze stworzoną przez nas tabelą są takie same jak w przypadku importowania danych do tabeli z pliku tekstowego, które omawialiśmy w temacie "2.2. Importowanie danych".

<br/>

**Tabela: Soki**
<Table
header = {["Smak", "Pojemność", "Cena"]}
data = {[
        ["Jabłkowy", "330", "5,00"],
        ["Pomarańczowy", "250", "5,00"],
        ["Porzeczkowy", "250", "4,50"],
        ["Grejpfrutowy", "330", "6,00"],
        ["Kaktusowy", "125", "4,50"]
        ]}
/>

<br/>

### 1. Wybieramy zakładkę "Tworzenie" -> "Tabela".
![](https://i.imgur.com/MB6yvsk.png)

### 2. Klikamy, aby dodać pole i wybieramy odpowiedni typ danych.
Pamiętajmy, że w jednej kolumnie tabeli możemy przechowywać dane tylko jednego typu (np. tylko liczby lub tylko napisy).
![](https://i.imgur.com/RpbckH4.png)

### 3. Nazywamy naszą kolumnę.
Klikamy na nazwę wybranego pola, zaznaczamy test i zamieniamy nazwę na wybraną przez nas.
![](https://i.imgur.com/0GQTG7Y.png)

### 4. Analogicznie dodajemy inne pola.
<img src={new URL('https://i.imgur.com/fNXku6m.png', import.meta.url)} alt="xyz" className={'medium_img'} />

### 5. Wprowadzamy wartości do tabeli.
Klikamy na pole, do którego chcemy dodać wartość i wprowadzamy ją.
<img src={new URL('https://i.imgur.com/36RU9PR.png', import.meta.url)} alt="xyz" className={'medium_img'} />

### 6. Ustawiamy wyświetlanie.
![](https://i.imgur.com/PI3QMUk.png)

### 7. Wybieramy klucz główny.
W tym przypadku klucz główny został dodany przez program Access automatycznie. Gdybyśmy chcieli, aby któraś ze stworzonych przez nas kolumn np. Smak była kluczem głównym, zmieniamy widok na "Widok projektu", zaznaczamy odpowiednią kolumnę i wybieramy "Projektowanie" -> "Klucz podstawowy".
![](https://i.imgur.com/7sVbMsr.png)
![](https://i.imgur.com/YEvPf1Q.png)

### 8. Gotowe!