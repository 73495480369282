<br/> 

Część spośród wskazówek może być niezrozumiała przed przerobieniem odpowiednich lekcji z Accessa. Nie martw się tym,
w każdej chwili możesz wrócić do tej sekcji i przeczytać ją jeszcze raz. Na razie chcemy, zwrócić Twoją uwagę na pewne aspekty,
które będziemy jeszcze niejednokrotnie podkreślać, tak abyś miał je "z tyłu głowy".

## Uwagi natury ogólnej:

### Zapoznaj się z instrukcją na początku arkusza.
Wiem, że czytanie instrukcji pisania matury, to nie jest najbardziej pasjonujące zadanie na świecie. Jeśli jednak poświęciłeś już tyle czasu na naukę i kolejne dwie godziny życia przeznaczysz na rozwiązywanie zadań na egzaminie, to warto zainwestować też 5 min w dokładne przeczytanie instrukcji.

### Dbaj o nazewnictwo.
Egzaminator nie sprawdza plików o niepoprawnej nazwie, czyli niezgodnej z treścią zadania lub tym, co zadeklarowaliśmy poprzez uzupełnienie pola na nazwę. Oznacza to, że nawet jeśli twoje rozwiązanie będzie celujące, ale plik nazwiesz niepoprawnie, to otrzymasz ZERO punktów.

### Pamiętaj o plikach z odpowiedziami.
Do każdego zadania musisz stworzyć plik tekstowy, w który zapiszesz odpowiedzi do wszystkich podpunktów z zadania.

### Zapisuj swoje rozwiązania na bieżąco.
Wypadki się zdarzają. Możesz przez przypadek zamknąć swój program, podczas egzaminu może pojawić się spięcie i chwilowy brak prądu. Te i wiele innych sytuacji losowych mogą skutkować utratą danych. Dlatego pamiętaj, aby na bieżąco zapisywać postęp swoich prac.

### Przeczytaj uważnie zadanie.
W poleceniu kryje się mnóstwo bardzo istotnych informacji. W zależności od formuły, jaką CKE wybierze na dany rok, ten etap może być trudny lub łatwy. Czasami wszystkie detale takie jak typy danych, nazwy kolumn itp. są rozpisane bardzo przejrzyście i szczegółowo, czasami jednak trzeba mozolnie wyłuskiwać te informacje z zawiłej treści zadania.

## Bardziej techniczne uwagi:

### Zwróć szczególną uwagę na format daty.
Przy imporcie danych data może wyświetlać się w sensowny sposób, sprawiając wrażenie, że formatowanie jest prawidłowe. Zawsze jednak upewnij się, że faktycznie tak jest! Błąd popełniony w tym miejscu będzie fatalny w skutkach.

### Upewnij się, że klucz główny został wybrany prawidłowo.

### Przemyśl i sprawdź, czy relacje między tabelami są właściwe.

### Nie bój się rozbijać zadania na podproblemy. Kwerendy pomocnicze są równie dobre, co jedno złożone zapytanie.

### Podczas nauki zerkaj do widoku SQL.
Na uczelni czy w przyszłej pracy, a nawet na samej maturze znajomość SQL-a będzie pomocna. Próg wejścia do zrozumienia języka zapytań — SQL nie jest wysoki, czyta się go niemalże jak zdania w języku angielskim. Co prawda Access ma specyficzny sposób zapisu odwołań do tabel, nie utrudnia to jednak znacząco zrozumienia.
Jeśli wydaje Ci się, że zapytanie, które widzisz w widoku SQL, jest dziwnie skonstruowane i niepotrzebnie skomplikowane, to możliwe, że masz rację. Access czasami tłumaczy kwerendy "wyklikane" w programie w dość zawiły sposób. Nie martw się tym.

## Instrukcja do części praktycznej matury z informatyki
<img src={new URL('https://i.imgur.com/dggEAGX.png', import.meta.url)} alt="xyz" className={'small_img'} />