import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2022/Informatyka/poziom_rozszerzony/EINP-R2-100-2205.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2022/Informatyka/poziom_rozszerzony/Dane_2205.zip">PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ
W pliku <i>liczby.txt</i> danych jest 200 różnych liczb całkowitych z zakresu [10, 100000].
Każda z tych liczb zapisana jest w osobnym wierszu.
Napisz program (lub kilka programów), który(-e) znajdzie(-dą) odpowiedzi do poniższych
zadań. Odpowiedzi zapisz w pliku <i>wyniki4.txt</i>. Każdą odpowiedź poprzedź numerem
oznaczającym zadanie.
Do dyspozycji masz plik <i>przyklad.txt</i>, który także zawiera 200 liczb – odpowiedzi dla
tego pliku podano w treściach zadań. Możesz sprawdzać na nim działanie swojego
programu.
Uwaga: Pamiętaj, że Twój program musi ostatecznie działać dla pliku <i>liczby.txt</i>.

<br/><br/>

### <u>Zadanie 2</u>   (0-4pkt.)
Znajdź w pliku <i>liczby.txt</i>:
<li> liczbę, która ma w rozkładzie najwięcej czynników pierwszych (podaj tę liczbę oraz liczbę
jej czynników pierwszych) </li>
<li>liczbę, która ma w rozkładzie najwięcej różnych czynników pierwszych (podaj tę liczbę
oraz liczbę jej różnych czynników pierwszych).</li>

#### Przykład:
Liczba 420=2·2·3·5·7 ma w rozkładzie 5 czynników pierwszych, w tym 4 różne
czynniki pierwsze (2, 3, 5, 7).
Odpowiedź dla danych z pliku <i>przyklad.txt</i>: 

```text
144 6 210 4
```
(Liczba 144 ma najwięcej czynników pierwszych; liczba czynników pierwszych liczby 144
wynosi 6. Liczba 210 ma najwięcej różnych czynników pierwszych; liczba różnych czynników
pierwszych liczby 210 wynosi 4).

<br/><br/>

<Dropdown title="ODPOWIEDŹ">

```text
Najwięcej czynników: 10 dla 99792, 20992, 56064
Najwięcej różnych: 6 dla 62790 
```

</Dropdown>


<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
PATH = r"liczby.txt"


def get_prime_factors(number: int) -> int:
    divider = 1
    factors = []

    while number != 1:
        divider += 1

        while not number % divider:
            number = number // divider
            factors.append(divider)

    return factors


def get_numbers() -> list[str]:

    with open(PATH) as text_file:
        numbers = text_file.read().strip().split("\n")

    return list(map(int, numbers))


def main():
    numbers = list(map(lambda x: [x, get_prime_factors(x)], get_numbers()))

    res1 = len(max(numbers, key=lambda x: len(x[1]))[1])
    res2 = len(max(numbers, key=lambda x: len(set(x[1])))[1])

    res_1_matching = list(filter(lambda x: len(x[1]) == res1, numbers))

    for match in res_1_matching:
        print(match[0], end=" ")

    print("- najwiecej czynnikow", res1)

    res_2_matching = list(filter(lambda x: len(set(x[1])) == res2, numbers))

    for match in res_2_matching:
        print(match[0], end=" ")

    print("- najwiecej roznych czynnikow", res2)


if __name__ == "__main__":
    main()

```
</Dropdown>
