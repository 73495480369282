import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2011/P/inf_pp_2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2011/Dane_PP.rar"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ (0-10pkt.)
Informatyk z firmy „KompOK” zapisał w pliku <i>hasla.txt</i> 200 haseł. Każde hasło
umieszczone jest w osobnym wierszu pliku. Hasło składa się tylko z małych liter alfabetu
angielskiego, zaś jego długość wynosi od 3 do 10 znaków.<br/>
Wykorzystując dane zawarte w tym pliku, wykonaj poniższe polecenia. Odpowiedzi do
poszczególnych podpunktów zapisz w plikach tekstowych o nazwach <i>wynik4a.txt</i>,
<i>wynik4b.txt</i>, <i>wynik4c.txt</i>.


<br/><br/>

### <u>Podpunkt A</u>
W pliku <i>wynik4a.txt</i> podaj, ile haseł ma parzystą, a ile nieparzystą liczbę znaków.

<br/>
<Dropdown title="ODPOWIEDŹ">

```text
105 haseł ma parzystą liczbę znaków, a 95 – nieparzystą. 
```
</Dropdown>
<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
with open("dane.txt", "r") as f:
    lines = f.readlines()

for line in lines:
    line = line.strip()
    if line[::-1] == line:
        print(line)
```
</Dropdown>

<br/><br/>

### <u>Podpunkt B</u>
W pliku <i>wynik4b.txt</i> utwórz zestawienie haseł (po jednym w wierszu), które są
palindromami.<br/>
<u>Palindrom</u> to wyraz brzmiący tak samo przy czytaniu z lewej strony do prawej, jak
i odwrotnie, np. kajak, potop.

<br/><br/>
<Dropdown title="ODPOWIEDŹ">

```text
dompmod
grafarg
kajak
komok
matam
mpoopm
mpouiuopm
oddo
omo
pokop
plkjjklp
pokkop
plolp
```
</Dropdown>
<br/><br/>

### <u>Podpunkt C</u>
Zapisz w pliku <i>wynik4c.txt</i> zestawienie haseł (po jednym w wierszu) zawierających
w sobie dwa kolejne znaki, których suma kodów ASCII wynosi 220.<br/>
<b>Przykłady: </b>
Hasło krzysio zawiera dwa kolejne znaki si, których suma kodów ASCII wynosi 220. Kod
ASCII znaku s to 115, kod znaku i to 105; suma kodów wynosi 115+105 = 220.<br/>
Hasło cyrk zawiera również takie dwa kolejne znaki. Kod ASCII znaku c to 99, kod ASCII
znaku y to 121; suma kodów wynosi 99+121=220 
<br/><br/>

![](https://i.imgur.com/5PvAfTe.png)

<br/>
<u>Uwaga:</u> Kolejność haseł w plikach <i>wynik4b.txt</i>, <i>wynik4c.txt</i> powinna być zgodna
z kolejnością ich występowania w pliku <i>hasla.txt</i>. 


<br/>
<Dropdown title="ODPOWIEDŹ">

```text
amodda
damod
damodd
dompmod
edamo
edamod
isksad
iughd
kisksa
kkompo
komok
kompiel
kompo
kompoc
kompok
kompoot
kompooto
kompost
kompot
komput
komu
komunikat
moddam
nruiugh
nruiughd
okisks
okkomp
omnibus
omo
ompioroip
ompoci
ompokk
ompooto
ompootoo
ruiughd
ruiughdf
sokisk
sunruiug
ughdf
ughdfbk
uiughdf
uiughdfb
unruiug
unruiugh
zedamo
plkjjklp
mops
polewa
komputer
komputerek
kolomp
plomp
plolp
komput
```
</Dropdown>



