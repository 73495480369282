import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2009/informatyka/PP_II.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2009/informatyka/Dane_pp.rar"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ (0-8pkt.)
Liczba pierwsza to liczba naturalna większa od 1, która ma dokładnie dwa dzielniki naturalne:
1 i samą siebie. <br/>
Przykłady liczb pierwszych:

```text
7
11
29
```
<br/>
Liczba 21 nie jest liczbą pierwszą, ponieważ oprócz liczby 1 i 21 jej dzielnikami są także
3 i 7.<br/>
W pliku o nazwie <i>liczby.txt</i> umieszczono w kolejnych wierszach 500 liczb całkowitych
dodatnich, po jednej w wierszu, z których każda liczba ma co najwyżej 6 cyfr. <br/><br/>
<b>Napisz program</b>, za pomocą którego otrzymasz tylko te liczby z pliku <i>liczby.txt</i>, które są 
<b> kwadratami liczb pierwszych</b>. Na przykład liczba 49 jest kwadratem liczby pierwszej – 
49 = 7 <sup>2</sup> . Wyniki zapisz w pliku <i>zad_5.txt</i>. Twój program powinien działać poprawnie
również wtedy, gdy plik <i>liczby.txt</i> będzie zawierał 500 innych liczb całkowitych
dodatnich, o co najwyżej 6 cyfrach, każda liczba w osobnym wierszu.

<br/>
<br/>
<Dropdown title="ODPOWIEDŹ">

```text
5041 
1369 
32041 
844561 
4 
96721 
9 
942841 
49 
1849 
528529 
121 
961 
169 
```
</Dropdown>

<Dropdown title ="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
with open("mecz.txt", "r") as f:
    line = f.readline()
    # jezeli rozgrywke wygrala inna druzyna niz poprzednio to mamy dwie rozne literki po sobie
    # jako ze w grze braly udzial tylko dwie druzyny wystarczy policzyc ile razy wystapil ciag "AB" a ile razy "BA"
    print(line.count("AB") + line.count("BA"))
```
</Dropdown>



