.smooth_scroll {
    scroll-behavior: smooth;
}

.main_page {
    max-width: 85em;
    margin: 0 auto;
    width: 75%;
}

.section {
    padding-top: 20px;
}

.section .header {
    padding-top: 45px;
    font-family: 'Chakra Petch', sans-serif;
    font-size: 50px;
    letter-spacing: 45px;
    text-transform: uppercase;
    line-height: 0px;
    color: var(--main-accent);
    text-align: center;

    /* letter-spacing robi spacje za ostatnim znakiem - rozwiązanie problemu */
    text-indent: 45px;
}

.section .header h1 {
    text-align: center;
}

.section article {
    /* font-family: 'IBM Plex Serif', serif; */
    font-family: 'Roboto', serif;
    font-size: 16px;
    line-height: 20px;
    /* font-weight: 00; */
    background-color: var(--primary3);
    color: var(--font);
    padding: 60px 30px 20px;
    /* margin: 0 5vw; */
    margin: 0;
    text-align: center;
    letter-spacing: 0.1px;
    border-radius: 25px;
    box-sizing: border-box;
}

.main {
    min-height: 80vh;
    text-align: center;
    display: grid;
    grid-template-columns: 16vw 1fr;
    gap: 4vw;
    padding: 35px 25px 4vw;
}



/* SIDEBAR */

.main .contents .cont {
    display: flex;
    position: sticky;
    top: 60px;
    flex-direction: column;
}

/* 
.main .contents .cont .menu_button:first-child{
    margin-top: 0px;
} */

/* 
.main .contents .cont .menu_button:first-child{
    margin-top: 0px;
} */

.main article {
    font-family: 'Roboto', serif;
    font-size: 16px;
    font-weight: 300;
    background-color: var(--primary3);
    color: var(--font);
    padding: 20px 30px;
    margin-bottom: 2vw;
    border-width: 2px;
    border-style: solid;
    text-align: left;
    line-height: 20px;
}

.acknowledgements {
    width: 85%;
    max-width: 85em;
    margin: 0 auto;
    text-align: center;
}

.acknowledgements article {
    font-family: 'Roboto', serif;
    font-size: 16px;
    font-weight: 300;
    /* background-color: var(--primary3); */
    /* color: var(--font); */
    background-color: var(--card-background);
    color: var(--font);
    box-shadow: var(--card-shadow) 0px 2px 8px 0px;
    padding: 20px 30px;
    margin-bottom: 2vw;
    /* border-width: 2px; */
    /* border-style: solid; */
    text-align: left;
    line-height: 20px;
}

.main article:last-child {
    margin-bottom: 0;
}

.acknowledgements article h1 {
    font-size: 25px;
    font-weight: 500;
    font-family: 'Chakra Petch', sans-serif;
    text-align: center;
    padding: 5% 0;
}

.main article h1,
.acknowledgements article h1 {
    font-family: 'Chakra Petch', sans-serif;
    text-transform: uppercase;
    color: var(--main-accent);
    padding-bottom: 6px;
    padding-top: 80px;
    margin-top: -80px;
}

.main article {
    text-justify: inter-word;
    /* margin: 2vw 0; */
    border-width: 2px;
    border-color: var(--article-border);
    border-radius: 1px;
}

.acknowledgements article h1 {
    letter-spacing: 3px;
}

.legenda {
    display: flex;
    justify-content: center;
    align-items: center;
}

.legenda_img {
    max-width: 80px;
    filter: var(--screen_filter);
}


.statistic_img {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    display: block;
    filter: var(--screen_filter);
}

.authors {
    /* padding-top: 40px; */
    line-height: normal;
    margin: 0 auto;
    padding-bottom: 20px;
    padding-top: 70px;
    margin-top: -70px;
    max-width: 85em;
    width: 85%;
}

.authors_title {
    font-family: 'Chakra Petch', sans-serif;
    font-size: 50px;
    letter-spacing: 30px;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--title);
    text-align: center;
    padding: 25px 0;
}

.authors_details_wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(auto, 1fr));
    grid-gap: 20px;
    justify-content: space-between;
    justify-items: center;
    align-content: center;
}

.author {
    flex-direction: column;
    background-color: var(--card-background);
    color: var(--font);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--card-shadow) 0px 2px 8px 0px;
    padding: 1.5em;
}

.author_name {
    letter-spacing: 3px;
    font-size: 25px;
    font-weight: 500;
    font-family: 'Chakra Petch', sans-serif;
    text-align: center;
    padding: 5% 0;
    display: table;
}

.author_name a svg {
    height: 1.2em;
    width: 1.2em;
    margin-left: 5px;
}


.photo img {
    width: 15vw;
    height: 15vw;
    border-radius: 90%;
}

.introduction {
    text-align: justify;
    font-size: 15px;
    font-family: "Roboto", serif;
    font-weight: 300;
    height: 50%;
}


.author_name a {
    color: var(--font);
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

@media (max-width: 1200px) {

    .main_page {
        width: 75%;
    }

    .section {
        padding-top: 40px;
    }

    .section .header {
        padding-top: 15px;
        font-size: 50px;
        letter-spacing: 45px;
        text-indent: 45px;
    }

    .section article {
        font-size: 15px;
        padding: 50px 30px 20px;
        /* margin: 0 4vw; */
        line-height: 17px;
    }

    .main .contents .cont a {
        font-size: 15px;
    }

    .main {
        grid-template-columns: 18vw 1fr;
        gap: 2vw;
    }

    .main article {
        font-size: 15px;
        padding: 15px 25px;
        margin-bottom: 2vw;
        line-height: 18px;
    }

    .acknowledgements article {
        font-size: 15px;
        padding: 15px 25px;
        line-height: 18px;
    }

    .introduction {
        text-align: left;
    }

    .authors,
    .acknowledgements {
        width: 90%;
    }

    .author {
        padding: 1em;
    }
}

@media(max-width:1000px) {
    .section .header {
        padding-top: 5px;
        font-size: 45px;
        letter-spacing: 40px;
        text-indent: 40px;
    }

    .main {
        padding-top: 20px;
        display: block;
    }

    .main .contents {
        display: none;
    }

    .authors_details_wrapper {
        grid-template-columns: none;
        grid-template-rows: repeat(3, 1fr);
        justify-content: center;
        grid-gap: 2em;
        /* width: 60%; */
        margin: 0 auto;
    }

    .authors {
        padding-bottom: 0;
        width: 60%;
    }

    .author {
        /* width: 60%; */
        /* padding: 1em; */
    }

    .acknowledgements {
        width: 60%;
    }

    .authors_title {
        letter-spacing: 20px;
        font-size: 30px;
    }

    .authors_details_wrapper div:first-child {
        margin-top: 1em;
    }

    .authors_details_wrapper div:last-child {
        margin-bottom: 2em;
    }

    .author_name {
        font-size: 19px;
        padding: 2% 0;
    }

    .author_name a svg {
        height: 1em;
        width: 1em;
    }

    .introduction {
        font-size: 15px;
        word-break: break-word;
    }
}

@media (max-width:810px) {
    .main_page {
        width: 85%;
    }

    .main article,
    .acknowledgements article {
        font-size: 13px;
    }

    .section .header {
        font-size: 30px;
        letter-spacing: 25px;
        text-indent: 25px;
    }

    .section article {
        font-size: 13px;
        padding: 30px 30px 20px;
        line-height: 16px;
    }

    .main_content article {
        line-height: 15px;
    }

    .legenda_img {
        max-width: 60px;
    }

    .author {
        box-sizing: content-box;
    }

    .authors_title {
        padding: .6em 0;
    }

    .authors_details_wrapper {
        grid-gap: 1.5em;
    }

    .authors_details_wrapper div:first-child {
        margin-top: .5em;
    }

    .authors_details_wrapper div:last-child {
        margin-bottom: .5em;
    }

}

@media (max-width: 600px) {
    .main_page {
        width: 95%;
    }

    .section .header {
        padding-top: 0px;
        font-size: 25px;
        letter-spacing: 10px;
        text-indent: 5px;
    }

    .section article {
        padding: 25px 20px 15px;
        line-height: 17px;
        text-align: left;
    }

    .main {
        padding-left: 0px;
        padding-right: 0px;
    }

    .main article,
    .acknowledgements article {
        font-size: 14px;
        padding: 10px 15px;
        border-width: 2px;
    }

    .main article h1 {
        text-align: center;
    }

    .legenda_img {
        max-width: 45px;
    }

    .authors,
    .acknowledgements {
        width: 95%;
    }

    .authors_title {
        letter-spacing: 8px;
        font-size: 25px;
    }

    .authors_title {
        padding: .5em 0;
    }

    .authors_details_wrapper {
        grid-gap: 1em;
    }

    .author_name {
        padding: .1em;
    }

    .author .photo {
        margin-top: 0;
    }

    .introduction {
        font-size: 14px;
    }
}

@media(max-width:400px) {
    .section .header {
        font-size: 18px;
    }

    .section article {
        font-size: 11px;
        line-height: 14px;
        padding: 20px 20px 15px;
    }

    .main article,
    .acknowledgements article {
        font-size: 11px;
        line-height: 14px;
        padding: 8px 10px;
        border-width: 2px;
        text-align: left;
    }

    .main article h1,
    .acknowledgements article h1 {
        font-size: 16px;
    }

    .legenda_img {
        max-width: 35px;
    }

    .author_name {
        font-size: 15px;
    }

    .author_name a svg {
        height: 0.8em;
        width: 0.8em;
    }

    .introduction {
        text-align: left;
        font-size: 11px;
    }

    .authors_title {
        letter-spacing: 6px;
        font-size: 20px;
    }
}