import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2020/formula_od_2015/informatyka/MIN-R2_1P-202.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2020/formula_od_2015/informatyka/Dane_PR2.zip">PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ
W pliku <i>pary.txt</i> znajduje się 100 wierszy. Każdy wiersz zawiera parę danych składającą
się z liczby całkowitej z przedziału od 3 do 100 i słowa (ciągu znaków) złożonego z małych
liter alfabetu angielskiego o długości od 1 do 50 znaków. Liczba i słowo są oddzielone znakiem
spacji.<br/>
Napisz <span className="bold">program(-my)</span>, dający(-e) odpowiedzi do poniższych zadań. Uzyskane odpowiedzi
zapisz w pliku <i>wyniki4.txt</i>, poprzedzając każdą z nich numerem odpowiedniego zadania.<br/><br/>
<span className="bold">Uwaga</span>: plik <i>przyklad.txt</i> zawiera przykładowe dane spełniające warunki zadania.
Odpowiedzi dla danych z pliku <i>przyklad.txt</i> są podane pod treściami zadań oraz w pliku
<i>odp_przyklad.txt</i>. 

<br/><br/>

### <u>Zadanie 3</u>   (0-4pkt.)
Para (liczba1, słowo1) jest <span className="bold">mniejsza</span> od pary (liczba2, słowo2), gdy:<br/>
– liczba1 < liczba2,<br/>
albo<br/>
– liczba1 = liczba2 oraz słowo1 jest leksykograficznie (w porządku alfabetycznym)
mniejsze od słowo2.<br/><br/>

#### Przykład:
para (1, bbbb) jest mniejsza od pary (2, aaa), natomiast para (3, aaa) jest mniejsza od pary
(3, ab).<br/><br/>

Rozważ wszystkie pary (liczba, słowo) zapisane w wierszach pliku <i>pary.txt</i>, dla których
<span className="bold">liczba jest równa długości słowa</span>, i wypisz spośród nich taką parę, która jest mniejsza od
wszystkich pozostałych. W pliku <i>pary.txt</i> jest jedna taka para.<br/><br/>

Dla danych z pliku <i>przyklad.txt</i> odpowiedzią jest:
6 abbbbc

<br/><br/>

<Dropdown title="ODPOWIEDŹ">

```text
3 asd
```

</Dropdown>

<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def get_words() -> list[int]:

    with open(r"pary.txt") as text_file:
        lines = text_file.read().strip().split("\n")

    numbers = []

    for line in lines:
        numbers.append([int(line.split()[0]), line.split()[1]])

    return numbers


def main():
    pairs = get_words()

    pars = list(filter(lambda x: x[0] == len(x[1]), pairs))

    result = min(pars, key=lambda x: (x[0], x[1]))

    print(*result)


if __name__ == "__main__":
    main()

```
</Dropdown>
