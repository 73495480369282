import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2012/maj/infor/a2_pr.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2012/maj/infor/Dane_PR.rar"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ (0-10pkt.)
Rozważmy szyfr podstawieniowy działający zgodnie z następującymi zasadami:<br/>
- Tekst jawny, szyfrogram oraz klucz składają się wyłącznie z wielkich liter alfabetu
angielskiego.<br/>
- Litery ponumerowano i przyporządkowano im kody ASCII (liczby z zakresu 65–90): <br/>
<br/>

![](https://i.imgur.com/Y8XJaVB.png)

<br/>

- Kolejne litery tekstu jawnego są szyfrowane za pomocą kolejnych liter słowa będącego
kluczem, być może powtórzonego wiele razy.<br/>
- W procesie szyfrowania tekst jawny przekształcany jest na szyfrogram przy pomocy
klucza poprzez dodanie do <b>kodu</b> litery tekstu jawnego <b>numeru</b> odpowiadającej jej litery
klucza. Jeżeli tak uzyskana wartość liczbowa będzie większa od 90, należy ją zmniejszyć
o 26. Szyfrem danej litery jest litera o tak uzyskanym kodzie. Poniższy przykład precyzuje
zasady szyfrowania.<br/>
<b>Przykład: </b>
Tekst jawny: LATO, klucz: WODA<br/>
L+W = 76+23 = 99. Ponieważ przekroczono zakres 90, należy od 99 odjąć 26, czyli
99–26 = 73. Zatem zaszyfrowanym znakiem jest litera I.<br/>
A+O = 65+15 = 80, czyli zaszyfrowanym znakiem jest litera P.<br/>
T+D = 84+4 = 88, czyli zaszyfrowanym znakiem jest litera X.<br/>
O+A = 79+1 = 80, czyli zaszyfrowanym znakiem jest litera P.<br/>
Szyfrogram: IPXP<br/>
- Jeżeli użyte słowo kluczowe jest zbyt krótkie, by wystarczyło do zaszyfrowania całego
tekstu, należy użyć jego powtórzeń. <br/>
<b>Przykład: </b> 
Tekst jawny: MARTA, klucz: TOR<br/>
M+T = 77+20 = 97, 97-26=71, G<br/>
A+O = 65+15 =80, P<br/>
R+R = 82+18= 100, 100-26 = 74, J<br/>
T+T = 84+20 = 104, 104-26=78, N<br/>
A+O = 65+15 =80, P<br/>
Szyfrogram: GPJNP<br/>
- W procesie deszyfrowania szyfrogram przekształcany jest na tekst jawny przy pomocy
klucza poprzez odjęcie od kodu litery szyfrogramu numeru odpowiadającej jej litery
klucza (jeżeli tak uzyskana wartość liczbowa będzie mniejsza od 65, należy ją powiększyć
o 26) i odczytanie litery o otrzymanym kodzie.<br/>

Korzystając z dostępnych narzędzi informatycznych, wykonaj poniższe polecenia.

<br/><br/>

### <u>Podpunkt A</u>   
W pliku <i>tj.txt</i> znajdują się niezaszyfrowane słowa, a w pliku <i>klucze1.txt</i> –
klucze szyfrujące. W obu plikach wyrazy umieszczone są w osobnych wierszach.
Zaszyfruj słowa zawarte w pliku <i>tj.txt</i>, wynik zapisz w pliku <i>wynik4a.txt</i>. Wyraz
zapisany w N-tym wierszu w pliku z wynikami powinien stanowić szyfrogram tekstu
jawnego znajdującego się w N-tym wierszu w pliku z tekstem jawnym uzyskany
za pomocą klucza znajdującego się w N-tym wierszu pliku z kluczami.
<br/>
<Dropdown title="ODPOWIEDŹ"> 

```text
CNA
UUU
WNS
TRX
WCCG
YOYG
XEUUAU
WVBY
QIE
FYDBZ
YQRQXDMHHAOS
RKXETREBHGFBW
WYAUKDWWGTI
LWTZMEPL
MLWGODX
GEWPZWCR
XGZMRSAD
GZCPVLHDUF
GDDULBERQA
APEUDKNPMO
GMFCJJFYTMH
PPPKMWIRAU
LFXXPRJZL
OYYZCKVUY
XKMQNSG
NZSTFEGHVCE
ONRVWOVW
MMMICLXVQ
MRTCGISKFY
SNYNSHBP
EHDABER
KYPGTMDUF
NIYBOQGJ
EQQRIDJL
ADWPDRQZL
QAYMTFYSM
QJJUCQO
OPPICXNLP
XJFHQBKBSX
KHZVSSXRD
SFNBNAQSKV
MPEYAOA
MWLPBGPF
LNPFSMBNPPDOR
APPSHCYCU
TUNMIVNB
PFRNMUYDHOY
WKNLJPTWNHURN
QDYLEQFMTUHNN
GYIGEBTKTDVD
VHARCCKPDFYLQ
WSQILBTJJD
TDLKXPEGAN
GZIHOZMQTAXI
EZJTBGIQ
XJZSJSVXPM
AWNJXLOMVNNX
WFDCGBEAGSRGL
ZKJSFPSRNYGJ
YEVHEITYIZOU
ELZBZOYOL
CSQRXMORC
SKFOMNFAHLEQXD
NQJFXSUAGEZ
WBVYTBVU
SFDKNNMPJIBAOB
AFOJNJXXEL
DUHSRGIHLPJHG
DRLIPMGSV
OYYSFWYSHZ
NKYCOKJDG
FAVAJFNXEZB
BUUFZWJQJORHSK
GTGPMTYPMBZN
IAYGSDHKFNP
TAIUQSODRLIE
GOWCZFXJFENJQ
VPIDTXAOCCOB
CPJPTGEBMZ
JHUYVFCEASE
OHCOZZWENH
PEOEGGZLBYJPNVT
JJJOUTTRCCXPVJ
IFPIGOAAXE
MFRAMXPFEJY
ARJHVBEJUJL
SIDIAXMPVS
IPGBQRUJU
XRTXMFKSFBOT
LNBUIAPOB
MPKRROJMCUMXFLVYN
PCVWLKZPUIP
GFOQXCWFMKB
TVJZKBVO
MWWCLSCIE
TPJZIKVSBZEDPRCZC
EDNXUYBVYNKNN
WVZSFSUHBNN
YYFUHXAIZL
DHHABOENDL
TBLHVADDPHC
WJYSAEUAYCG
DKMXUKTNAQRC
BVKIQVFSHETL
SHSMRDFUGD
COVYNVPDX
FTKOHQIFNNU
XQMBOHIPYDAMO
IRGRCVPIBFUEWZYQR
YSQWBXFQZRCB
DLEDES
GYEAF
IELOZ
VJKYTW
NKQ
OXP
ALX
ZQHR
NSOZ
MDO
```
</Dropdown>

<br/><br/>

### <u>Podpunkt B</u>
W pliku <i>sz.txt</i> znajdują się zaszyfrowane słowa, a w pliku <i>klucze2.txt</i> znajdują
się klucze deszyfrujące. W obu plikach wyrazy umieszczone są w osobnych wierszach.
Odszyfruj słowa zawarte w pliku <i>sz.txt</i>, wynik zapisz do pliku <i>wynik4b.txt</i>.
Wyraz zapisany w N-tym wierszu w pliku z wynikami powinien stanowić tekst jawny
szyfrogramu znajdującego się w N-tym wierszu w pliku z szyfrogramami uzyskany
za pomocą klucza zapisanego w N-tym wierszu pliku z kluczami. 

<br/>
<Dropdown title="ODPOWIEDŹ">

```text
KOT
PIES
COS
NIC
NUTA
BUT
LUPA
ZUPA
KWIAT
CEL
KONWERTEREM
ZAAKCEPTOWANIA
KOLOROWANIE
PIERWOTNE
KLASYCZNY
STOPOWYCH
PNEUMATYCZNE
PODSTAWOWYCH
ROZPOZNAJE
POCHODZENIA
WYSZUKIWANIE
PRZEKAZYWANY
PRZYDATNE
AUTOMATYCZNE
PRZYGOTOWYWANIU
PRZERZUTKA
ROZPOZNAWANIE
PROGRAMU
ZAPISYWANE
DOPASOWYWANIE
REJESTRACJI
TURBULENCJE
KODOWANIA
ZINTEGROWANE
NAJPOPULARNIEJSZE
MECHANICZNE
HYDRAULICZNE
PRZEDNIEGO
ZASTOSOWANIE
TEKSTOWYCH
NATURALNEJ
WYSZUKIWANIU
KOMPUTERACH
JEDNOCZESNYM
KONFIGUROWALNY
KOMENTARZACH
ZACHOWANIEM
ZAOPATRZONY
ROZPISANEGO
SAMOCHODU
RZECZYWISTY
ELEKTRYCZNY
OBUDOWIE
GUBERNATOR
KONWERTUJE
ZAAWANSOWANYCH
KONSTRUKTORA
POWIERZCHNIOWYCH
ZLIKWIDOWANIE
HAMOWANIA
PUNKTOWYM
SZKIELETEM
SAMOLOTU
POPULARNYCH
CESARZOWI
PROGRAMOWANIA
UDZIELANIE
TABULACJI
POMOCNICZYCH
KOMPONENTY
STANDARDY
REGULARNYCH
KLAWISZOWY
UPRAWNIENIAMI
DOWOLNYCH
WSTAWIANIA
USTAWIENIA
NAZWISKU
NIEZNACZNIE
ORGANIZACJI
POZOSTAJE
DODATKOWYCH
ZAPEWNIENIE
ZAKTUALIZOWANO
ZAZWYCZAJ
LECZNICZEJ
STOSOWANIA
TOWARZYSZY
PODOBNYM
ZAJMOWANEJ
ZASTOSOWANIA
POKRYWANIA
SYSTEMOWEGO
BIODROWYM
WSZYSTKICH
POWIERZCHNI
DEKORACYJNEGO
ORGANIZACJA
POTRANATEM
ZAMIENNIKIEM
WIZYJNE
NAJLEPIEJ
DOCELOWYM
KONFRONTACJI
ORGANIZOWANE
OCHRONNEGO
INSTALACJI
DOSTOSOWANIE
NINIEJSZEJ
WSZYSTKIE
KREM
KTOS
ZNAC
ALBO
RYBA
LUB
MUS
PLUS
KROWA
OWCA
```
</Dropdown>

<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def kod_litery(litera):
    return ord(litera) - ord('A') + 1

def zaszyfruj(slowo, klucz):
    zaszyfrowane = ""
    for i in range(len(slowo)):
        kod = ord(slowo[i]) + kod_litery(klucz[i % len(klucz)])
        if kod > 90:
            kod -= 26
        zaszyfrowane += chr(kod)
    return zaszyfrowane

def podpunkt_a(tj, klucze1):
    with open('wynik4a.txt', 'w') as f:
        for (slowo, klucz) in zip(tj, klucze1):
            print(zaszyfruj(slowo, klucz), file=f)

def odszyfruj(slowo, klucz):
    odszyfrowane = ""
    for i in range(len(slowo)):
        kod = ord(slowo[i]) - kod_litery(klucz[i % len(klucz)])
        if kod < 65:
            kod += 26
        odszyfrowane += chr(kod)
    return odszyfrowane

def podpunkt_b(sz, klucze2):
    with open('wynik4b.txt', 'w') as f:
        for (slowo, klucz) in zip(tj, klucze1):
            print(odszyfruj(slowo, klucz), file=f)

def wczytaj_dane():
    with open('tj.txt', 'r') as plik:
        tj = [ a.strip() for a in plik.readlines()]

    with open('klucze1.txt', 'r') as plik:
        klucze1 = [ a.strip() for a in plik.readlines()]

    with open('sz.txt', 'r') as plik:
        sz = [ a.strip() for a in plik.readlines()]

    with open('klucze2.txt', 'r') as plik:
        klucze2 = [ a.strip() for a in plik.readlines()]

    return (tj, klucze1, sz, klucze2)

tj, klucze1, sz, klucze2 = wczytaj_dane()
podpunkt_a(tj, klucze1)
podpunkt_b(sz, klucze2)
```

</Dropdown>



