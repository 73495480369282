## Słowo wstępu do tematów "Modelowanie", "Tworzenie zapytań" i "Zaawansowane funkcje".
Ponieważ wszystkie podstawowe zagadnienia znacznie łatwiej jest wytłumaczyć, a przede wszystkim zrozumieć na konkretnych przykładach, to weźmiemy na tapet **maturę z maja 2018 roku**.
Omówimy wszystkie zagadnienia od podstaw i jednocześnie rozwiążemy ten egzamin krok po kroku.
Arkusz oraz potrzebne pliki znajdziecie na stronie Centralnej Komisji Egzaminacyjnej.

<br/>
<a className={`link link_materials`} target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2018/formula_od_2015/informatyka/MIN-R2_1P-182.pdf">ARKUSZ</a>
<br/>
<a className={`link link_materials`} href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2018/formula_od_2015/informatyka/Dane_PR2.zip">DANE DO ZADANIA</a>
<br/>
<a className={`link link_materials`} target="_blank" href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2018/formula_od_2015/Zasady_oceniania/MIN-R2_1P-182_zasady_oceniania.pdf">WZÓR OCENIANIA</a>