import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2022-czerwiec-matura-rozszerzona-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2022-czerwiec-matura-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ
W pliku <i>liczby.txt</i> zapisano 100 nieparzystych liczb całkowitych z przedziału [10, 9999].
Liczby w pliku mogą się powtarzać.<br/>
<b>Odbiciem</b> dodatniej nieparzystej liczby całkowitej n nazywamy taką liczbę N, w której zapisie
dziesiętnym nastąpiło odwrócenie kolejności cyfr.<br/><br/>
<b>Przykład:<br/></b>
Odbiciem liczby 2019 jest 9102, natomiast odbiciem liczby 12345 jest 54321.<br/>
<b>Napisz program</b> (lub kilka programów), który(-e) znajdzie(-dą) odpowiedzi na poniższe
pytania. Każdą odpowiedź zapisz w pliku <i>wyniki4.txt</i> i poprzedź ją numerem
oznaczającym zadanie.<br/><br/>
Do dyspozycji masz również plik <i>przyklad.txt</i> zawierający tylko 11 nieparzystych liczb
całkowitych z przedziału [10, 9999] – odpowiedzi dla tego pliku podane są w treściach
zadań, możesz sprawdzać na nim działanie swojego programu.<br/><br/>
<b>Uwaga:<br/></b> Pamiętaj, że Twój program (lub kilka programów) musi(-szą) ostatecznie działać dla
100 liczb zapisanych w pliku <i>liczby.txt</i>.

<br/><br/>

### Zadanie 4 (3pkt)

Podaj:<br/>
- ile różnych liczb zapisano w pliku <i>liczby.txt</i>
- ile liczb powtarza się dokładnie dwa razy w pliku <i>liczby.txt</i>
- ile liczb powtarza się dokładnie trzy razy w pliku <i>liczby.txt</i>.<br/><br/>
Dla pliku <i>przyklad.txt</i> odpowiedzią jest 10 1 0.

<br/><br/>
<Dropdown title="ODPOWIEDŹ">

```text
85 (różnych)
13 (powtarzających się 2 razy)
1 (powtarzająca się 3 razy)
```
</Dropdown>






<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
from collections import Counter


def main():
    with open(r"liczby.txt") as text_file:
        numbers = text_file.read().strip().split()

    counter = Counter(numbers)

    print("roznych liczby: {}, liczb powtarzajace sie 2 razy: {}, liczby powtarzajace się 3 razy: {}".format(
        len(set(numbers)),
        len(list(filter(lambda x: x[1] == 2, counter.items()))),
        len(list(filter(lambda x: x[1] == 3, counter.items())))
    ))


if __name__ == "__main__":
    main()

```
</Dropdown>
