<br/>

Dalej będziemy bazować na danych z matury z maja 2018 roku. Tym razem jednak zajmiemy się zadaniem wymyślonym na potrzeby zilustrowania użycia kwerend wyszukujących duplikaty.
<br/>

**Zadanie:** Utwórz zestawienie zawierające komputery, które kilkukrotnie (więcej niż raz) uległy awarii o tym samym priorytecie. Wynik powinien zawierać 'Numer_komputera' oraz 'Priorytet' tychże awarii.

### 1. Wybieramy "Tworzenie" -> "Kreator kwerend".

### 2. Wybieramy "Kreator kwerend wyszukujących duplikaty".
![](https://i.imgur.com/dGz7m2r.png)

### 3. Definiujemy, w jakiej tabeli chcemy wyszukać duplikaty.
<img src={new URL('https://i.imgur.com/UoD9dvu.png', import.meta.url)} alt="xyz" className={'medium_img'} />
W naszym przykładzie będzie to tabela 'Awarie'.

### 4. Wybieramy pola, które mają zawierać zduplikowane dane.
Możemy to zrobić poprzez dwukrotne kliknięcie wybranego pola lub poprzez zaznaczenie go jednokrotnym kliknięciem i użycie strzałki ">". Analogicznie strzałka "<" może posłużyć nam do wycofania pola.
<img src={new URL('https://i.imgur.com/iZf87Zk.png', import.meta.url)} alt="xyz" className={'medium_img'} />

Do zestawienia z zadania potrzebujemy informacji o 'Numerze_komputera' i 'Priorytecie' awarii.

### 5. Ustalamy, czy (i jeśli tak to jakie) dodatkowe pola będą potrzebne.
Możemy je dodawać w taki sam sposób jak w punkcie 4.
<img src={new URL('https://i.imgur.com/ftSkLyU.png', import.meta.url)} alt="xyz" className={'medium_img'} />

Nam nie będą potrzebne żadne dodatkowe pola.

### 6. Gotowe!
![](https://i.imgur.com/L2bprt8.png)
Wystarczy zapisać kwerendę.