import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2019-czerwiec-matura-rozszerzona-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2019-czerwiec-matura-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ
Liczby pierwsze to liczby naturalne większe od 1, które mają dokładnie dwa dzielniki: jedynkę
i samą siebie.<br/>
Dane są dwa pliki: <i>liczby.txt</i> i <i>pierwsze.txt</i>. Plik <i>liczby.txt</i> zawiera 300
wierszy. W każdym wierszu tego pliku znajduje się jedna liczba całkowita dodatnia z zakresu
od 1 do 100 000.<br/>
Plik <i>pierwsze.txt</i> zawiera 200 wierszy. W każdym wierszu tego pliku znajduje się jedna
liczba pierwsza z zakresu od 10 do 1 300 000.<br/><br/>

<b>Uwaga:</b> pomocnicze pliki <i>liczby_przyklad.txt</i> i <i>pierwsze_przyklad.txt</i>,
zawierają dane, które możesz wykorzystać, aby sprawdzić poprawność działania swojego(-ich)
programu(-ów). Każdy z nich zawiera po 50 wierszy. W każdym wierszu znajduje się jedna
liczba. Odpowiedzi dla danych z tych plików są podane pod treściami zadań.<br/><br/>

<b>Napisz program(-y)</b>, w wyniku działania którego(-ych) otrzymasz odpowiedzi do poniższych
zadań. Pliki źródłowe z rozwiązaniem zapisz pod nazwą zgodną z numerem zadania,
z rozszerzeniem odpowiadającym użytemu językowi programowania. 
<br/><br/>

### <u>Zadanie 2</u> (4pkt)
Podaj, w kolejności ich występowania w pliku <i>pierwsze.txt</i>, wszystkie te liczby, które
czytane od prawej do lewej również są liczbami pierwszymi. Odpowiedź zapisz w pliku
<i>wyniki4_2.txt</i>.<br/><br/>
<b>Przykład:<br/></b>
Jeśli odczytamy liczbę pierwszą 17 od prawej do lewej, otrzymamy liczbę 71, która również
jest liczbą pierwszą.<br/>
Dla pliku <i>pierwsze_przyklad.txt</i> liczbami spełniającymi warunek zadania są: 701,
709, 1033, 167, 1109, 1619, 1009, 179, 1499, 76001, 1601, 31873


<br/><br/>

<Dropdown title="ODPOWIEDŹ">

```text
31
37
101
1009
1021
113
1499
1213
1217
1229
1231
1237
1487
1223
7027
7043
3821
31873
1511
140527
151169
151189
193261
1297001
1061
100267
100271
100279
181957
13
112163
112181
3803
76001
160183
160201
160243
17
907
701
383
389
1103
1109
31721
1583
1597
1601
1619
3571
112111 
```
</Dropdown>






<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def is_valid(number: int) -> bool:
    for divider in range(2, number):
        if number % divider:
            continue

        return False

    return True


def main():
    with open(r"pierwsze.txt") as text_file:
        numbers = [int(line[::-1]) for line in text_file.read().strip().split()]

    print(*list(map(lambda x: str(x)[::-1], list(filter(is_valid, numbers)))), sep="\n")


if __name__ == "__main__":
    main()

```
</Dropdown>
