import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2022-grudzien-probna-rozszerzona.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2022-grudzien-probna-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ
Dawno temu, w odległej galaktyce, rozegrano mecz w grę, która przypominała siatkówkę.
W meczu wystąpiły dwie drużyny: drużyna A i drużyna B. Mecz składał się z 10 000 krótkich
rozgrywek. Każda rozgrywka kończyła się wygraną jednej z dwóch drużyn, za którą
zwycięska drużyna otrzymywała jeden punkt.
Plik <i>mecz.txt</i> zawiera zapis wyników kolejnych rozgrywek – jeden wiersz z napisem
złożonym z 10 000 znaków A i B. Znak A oznacza, że rozgrywkę wygrała drużyna A,
natomiast znak B – że rozgrywkę wygrała drużyna B.
Napisz <b>program(-y)</b> który(-e) znajdzie(-dą) odpowiedzi do poniższych zadań. Odpowiedzi
zapisz w pliku <i>wyniki1.txt</i>, a każdą z nich poprzedź numerem odpowiedniego zadania.
Do dyspozycji masz plik <i>mecz_przyklad.txt</i>, spełniający warunki zadania – odpowiedzi
dla tego pliku podano w treściach zadań. Możesz sprawdzać na nim działanie swojego
programu.

<br/><br/>

### <u>Zadanie 2</u> (3pkt)
Pierwszy set w meczu trwa do pierwszej rozgrywki, po której któraś z drużyn ma co najmniej
1000 punktów za wygranie dotychczasowych rozgrywek, natomiast drużyna przeciwna ma
co najmniej 3 punkty mniej. Drużyna, która zdobywa w secie więcej punktów od przeciwnej,
wygrywa pierwszego seta.<br/>
<b>Przykład:<br/></b> pierwszy set może się zakończyć wynikami: 1000:500, 997:1000, 1500:1497.
Wyniki 900:100, 999:1000, 1500:1500 nie kończą seta.<br/>
Podaj, która drużyna wygrała pierwszego seta i jaki w tym momencie był wynik (liczba
zwycięskich rozgrywek drużyny A i liczba zwycięskich rozgrywek drużyny B w pierwszym
secie).<br/>
Dla pliku <i>mecz_przyklad.txt</i> odpowiedzią jest: A 1000:5

<br/>
<br/>
<Dropdown title="ODPOWIEDŹ">

```text
B 1001:1004
```
</Dropdown>






<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def get_game() -> str:
    with open(r"mecz.txt") as text_file:
        return text_file.read().strip("\n")


def there_is_winner(score: dict) -> bool:
    better_score = max(score.values())
    worse_score = min(score.values())

    if better_score < 1000:
        return False

    if better_score - worse_score >= 3:
        return True

    return False


def get_first_set_winner(game: str):

    team_scores = {"A": 0, "B": 0}

    for character in game:
        team_scores[character] += 1

        if there_is_winner(team_scores):
            # print(team_scores)

            winner = max(team_scores.items(), key=lambda x: x[1])

            print(winner[0], "{}:{}".format(*team_scores.values()))

            return


def main():
    get_first_set_winner(get_game())


if __name__ == '__main__':
    main()

```
</Dropdown>
