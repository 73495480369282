## Dlaczego warto wybrać Pythona na maturze?
Na maturze z informatyki możesz wybrać jeden z czterech następujących języków programowania: C/C++, Java, Pascal lub Python. W tej notce omówimy pokrótce każdy z tych języków i skupimy się na argumentach przemawiających za wyborem Pythona na egzaminie dojrzałości. 

![](https://i.imgur.com/HLMv2tA.png)
*Źródło: 
<a className={`link`} target="_blank" href="https://cke.gov.pl/images/_KOMUNIKATY/20220819%20EM%202023%20Komunikat%20o%20egzaminie%20z%20informatyki.pdf*"> https://cke.gov.pl/images/_KOMUNIKATY/20220819%20EM%202023%20Komunikat%20o%20egzaminie%20z%20informatyki.pdf* </a> 

Jeszcze jakiś czas temu C++ był językiem najczęściej uczonym w szkołach średnich i zwykle wybieranym na maturze. Jest on bardzo popularny wśród zawodowych programistów i jego znajomość może ułatwić nam w przyszłości znalezienie pracy. Uważa się jednak, że jest to trudny język, szczególnie dla początkujących. Wymaga opanowania dużej ilości materiału i usystematyzowania wiedzy ze znajomości działania komputerów. Jeśli planujemy wybrać studia informatyczne, jako dalszą ścieżkę rozwoju, to warto wziąć go pod uwagę, bo zdecydowanie może nam pomóc w byciu świadomymi programistami. <br/><br/>

Jeśli chodzi jednak o C++ pod względem matury, to może on sprawić nam wiele problemów. Musimy pamiętać o zapisywaniu typów przy deklaracji zmiennych, a operacje na tablicach i napisach są dość skomplikowane. W przypadku kiedy nasz program się nie kompiluje, możemy mieć problem z wyłapaniem miejsca, w którym zrobiliśmy błąd. W momencie, kiedy liczy się czas — a przecież zadania programistyczne, to tylko fragment całej matury z informatyki — to lepszym wyborem będzie język wysokopoziomowy, który jest bardziej zbliżony do ludzkiej mowy. <br/><br/>

Java nie jest złym wyborem, wydaje się, że jest językiem prostszym w obsłudze niż C++, ze względu na dużo wbudowanych bibliotek — nie musimy więc za każdym razem wynajdować koła na nowo, tylko możemy korzystać z gotowych rozwiązań. Jest on jednak rzadko nauczany w szkołach i istnieje zdecydowanie mniej dostępnych materiałów do nauki w porównaniu do poprzednio omawianych języków. <br/><br/>

Python jest językiem wysokopoziomowym, co znaczy, że jest bardziej zbliżony do ludzkiej mowy niż Java czy C++. Jest też dużo prostszy w nauce, jak i w obsłudze. Rozwiązując zadanie w Pythonie kontra w C++ czy w Javie, w Pythonie wykonamy je zdecydowanie szybciej — a przecież czas odgrywa kluczową rolę na maturze! Posiada on również mnóstwo wbudowanych funkcji, które zdecydowanie ułatwiają pisanie kodu. Wystarczy porównać wykonywanie operacji na napisach w Pythonie, a w C++, aby zauważyć, jak duża jest to różnica. <br/>
Składnia Pythona jest bardzo prosta i wymusza stosowanie wcięć, co bardzo pomaga w pisaniu "czystego" kodu. Dzięki temu stanie się on bardziej przejrzysty i czytelny. Poza tym nie musimy myśleć o definiowaniu typów zmiennych, co przyspiesza naszą pracę. Plusów Pythona jest naprawdę sporo, m.in. "slicing" (czyli rozbudowany sposób uzyskiwania dostępu do elementów list czy napisów, dzięki czemu bardzo łatwo możemy zwrócić wycinek listy) oraz mnóstwo funkcji pomocniczych (które ułatwią np. sortowanie danych, albo prostą konwersję między typami). <br/><br/>

Tak naprawdę każdy z tych trzech języków jest dobry pod względem matury.
Jeśli uczysz się w szkole C++ lub Javy i czujesz się pewnie, korzystając z nich, to jego wybór jest jak najbardziej właściwy. Jeśli jednak zaczynasz od zera, to naszym zdaniem Python będzie najlepszy do nauki (do matury z informatyki i tak będziesz mieć dużo materiału do przerobienia — wybierz więc język, którego nauczysz się najszybciej). Nasz kurs skupi się właśnie na tym języku, ze względu na plusy, które wymieniliśmy powyżej, a także dlatego, że jest on aktualnie najbardziej popularny. <br/><br/>

Wspomnijmy jeszcze o Pascalu, bo jest to język, którego jeszcze nie poruszyliśmy, a przecież można za jego pomocą rozwiązywać zadania na maturze. Opowiemy o nim jednak bardziej w formie ciekawostki. Jest to język przestarzały i o nieintuicyjnej składni, naszym zdaniem nie warto w ogóle rozważać jego wyboru. Nauka tego języka nie jest opłacalna ani przyszłościowa — rynek IT ma coraz mniejsze zapotrzebowanie na programistów posługujących się tym językiem. <br/><br/>