.navbar {
    min-height: 80px;
}

.navbar .navbar_sticky {
    font-size: 0;
    /* to likiwduje wbudowany margines w menu*/
    overflow: hidden;
    /* navbar moving while scrolling */
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 99;
    border-bottom: 2px solid var(--primary);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav_wrapper_left {
    align-items: center;
    margin-left: 20px;
}

.nav_wrapper_right {
    align-items: center;
    margin-right: 20px;
}

.nav_wrapper_left a,
.nav_wrapper_right a,
.nav_wrapper_right .switch {
    /* transition: .5s; */
}

.nav_wrapper_right .switch {
    padding: 0px;
}

.nav_wrapper_right .icon {
    display: none;
}

.navbar .nav_button {
    margin-top: 0px;
    margin-bottom: 0;
    background-color: transparent;
}

.navbar .nav_wrapper_right .icon {
    background-color: var(--transparent);
    color: var(--primary);
    border: 0px;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
    margin-left: 10px;
}

/* Hide default HTML checkbox */
.switch .checkbox {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--tools);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider::before {
    position: absolute;
    content: "";
    background: white url('../../img/sun-icon.png');
    background-position: center;
    transform: scale(1);
    height: 20px;
    width: 20px;
    left: 5px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.checkbox:checked+.slider {
    background-color: var(--slider);
}

.checkbox:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    background: white url('../../img/moon-icon.png');
    transform: scale(1);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


@media (max-width: 1200px) {
    .navbar .navbar_sticky {
        padding-left: 0px;
        padding-right: 0px;
    }

    .navbar .nav_wrapper_left {
        padding-left: 20px;
    }

    .navbar .nav_wrapper_right {
        padding-right: 20px;
    }

    .header h1 {
        margin-top: 40px;
        font-size: 60px;
        letter-spacing: 40px;
    }

    .switch {
        display: inline-block;
    }
}

@media(max-width:1000px) {
    .navbar .nav_wrapper_left {
        padding-left: 5px;
    }

    .navbar .nav_wrapper_right {
        padding-right: 5px;
    }

    .navbar a {
        font-size: 15px;
    }

    .switch {
        display: inline-block;
    }
}


@media (max-width:810px) {
    .navbar {
        min-height: 60px
    }

    .header h1 {
        margin-top: 30px;
        font-size: 50px;
        letter-spacing: 30px;
    }

    .navbar .nav_wrapper_left {
        padding-left: 0px;
    }

    .navbar .nav_wrapper_right {
        padding-right: 5px;
    }

    .navbar .nav_button {
        padding-left: 25px;
    }

    .nav_wrapper_left {
        margin-left: 0px;
    }

    .navbar .nav_wrapper_left a:not(:first-child) {
        display: none;
    }

    .navbar .nav_wrapper_right a:first-child {
        display: none;
    }

    .navbar .nav_wrapper_right .slider {
        display: none;
    }

    .navbar .nav_wrapper_right button.icon {
        color: var(--primary);
        transform: scale(1.2);
        float: right;
        display: block;
    }

    .navbar_sticky.responsive {
        position: relative;
        flex-direction: column;
        text-align: left;
        margin-bottom: 0px;
    }

    .navbar_sticky.responsive .nav_wrapper_right .icon {
        display: block;
        position: absolute;
        right: 25;
        top: 9.5;
    }

    .navbar_sticky.responsive .nav_wrapper_left {
        /* display: flex; */
        flex-direction: column;
        padding-left: 0px;
        margin-left: 0px;
    }

    .navbar_sticky.responsive .nav_wrapper_right {
        padding-right: 0px;
        margin-left: 0px;
    }

    .navbar_sticky.responsive .nav_wrapper_left a {
        border-bottom: solid 2px white;
        width: 100%;
    }

    .navbar_sticky.responsive .nav_wrapper_left a:first-child {
        border-bottom-width: 3px;
    }

    .navbar_sticky.responsive .nav_wrapper_left a,
    .navbar_sticky.responsive .nav_wrapper_right a {
        float: none;
        display: block;
        align-self: start;
        padding-left: 25px;
    }

    .navbar_sticky.responsive .nav_wrapper_left a:hover,
    .navbar_sticky.responsive .nav_wrapper_right a:hover {
        background-color: transparent;
    }

    .navbar a {
        font-size: 13px;
        text-transform: uppercase;
    }

    .switch {
        display: none;
    }
}