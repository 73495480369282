import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2021-czerwiec-matura-rozszerzona-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2021-czerwiec-matura-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ
W pliku <i>napisy.txt</i> znajduje się 1000 wierszy po 50 znaków (dużych liter angielskiego
alfabetu oraz cyfr).<br/><br/>
Napisz <b>program(y)</b>, który(e) da(dzą) odpowiedzi do poniższych zadań. Odpowiedzi zapisz
w pliku <i> wyniki4.txt</i>, a każdą odpowiedź poprzedź numerem oznaczającym odpowiednie
zadanie.<br/><br/>
<b>Uwaga:<br/></b> Plik <i>przyklad.txt</i> zawiera dane przykładowe spełniające warunki zadania.
Odpowiedzi dla danych z pliku <i>przyklad.txt</i> są podane pod pytaniami. )

<br/><br/>

### <u>Zadanie 1</u> (2 pkt.)

Podaj łączną liczbę <b>cyfr</b> we wszystkich napisach z pliku <i>napisy.txt.</i><br/>
Dla danych z pliku <i>przyklad.txt</i> wynikiem jest: 

```text
46504
```

<br/><br/>
<Dropdown title="ODPOWIEDŹ">

```text
11844
```
</Dropdown>





<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def main():
    with open(r"napisy.txt") as text_file:
        content = text_file.read().strip()

    counter = 0

    for character in content:
        if not character.isdigit():
            continue

        counter += 1

    print("ilosc liczb w pliku:", counter)


if __name__ == "__main__":
    main()

```
</Dropdown>
