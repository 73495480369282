import Dropdown from "../../components/dropdown/Dropdown.jsx";
import TableQuiz from "../../components/tableQuiz/TableQuiz.jsx";
import Quiz from "../../components/Quiz.jsx";
import FillGapsQuiz from "../../components/FillGapsQuiz.jsx";


## Zadanie 1 (0-1pkt.)
(maj 2015 część 1 zadanie 2.4)

### TREŚĆ
Na licencji ADWARE jest rozpowszechniane oprogramowanie, które

<TableQuiz 
    questions={
      [
        {question: "jest rozpowszechniane za darmo, ale zawiera funkcje wyświetlające reklamy.", answer: 'P'},
        {question: "ma otwarty kod źródłowy.", answer: 'F'},
        {question: "jest opłacane przez użytkownika.", answer: 'F'},
        {question: "może być używane tylko przez z góry ustalony czas.", answer: 'F'},
      ]
    }
/>

## Zadanie 2 (0-1pkt.)
(maj 2017 stara matura, poziom podstawowy, część 1 zadanie 3.2)

### TREŚĆ 
Wskaż licencję, która pozwala na bezpłatne wykorzystanie kodu źródłowego programu
w dowolnym celu niekomercyjnym. 
<Quiz 
    question={''}
    answers={[
        "Freeware",
        "Shareware",
        "GPL",
        "MOLP"]}
    correct={"GPL"}
/>

## Zadanie 3 (0-1pkt.)
(maj 2013 stara matura, poziom podstawowy, część 1 zadanie 3g)

### TREŚĆ 
Programy komputerowe rozpowszechniane bez opłat do wypróbowania przez
użytkowników, mające często ograniczenia czasowe, limit uruchomień lub blokadę
dostępu do niektórych funkcji, mają licencję

<Quiz 
    question={''}
    answers={[
        "freeware",
        "komercyjną",
        "shareware"]}
    correct={"shareware"}
/>

## Zadanie 4 (0-1pkt.)
(maj 2013 stara matura, poziom rozszerzony, część 1 zadanie 3d)

### TREŚĆ 
Licencja adware 

<TableQuiz 
    questions={
      [
        {question: "umożliwia korzystanie z aplikacji po uiszczeniu opłaty", answer: 'F'},
        {question: "zawiera żądanie zgody na wyświetlanie reklamy zwykle w postaci banerów", answer: 'P'},
        {question: "nie gwarantuje dostępu do kodu źródłowego aplikacji", answer: 'P'},
        {question: "dotyczy wyłącznie oprogramowania systemowego", answer: 'F'}
      ]
    }
/>

## Zadanie 5 (0-1pkt.)
(maj 2012 stara matura, poziom podstawowy, część 1 zadanie 3e)

### TREŚĆ 
Licencja GNU GPL zezwala na

<TableQuiz 
    questions={
      [
        {question: "uruchamianie programu do użytku domowego", answer: 'P'},
        {question: "rozpowszechnianie niezmodyfikowanej kopii programu", answer: 'P'},
        {question: "analizowanie, jak program działa i dostosowywanie go do swoich potrzeb", answer: 'P'},
        {question: "udoskonalanie programu i publiczne rozpowszechnianie własnych ulepszeń", answer: 'P'}
      ]
    }
/>

## Zadanie 6 (0-1pkt.)
(maj 2007 stara matura, poziom rozszerzony, część 1 zadanie 1.10.)

### TREŚĆ
Oprogramowanie, z którego możesz dowolnie długo i bezpłatnie korzystać to

<Quiz 
    question={''}
    answers={[
        "wszystkie programy dostępne w Internecie",
        "kopie zapasowe oprogramowania zainstalowanego w szkole",
        "shareware",
        "freeware"]}
    correct={"freeware"}
/>
