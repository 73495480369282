import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2017/formula_od_2015/informatyka/MIN-R2_1P-172.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="http://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2017/formula_od_2015/informatyka/Dane_PR2.zip">PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ
W pliku <i>dane.txt</i> znajduje się 200 wierszy. Każdy wiersz zawiera 320 liczb naturalnych
z przedziału od 0 do 255, oddzielonych znakami pojedynczego odstępu (spacjami).<br/>
Przedstawiają one jasności kolejnych pikseli czarno-białego obrazu o wymiarach 320 na 200
pikseli (od 0 – czarny do 255 – biały).<br/>
Napisz program(y), który(e) da(dzą) odpowiedzi do poniższych zadań. Odpowiedzi zapisz
w pliku <i>wyniki6.txt</i>, a każdą odpowiedź poprzedź numerem oznaczającym odpowiednie
zadanie.<br/><br/>
<span className="bold">Uwaga</span>: plik <i>przyklad.txt</i> zawiera dane przykładowe spełniające warunki zadania (obraz
ma takie same rozmiary). Odpowiedzi dla danych z pliku <i>przyklad.txt</i> są podane pod
poleceniami. 

<br/><br/>

### <u>Zadanie 2</u>   (0-2pkt.)
Podaj, ile wynosi najmniejsza liczba wierszy, które należy usunąć, żeby obraz miał pionową oś
symetrii. Obraz ma pionową oś symetrii, jeśli w każdym wierszu i-ty piksel od lewej strony
przyjmuje tę samą wartość, co i-ty piksel od prawej strony, dla dowolnego 1 ≤ i ≤ 320.<br/><br/>
Dla danych z pliku <i>przyklad.txt</i> wynikiem jest 3. 

<br/><br/>

<Dropdown title="ODPOWIEDŹ">

```text
149
```
</Dropdown>

<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def get_image() -> list[list[int]]:
    with open("dane.txt") as image:
        lines = image.read().strip().split("\n")

    matrix = [[int(pixel) for pixel in line.split(" ")] for line in lines]

    return matrix


def main():
    image = get_image()

    to_remove = 0

    for row in image:
        if row == row[::-1]:
            continue

        to_remove += 1

    print(to_remove)


if __name__ == "__main__":
    main()

```
</Dropdown>