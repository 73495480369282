import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2022/Informatyka/poziom_rozszerzony/EINP-R2-100-2205.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://cke.gov.pl/images/_EGZAMIN_MATURALNY_OD_2015/Arkusze_egzaminacyjne/2022/Informatyka/poziom_rozszerzony/Dane_2205.zip">PLIKI DO ZADANIA</a>
<br/>
</div>

### TREŚĆ
W pliku <i>liczby.txt</i> danych jest 200 różnych liczb całkowitych z zakresu [10, 100000].
Każda z tych liczb zapisana jest w osobnym wierszu.
Napisz program (lub kilka programów), który(-e) znajdzie(-dą) odpowiedzi do poniższych
zadań. Odpowiedzi zapisz w pliku <i>wyniki4.txt</i>. Każdą odpowiedź poprzedź numerem
oznaczającym zadanie.
Do dyspozycji masz plik <i>przyklad.txt</i>, który także zawiera 200 liczb – odpowiedzi dla
tego pliku podano w treściach zadań. Możesz sprawdzać na nim działanie swojego
programu.
Uwaga: Pamiętaj, że Twój program musi ostatecznie działać dla pliku <i>liczby.txt</i>.

<br/><br/>

### <u>Zadanie 1</u>   (0-4pkt.)
Podaj, ile jest w pliku <i>liczby.txt</i> takich liczb, których cyfry pierwsza i ostatnia są takie
same. Zapisz tę z nich, która występuje w pliku <i>liczby.txt</i> jako pierwsza.
W pliku z danymi jest co najmniej jedna taka liczba.
Odpowiedź dla danych z pliku <i>przyklad.txt</i>: 26 626
(26 takich liczb, które mają pierwszą i ostatnią cyfrę taką samą; pierwszą z nich w pliku
przykładowym jest 626)

<br/><br/>

<Dropdown title="ODPOWIEDŹ">

```text
Liczb z pierwszą cyfrą równą ostatniej: 18
Pierwsza taka liczba: 93639
```

</Dropdown>


<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
PATH = r"liczby.txt"


def get_numbers() -> list[str]:

    with open(PATH) as text_file:
        numbers = text_file.read().strip().split("\n")

    return numbers


def main():
    numbers = list(filter(lambda x: x[0] == x[-1], get_numbers()))

    print(len(numbers), numbers[0])


if __name__ == "__main__":
    main()

```
</Dropdown>
