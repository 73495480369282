import Dropdown from "../../../components/dropdown/Dropdown.jsx";
import Code from "../../../components/code/Code.jsx";

<div className="box_link"><a className="link  link_materials" target="_blank" href="https://arkusze.pl/maturalne/informatyka-2021-czerwiec-matura-rozszerzona-2.pdf">LINK DO ARKUSZA</a>
<br/>
<a className="link  link_materials" href="https://arkusze.pl/maturalne/informatyka-2021-czerwiec-matura-rozszerzona-zalaczniki.zip"> PLIKI DO ZADANIA</a>

<br/>
</div>

### TREŚĆ
W pliku <i>napisy.txt</i> znajduje się 1000 wierszy po 50 znaków (dużych liter angielskiego
alfabetu oraz cyfr).<br/><br/>
Napisz <b>program(y)</b>, który(e) da(dzą) odpowiedzi do poniższych zadań. Odpowiedzi zapisz
w pliku <i>wyniki4.txt</i>, a każdą odpowiedź poprzedź numerem oznaczającym odpowiednie
zadanie.<br/><br/>
<b>Uwaga:<br/></b> Plik <i>przyklad.txt</i> zawiera dane przykładowe spełniające warunki zadania.
Odpowiedzi dla danych z pliku <i>przyklad.txt</i> są podane pod pytaniami. )

<br/><br/>

### <u>Zadanie 2</u> (2 pkt.)
W pliku <i>napisy.txt</i> ukryto pewne pięćdziesięcioznakowe hasło w następujący sposób:<br/>
– w co dwudziestym wierszu (w wierszach o numerach 20, 40, 60, …, 1000), ukryto dokładnie
jedną literę hasła;<br/>
– ukryta litera w kolejnych wierszach zawsze znajduje się na innej pozycji: w 20 wierszu na
pierwszej, w 40 wierszu na drugiej, w 60 wierszu na trzeciej, …, w 1000 na pięćdziesiątej.<br/>
Podaj to hasło.<br/><br/>
Dla danych z pliku <i>przyklad.txt</i> wynikiem jest:

```text
UDALOSIEIZDAJEMYEGZAMINYMATURALNEZWIELUPRZEDMIOTOW 
```


<br/><br/>
<Dropdown title="ODPOWIEDŹ">

```text
SZYBKOROZWIAZUJEPROGRAMISTYCZNEZADANIAZINFORMATYKI
```
</Dropdown>





<Dropdown title="PRZYKŁADOWE ROZWIĄZANIE - PYTHON">

```python
def main():
    with open(r"napisy.txt") as text_file:
        content = text_file.read().strip().split("\n")

    message = ""

    for iteration, index in enumerate(range(19, 1000, 20), start=0):
        message += content[index][iteration]

    print(message)


if __name__ == "__main__":
    main()

```
</Dropdown>
